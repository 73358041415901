<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Open
            <span
              v-if="awaitingResponseOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ awaitingResponseOrders }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Cancelled
            <!-- <span
              v-if="closedOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ closedOrders }}</span
            > -->
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Completed
            <!-- <span
              v-if="completedOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ completedOrders }}</span
            > -->
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel class="pt-10">
        <grower-orders-table :tabFilters="awaitingResponseFilter" />
        <!-- <awaiting-response-buy-grower-orders-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <grower-orders-table :tabFilters="closedFilter" />
        <!-- <closed-buy-grower-orders-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <grower-orders-table :tabFilters="completedFilter" />
        <!-- <completed-buy-grower-orders-table /> -->
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import GrowerOrdersTable from "@/layouts/grower-portal/buy/orders/BuyGrowerOrdersTable.vue";
// import AwaitingResponseBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/AwaitingResponseBuyGrowerOrdersTable.vue";
// import ClosedBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/ClosedBuyGrowerOrdersTable.vue";
// import CompletedBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/CompletedBuyGrowerOrdersTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // AwaitingResponseBuyGrowerOrdersTable,
    // ClosedBuyGrowerOrdersTable,
    // CompletedBuyGrowerOrdersTable,
    GrowerOrdersTable,
  },
  data() {
    return {
      selectedTab: 0,
      awaitingResponseFilter: function (o) {
        if (
          o.status === "buyer_submitted" ||
          o.status === "buyer_rejected" ||
          o.status === "buyer_confirmed" ||
          o.status === "buyer_ordered"
        ) {
          return true;
        } else {
          return false;
        }
      },
      closedFilter: function (o) {
        if (
          o.status === "buyer_closed" ||
          o.status === "seller_closed" ||
          o.status === "seller_canceled"
        ) {
          return true;
        } else {
          return false;
        }
      },
      completedFilter: function (o) {
        if (o.status === "seller_completed") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    awaitingResponseOrders() {
      let numOrders = this.$store.getters.getNeedsActionVendorOrders.length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    closedOrders() {
      let numOrders = this.$store.getters.getClosedGrowerOrders.length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    completedOrders() {
      let numOrders = this.$store.getters.getCompletedGrowerOrders.length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>
