<template>
  <div class="flex w-full overflow-y-auto">
    <div
      v-for="(currentCrop, ndx) in currentCrops"
      :key="currentCrop.id"
      class="mr-4"
    >
      <button
        :class="[
          activeCropIndex == ndx
            ? 'border-indigo-600 text-indigo-600'
            : 'border-gray-500 text-gray-700 hover:border-gray-300 hover:text-gray-800',
          'rounded border-2 p-4 text-sm font-medium',
        ]"
        @click="changeTab(ndx)"
      >
        <div class="flex whitespace-pre">
          {{ currentCrop.crop_type ? currentCrop.crop_type : "Select Crop" }}
          <span
            v-if="
              currentCrop.harvest_range_start || currentCrop.harvest_range_end
            "
            class="text-gray-500"
          >
            ({{
              new Date(currentCrop.harvest_range_end)?.toLocaleString(
                "default",
                dateOptions,
              ) || currentCrop.harvest_range_start
            }})
          </span>
          <span v-if="currentCrop.active">[Current]</span>
        </div>
      </button>
      <div class="-mt-14 text-right" v-if="!currentCrop.active">
        <button
          class="mr-1 text-gray-400 hover:text-red-500"
          @click="deleteCrop(ndx)"
        >
          x
        </button>
      </div>
    </div>
    <button
      :class="[
        'border-gray-600 text-gray-700 hover:border-gray-300 hover:text-gray-800',
        'rounded border-2 p-4 text-sm font-medium',
      ]"
      @click="addCrop"
    >
      <div class="flex whitespace-pre">Add Crop</div>
    </button>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    currentCrops: {
      type: Array,
      default: () => [],
    },
    fieldId: {
      type: Number,
      default: null,
    },
  },
  emits: ["selectCrop", "addCrop", "deleteCrop"],
  data() {
    return {
      selectedCropIndex: null,
      dateOptions: {
        year: "numeric",
      },
    };
  },
  computed: {
    activeCropIndex() {
      let active = null;
      if (this.selectedCropIndex === null) {
        active = this.currentCrops.findIndex((crop) => crop.active == true);
        if (active !== -1) {
          this.$emit("selectCrop", active);
        }
      }
      return this.selectedCropIndex ?? active;
    },
  },
  methods: {
    deleteCrop(index) {
      // console.log("deleteCrop", index);
      this.$emit("deleteCrop", index);
      this.selectedCropIndex = null;
    },
    addCrop() {
      // console.log("addCrop");
      this.$emit("addCrop");
      this.changeTab(this.currentCrops.length - 1);
    },
    changeTab(index) {
      // console.log("changeTab", index);
      this.selectedCropIndex = index;
      this.$emit("selectCrop", index);
    },
  },
};
</script>
