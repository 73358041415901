<template>
  <button :class="pillStyle" @click="clickButton">
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: { type: String },
    hiddenOnSmallScreens: { type: Boolean, default: false },
    hiddenOnMediumAndSmallScreens: { type: Boolean, default: false },
    pillColorIndex: { type: Number, default: 0 },
  },
  emits: ["buttonClick"],
  computed: {
    conditionalBodyStyles() {
      let styles = {
        hidden: this.hiddenOnSmallScreens || this.hiddenOnMediumAndSmallScreens, // hidden should occur if either hidden clause given
      };

      return styles;
    },
    pillStyle() {
      let textColor = "";
      let backgroundColor = "";
      switch (this.pillColorIndex) {
        case 0:
          textColor = "text-violet-900";
          backgroundColor = "bg-violet-100";
          break;
        case 1:
          textColor = "text-green-900";
          backgroundColor = "bg-green-100";
          break;
        case 2:
          textColor = "text-yellow-900";
          backgroundColor = "bg-yellow-100";
          break;
        case 3:
          textColor = "text-red-900";
          backgroundColor = "bg-red-100";
          break;
        case 4:
          textColor = "text-blue-900";
          backgroundColor = "bg-blue-100";
          break;
        case 5:
          textColor = "text-indigo-900";
          backgroundColor = "bg-indigo-100";
          break;
        case 6:
          textColor = "text-purple-900";
          backgroundColor = "bg-purple-100";
          break;
        case 7:
          textColor = "text-pink-900";
          backgroundColor = "bg-pink-100";
          break;
        default:
          textColor = "text-gray-900";
          backgroundColor = "bg-gray-100";
      }
      return (
        "rounded-lg pl-2 pr-2 drop-shadow-lg " +
        " " +
        textColor +
        " " +
        backgroundColor +
        " "
      );
    },
  },
  methods: {
    clickButton() {
      this.$emit("buttonClick");
    },
  },
};
</script>
