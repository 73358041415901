<template>
  <page-card
    :headingTitle="
      currentOrder?.quantity +
      ' ' +
      currentOffer?.quantity_units +
      ' of ' +
      currentOrder?.details?.crop +
      ' to ' +
      currentOrder?.buyer_details?.name
    "
    :headingDescription="
      'Order # ' + $route.params.orderId + ' | ' + currentOrder?.status
    "
  >
    <template #buttonArea>
      <base-button
        buttonText="Finalize Order"
        :marginLeft="false"
        @buttonClick="completeOrder"
      />
      <base-button :marginLeft="true" buttonText="Save" />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-2">
        <detail-card
          :title="'Allocations'"
          :titleStat="allocationData.count + '  fields'"
          :statusText="
            allocationData.count > 0
              ? allocationData.anyZero
                ? 'Missing Acres'
                : 'Assigned'
              : 'Unassigned'
          "
          :statusRed="allocationData.count > 0 ? false : true"
          :statusYellow="allocationData.count > 0 && allocationData.anyZero"
          :primaryStat="{
            text: allocationData.acres_allocated + ' Allocated Acres',
            icon: 'TemplateIcon',
          }"
          :secondaryStats="[
            {
              text:
                allocationData.estimated_qty +
                ' ' +
                currentOffer?.quantity_units +
                ' Estimated Qty',
              icon: 'PresentationChartLineIcon',
            },
          ]"
          :buttonText="'Edit Allocations'"
          @detailCardClick="tabChanged(0)"
        />
      </div>
      <div class="sm:col-span-2">
        <detail-card
          v-if="currentOffer?.premiums"
          :title="'Premiums'"
          :titleStat="
            premiumData.nonFixedPremiums == premiumData.totalPremiums
              ? 'Manual Accounting Required'
              : '$' + premiumData.totalValue + ' opportunity'
          "
          :statusText="
            premiumData.completedPremiums == premiumData.totalPremiums
              ? 'Completed'
              : 'Incomplete'
          "
          :statusRed="
            premiumData.completedPremiums == premiumData.totalPremiums
              ? false
              : true
          "
          :primaryStat="{
            text: '$' + premiumData.completedValue + ' est. earnings',
            icon: 'CurrencyDollarIcon',
          }"
          :secondaryStats="[
            {
              text:
                premiumData.completedPremiums +
                ' of ' +
                premiumData.totalPremiums +
                ' premiums completed',
              icon: 'PencilIcon',
            },
          ]"
          :buttonText="'Edit Premiums'"
          @detailCardClick="tabChanged(1)"
        />
        <detail-card
          v-else
          :title="'Premiums'"
          :titleStat="'No premiums provided for this offer'"
          :statusText="'N/A'"
          :statusRed="false"
          :primaryStat="{
            text: 'N/A',
            icon: 'CurrencyDollarIcon',
          }"
          :secondaryStats="[
            {
              text: 'N/A',
              icon: 'PencilIcon',
            },
          ]"
          :buttonText="''"
          @detailCardClick="tabChanged(0)"
        />
      </div>
      <div class="sm:col-span-2">
        <detail-card
          :title="'Deliveries'"
          :titleStat="
            currentOrder?.quantity + ' ' + currentOrder?.units + ' to deliver'
          "
          :statusText="
            deliveryData?.totalDelivered >= currentOrder?.quantity ||
            currentOrder?.units == 'acres'
              ? 'Complete'
              : 'Incomplete'
          "
          :statusRed="
            deliveryData?.totalDelivered >= currentOrder?.quantity ||
            currentOrder?.units == 'acres'
              ? false
              : true
          "
          :primaryStat="{
            text: currentOffer?.deliver_by
              ? 'Deliver By : ' + currentOffer?.deliver_by
              : 'Deliver By : N/A',
            icon: 'CalendarIcon',
          }"
          :secondaryStats="[
            {
              text:
                deliveryData?.totalDelivered +
                ' ' +
                (currentOrder?.units != 'acres'
                  ? currentOrder?.units + ' delivered'
                  : currentOrderDeliveries[0]?.quantity_units + ' delivered'),
              icon: 'TruckIcon',
            },
          ]"
          :buttonText="'Edit Deliveries'"
          @detailCardClick="tabChanged(2)"
        />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Allocations
              </button>
            </Tab>
            <Tab
              v-show="currentOffer?.premiums"
              as="template"
              v-slot="{ selected }"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Deliveries
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentOrder?.status != 'Internal'"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-4">
            <!-- Allocations content will be handled by the AllocationsManager component -->
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <!-- Premiums content will be handled by the PremiumsManager component -->
          </TabPanel>

          <TabPanel as="dl" class="pt-4">
            <div class="mb-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <CropHarvestAggregate
                :orderId="parseInt($route.params.orderId)"
                perspective="grower"
              />
              <DeliveryAggregate :orderId="parseInt($route.params.orderId)" />
            </div>
            <crop-delivery-table
              :deliveries="currentOrderDeliveries"
              :orderId="parseInt(orderId)"
            ></crop-delivery-table>
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <crop-order-display
                v-if="dataIsLoaded"
                :orderId="parseInt($route.params.orderId)"
              />
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <view-attachments-table
              v-if="currentOrder?.status != 'Internal'"
              :attachments="attachments"
            />
            <add-attachments-table
              v-else
              :attachments="currentOrder?.attachments"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="pt-4"
            v-if="currentOrder?.status != 'Internal'"
          >
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <allocations-manager
        v-show="selectedTab == 0"
        :orderId="parseInt($route.params.orderId)"
        @updateAllocationData="setAllocationData"
      />
      <premiums-manager
        v-show="selectedTab == 1"
        :orderId="parseInt($route.params.orderId)"
        @updatePremiumData="setPremiumData"
      />
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropOrderDisplay from "@/layouts/buyer-grower/crop-orders/CropOrderDisplay.vue";
// import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import CropDeliveryTable from "@/layouts/grower-portal/sell/CropDeliveryTable.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
// import AllocationsAggregate from "@/layouts/grower-portal/sell/orders/AllocationAggregate.vue";
import DeliveryAggregate from "@/layouts/grower-portal/sell/orders/DeliveryAggregate.vue";
import CropHarvestAggregate from "@/layouts/common/orders/CropHarvestAggregate.vue";
// import AllocationsTable from "@/layouts/grower-portal/sell/orders/AllocationsTable.vue";
import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import DetailCard from "@/components/cards/DetailCard.vue";
import PremiumsManager from "@/layouts/grower-portal/sell/orders/PremiumsManager.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    CropOrderDisplay,
    // CropPremiumDisplay,
    AddAttachmentsTable,
    ViewAttachmentsTable,
    // AllocationsTable,
    AllocationsManager,
    // AllocationsAggregate,
    DeliveryAggregate,
    CropHarvestAggregate,
    ChatSingleView,
    CropDeliveryTable,
    DetailCard,
    PremiumsManager,
  },
  data() {
    // console.log("CROP ORDER");
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      showDeleteModal: false,
      selectedTab,
      allocationData: {},
      premiumData: {},
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        // this.$store.getters.getGrowerMarketOfferById(this.orderId) &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    attachments() {
      let attachments = this.currentOrder?.attachments;
      let offerAttachments = this.currentOffer?.attachments;
      if (attachments && offerAttachments) {
        attachments = attachments.concat(offerAttachments);
      }
      return attachments;
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let order = this.$store.getters.getGrowerCropOrderById(this.orderId);
      return order;
      // let test = this.$store.getters.getGrowerCropOrderById(this.orderId);
      // console.log("currentOrder", test);
      // return test;
    },
    currentOrderDeliveries() {
      if (!this.dataIsLoaded) {
        return null;
      }
      return this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId);
    },
    deliveryData() {
      if (!this.dataIsLoaded) {
        return {};
      }
      return {
        // sum of all delivery.quantity_delivered
        totalDelivered: this.currentOrderDeliveries.reduce(
          (acc, delivery) => acc + delivery.quantity_delivered,
          0,
        ),
      };
    },
    offerId() {
      return this.currentOrder?.offer;
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },

    async deleteProduceOrder() {
      this.showDeleteModal = false;
      // console.log("DELETE");
      let allosToDelete = this.$store.getters.getGrowerAllocationsByOrderId(
        this.$route.params.orderId,
      );
      for (let allo of allosToDelete) {
        this.$store.dispatch("deleteGrowerAllocation", { id: allo.id });
      }
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.orderId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-orders" });
      }
    },
    completeOrder() {
      this.$router.push({
        name: "grower-portal-finalize-offer-order",
        params: { orderId: this.orderId },
      });
      // this.$store.dispatch("updateGrowerCropOrder", {
      //   id: this.orderId,
      //   status: "Completed",
      // });
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      // set the query param to the selected tab
      this.$router.push({
        query: { tab: index },
      });
    },
    setAllocationData(data) {
      this.allocationData = data;
    },
    setPremiumData(data) {
      this.premiumData = data;
    },
  },
};
</script>
