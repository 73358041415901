<template>
  <modal :show="showModal" dialogPanelClass="max-w-screen-lg" :title="null">
    <record-form
      @saveRecord="storeRecord"
      @closeRecord="showModal = false"
      @cancelRecord="showModal = false"
      recordType="Fertilizer Application"
      v-if="modalType == 'record'"
    />
    <task-form
      :field-id="fieldId"
      :task-id="selectedTaskId"
      @closeModal="showModal = false"
      prepopulatedCategory="Fertilizer Application"
      v-if="modalType == 'task'"
    />
  </modal>

  <div
    class="mt-2 flex text-left text-lg font-medium uppercase tracking-wider sm:col-span-6"
  >
    Fertilizer
    <thumb-up-icon
      class="ml-2 h-6 w-6 text-green-500"
      v-if="totalEmissions < defaultEmissions"
    />
    <thumb-down-icon
      v-if="totalEmissions > defaultEmissions + 10"
      class="ml-2 h-6 w-6 text-red-500"
    />
    <div v-if="totalEmissions && defaultEmissions">
      <span class="ml-2 text-xs normal-case text-gray-500">
        ({{ Math.round(totalEmissions)?.toLocaleString() }} kg CO2e / acre vs
        {{ Math.round(defaultEmissions)?.toLocaleString() }} kg CO2e / acre by a
        typical grower)</span
      >
    </div>
  </div>
  <!-- show current fertilizer records-->
  <select-search
    :wideItem="true"
    inputTitle="Source of Ammonia"
    :selectOptions="[
      {
        label: 'Conventional',
        value: 'Conventional',
      },
      { label: 'Green', value: 'Green' },
    ]"
    v-model="currentInputs.source_of_ammonia"
    :placeholder="scenario.defaults.source_of_ammonia"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.source_of_ammonia"
  />
  <select-search
    :wideItem="true"
    inputTitle="Technology for Nitrogen Management"
    :selectOptions="[
      {
        label: 'Business as usual',
        value: 'Business as usual',
      },
      {
        label: '4R (Right time, Right place, Right form, and Right rate)',
        value: '4R (Right time, Right place, Right form, and Right rate)',
      },
      {
        label: 'Enhanced Efficiency Fertilizer',
        value: 'Enhanced Efficiency Fertilizer',
      },
    ]"
    v-model="currentInputs.technology_for_nitrogen_management"
    :placeholder="scenario.defaults.technology_for_nitrogen_management"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.technology_for_nitrogen_management"
  />
  <div
    v-if="records && scenario.metadata?.crops?.length == 1"
    class="w-full text-sm sm:col-span-6"
  >
    Fertilizer Applications
    <div
      class="mt-2 w-full border-separate border-spacing-2 rounded-lg border border-slate-300"
    >
      <div
        class="mx-2 my-2 grid auto-cols-max grid-cols-[30%_20%_15%_15%_15%+5%] text-gray-500"
      >
        <div>Description</div>
        <div>Product</div>
        <div>Nitrogen (lbs/acre)</div>
        <div>Phosphorus (lbs/acre)</div>
        <div>Potassium (lbs/acre)</div>
        <div></div>
        <template v-for="(record, index) in records" :key="index">
          <div class="">
            {{ record.result }}
            <span v-if="record.planned_or_actual == 'planned'"> (Planned)</span>
          </div>
          <div class="">
            {{ record.payload?.product }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="w-24 text-right">
            {{
              convertToPounds(
                record.payload.product,
                record.payload.nitrogen,
                record.payload.rate,
                record.payload.units,
              )?.toFixed(2)
            }}
          </div>
          <div class="w-24 text-right">
            {{
              convertToPounds(
                record.payload.product,
                record.payload.phosphorus,
                record.payload.rate,
                record.payload.units,
              )?.toFixed(2)
            }}
          </div>
          <div class="w-24 text-right">
            {{
              convertToPounds(
                record.payload.product,
                record.payload.potassium,
                record.payload.rate,
                record.payload.units,
              )?.toFixed(2)
            }}
          </div>
          <div class="w-8 text-center">
            <span v-if="record.planned_or_actual == 'planned'">
              <a @click="editTask(record.taskId)"
                ><pencil-icon class="h-6 w-6 text-gray-500" /></a
            ></span>
          </div>
          <template
            v-for="(mix_product, subindex) in record.payload?.mix_products"
            :key="subindex"
          >
            <div class="w-48"></div>
            <div>
              {{ mix_product?.product }}
              <!-- ({{ mix_product.nitrogen }}-{{
                mix_product.phosphorus
              }}-{{ mix_product.potassium }}) -->
            </div>
            <div class="w-24 text-right">
              {{
                convertToPounds(
                  mix_product.product,
                  mix_product.nitrogen,
                  mix_product.rate,
                  mix_product.units,
                )?.toFixed(2)
              }}
            </div>
            <div class="w-24 text-right">
              {{
                convertToPounds(
                  mix_product.product,
                  mix_product.phosphorus,
                  mix_product.rate,
                  mix_product.units,
                )?.toFixed(2)
              }}
            </div>
            <div class="w-24 text-right">
              {{
                convertToPounds(
                  mix_product.product,
                  mix_product.potassium,
                  mix_product.rate,
                  mix_product.units,
                )?.toFixed(2)
              }}
            </div>
            <div></div>
          </template>
        </template>
        <!-- <div class="font-bold text-gray-800">
          <div>Total</div>
          <div></div>
          <div class="w-24 text-right">{{ totalNitrogen }}</div>
          <div class="w-24 text-right">{{ totalPhosphorus }}</div>
          <div class="w-24 text-right">{{ totalPotassium }}</div>
        </div> -->
      </div>
    </div>

    <div class="mt-2 flex justify-end">
      <base-button
        buttonText="+ Add Planned Activity"
        :redButton="false"
        @buttonClick="addTask"
        v-if="!readOnly"
      />
      <base-button
        buttonText="+ Add Record"
        :redButton="false"
        @buttonClick="addRecord"
        v-if="!readOnly"
      />
    </div>
  </div>
  <template v-else>
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Nitrogen Fertilizer
    </div>
    <number-input
      :wideItem="true"
      inputTitle="Ammonia (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.ammonia"
      :placeholder="scenario.defaults.ammonia?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.ammonia?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Ammonium Nitrate (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.ammonium_nitrate"
      :placeholder="scenario.defaults.ammonium_nitrate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.ammonium_nitrate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Ammonium Sulfate (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.ammonium_sulfate"
      :placeholder="scenario.defaults.ammonium_sulfate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.ammonium_sulfate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Diammonium Phosphate (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.n_diammonium_phosphate"
      :placeholder="scenario.defaults.n_diammonium_phosphate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.n_diammonium_phosphate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Monoammonium Phosphate (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.n_monoammonium_phosphate"
      :placeholder="scenario.defaults.n_monoammonium_phosphate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.n_monoammonium_phosphate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Urea (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.urea"
      :placeholder="scenario.defaults.urea?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.urea?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Urea Ammonium Nitrate Solution (lbs N/acre)"
      inputHtmlType="text"
      v-model="currentInputs.urea_ammonium_nitrate_solution"
      :placeholder="
        scenario.defaults.urea_ammonium_nitrate_solution?.toFixed(2)
      "
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="
        currentInputs.urea_ammonium_nitrate_solution?.toFixed(2)
      "
      :nullIfEmpty="true"
    />

    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Phosphorus Fertilizer
    </div>
    <number-input
      :wideItem="true"
      inputTitle="Diammonium Phosphate (lbs P2O5/acre)"
      inputHtmlType="text"
      v-model="currentInputs.p_diammonium_phosphate"
      :placeholder="scenario.defaults.p_diammonium_phosphate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.p_diammonium_phosphate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Monoammonium Phosphate (lbs P2O5/acre)"
      inputHtmlType="text"
      v-model="currentInputs.p_monoammonium_phosphate"
      :placeholder="scenario.defaults.p_monoammonium_phosphate?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.p_monoammonium_phosphate?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Potash Fertilizer
    </div>
    <number-input
      :wideItem="true"
      inputTitle="Potash Fertilizer (lbs K2O/acre)"
      inputHtmlType="text"
      v-model="currentInputs.potash_fertilizer"
      :placeholder="scenario.defaults.potash_fertilizer?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.potash_fertilizer?.toFixed(2)"
      :nullIfEmpty="true"
    />
  </template>
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
import SelectSearch from "@/components/form/SelectSearch";
import BaseButton from "@/components/buttons/BaseButton";
import Modal from "@/components/modals/PopupModal";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import TaskForm from "@/layouts/grower-portal/operations/PlanTask.vue";
import { PencilIcon } from "@heroicons/vue/outline";
import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/vue/outline";
import { gramsEmittedByInputPerAcre } from "@/components/composables/convertUnits";
import {
  isVolumeUnit,
  isMassUnit,
} from "@/components/composables/convertUnits";
import { postgresDate } from "@/components/composables/dateUtils";
import { convert } from "convert";
export default {
  components: {
    SelectSearch,
    BaseButton,
    NumberInput,
    Modal,
    RecordForm,
    TaskForm,
    PencilIcon,
    ThumbUpIcon,
    ThumbDownIcon,
  },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
          },
      recordCache: [],
      showModal: false,
      selectedTaskId: null,
      modalType: null,
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
    fieldId() {
      if (this.scenario.metadata?.crops?.length == 1) {
        let cropId = this.scenario.metadata.crops[0];
        let field = this.$store.getters.getFieldByCropId(cropId);
        return field?.id;
      }
      return null;
    },
    records() {
      let records = [];
      let tasks = [];
      if (this.scenario.metadata?.crops?.length == 1) {
        records = this.$store.getters.getRecordsByCrop(
          this.scenario.metadata.crops[0],
        );
        if (records && records.length > 0) {
          records = records.filter((record) => {
            return record.category == "Fertilizer Application";
          });
        }
        // console.log("records loaded", records);
        tasks = this.$store.getters.getTasksByField(this.fieldId);
        if (tasks && tasks.length > 0) {
          tasks = tasks.filter((task) => {
            return task.category == "Fertilizer Application";
          });
          tasks = tasks.map((task) => {
            return {
              ...task.details,
              planned_or_actual: "planned",
              taskId: task.id,
            };
          });
          records = records.concat(tasks);
        }
      }
      return records;
    },
    totalEmissions() {
      // console.log(
      //   "currentInputs",
      //   this.currentInputs,
      //   gramsEmittedByInputPerAcre("ammonium_nitrate"),
      //   this.currentInputs.ammonium_nitrate,
      // );
      let total = 0;
      let energy = 0;
      this.currentInputs.ammonia !== null &&
      this.currentInputs.ammonia !== undefined
        ? (energy = this.currentInputs.ammonia)
        : (energy = this.scenario.defaults.ammonia);
      total += gramsEmittedByInputPerAcre("ammonia") * energy;

      energy = 0;
      this.currentInputs.urea !== null && this.currentInputs.urea !== undefined
        ? (energy = this.currentInputs.urea)
        : (energy = this.scenario.defaults.urea);
      total += gramsEmittedByInputPerAcre("urea") * energy;

      energy = 0;
      this.currentInputs.ammonium_nitrate !== null &&
      this.currentInputs.ammonium_nitrate !== undefined
        ? (energy = this.currentInputs.ammonium_nitrate)
        : (energy = this.scenario.defaults.ammonium_nitrate);
      total += gramsEmittedByInputPerAcre("ammonium_nitrate") * energy;

      energy = 0;
      this.currentInputs.ammonium_sulfate !== null &&
      this.currentInputs.ammonium_sulfate !== undefined
        ? (energy = this.currentInputs.ammonium_sulfate)
        : (energy = this.scenario.defaults.ammonium_sulfate);
      total += gramsEmittedByInputPerAcre("ammonium_sulfate") * energy;

      energy = 0;
      this.currentInputs.urea_ammonium_nitrate_solution !== null &&
      this.currentInputs.urea_ammonium_nitrate_solution !== undefined
        ? (energy = this.currentInputs.urea_ammonium_nitrate_solution)
        : (energy = this.scenario.defaults.urea_ammonium_nitrate_solution);
      total +=
        gramsEmittedByInputPerAcre("urea_ammonium_nitrate_solution") * energy;

      energy = 0;
      this.currentInputs.n_monoammonium_phosphate !== null &&
      this.currentInputs.n_monoammonium_phosphate !== undefined
        ? (energy = this.currentInputs.n_monoammonium_phosphate)
        : (energy = this.scenario.defaults.n_monoammonium_phosphate);
      total += gramsEmittedByInputPerAcre("n_monoammonium_phosphate") * energy;

      energy = 0;
      this.currentInputs.n_diammonium_phosphate !== null &&
      this.currentInputs.n_diammonium_phosphate !== undefined
        ? (energy = this.currentInputs.n_diammonium_phosphate)
        : (energy = this.scenario.defaults.n_diammonium_phosphate);
      total += gramsEmittedByInputPerAcre("n_diammonium_phosphate") * energy;

      energy = 0;
      this.currentInputs.p_monoammonium_phosphate !== null &&
      this.currentInputs.p_monoammonium_phosphate !== undefined
        ? (energy = this.currentInputs.p_monoammonium_phosphate)
        : (energy = this.scenario.defaults.p_monoammonium_phosphate);
      total += gramsEmittedByInputPerAcre("p_monoammonium_phosphate") * energy;

      energy = 0;
      this.currentInputs.p_diammonium_phosphate !== null &&
      this.currentInputs.p_diammonium_phosphate !== undefined
        ? (energy = this.currentInputs.p_diammonium_phosphate)
        : (energy = this.scenario.defaults.p_diammonium_phosphate);
      total += gramsEmittedByInputPerAcre("p_diammonium_phosphate") * energy;

      energy = 0;
      this.currentInputs.potash_fertilizer !== null &&
      this.currentInputs.potash_fertilizer !== undefined
        ? (energy = this.currentInputs.potash_fertilizer)
        : (energy = this.scenario.defaults.potash_fertilizer);
      total += gramsEmittedByInputPerAcre("potash_fertilizer") * energy;

      total = total / 1000; // convert grams to kg
      return total;
    },
    defaultEmissions() {
      let total =
        gramsEmittedByInputPerAcre("ammonia") * this.scenario.defaults.ammonia +
        gramsEmittedByInputPerAcre("urea") * this.scenario.defaults.urea +
        gramsEmittedByInputPerAcre("ammonium_nitrate") *
          this.scenario.defaults.ammonium_nitrate +
        gramsEmittedByInputPerAcre("ammonium_sulfate") *
          this.scenario.defaults.ammonium_sulfate +
        gramsEmittedByInputPerAcre("urea_ammonium_nitrate_solution") *
          this.scenario.defaults.urea_ammonium_nitrate_solution +
        gramsEmittedByInputPerAcre("n_monoammonium_phosphate") *
          this.scenario.defaults.n_monoammonium_phosphate +
        gramsEmittedByInputPerAcre("n_diammonium_phosphate") *
          this.scenario.defaults.n_diammonium_phosphate +
        gramsEmittedByInputPerAcre("p_monoammonium_phosphate") *
          this.scenario.defaults.p_monoammonium_phosphate +
        gramsEmittedByInputPerAcre("p_diammonium_phosphate") *
          this.scenario.defaults.p_diammonium_phosphate +
        gramsEmittedByInputPerAcre("potash_fertilizer") *
          this.scenario.defaults.potash_fertilizer;
      total = total / 1000; // convert grams to kg
      return total;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    convertToPounds(product, percentage, rate, units) {
      // console.log("convertToPounds", product, percentage, rate, units);
      let pounds = 0;
      switch (units) {
        case "lbs":
          units = "lb";
          break;
      }
      if (isVolumeUnit(units)) {
        let standardRate = convert(rate, units).to("gallons");
        if (product == "Urea Ammonium Nitrate") {
          pounds = standardRate * 10.66;
        } else if (product == "Anhydrous ammonia") {
          pounds = standardRate * 5.15;
        }
      }
      if (isMassUnit(units)) {
        pounds = convert(rate, units).to("pounds");
      }
      return pounds * (percentage / 100);
    },
    addRecord() {
      this.modalType = "record";
      this.showModal = true;
    },
    addTask() {
      this.selectedTaskId = null;
      this.modalType = "task";
      this.showModal = true;
    },
    editTask(taskId) {
      this.selectedTaskId = taskId;
      this.modalType = "task";
      this.showModal = true;
    },
    assignToInput(product, payload) {
      switch (product) {
        case "Ammonia":
          this.currentInputs.ammonia += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          break;
        case "Urea":
          this.currentInputs.urea += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          break;
        case "Ammonium Nitrate":
          this.currentInputs.ammonium_nitrate += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          break;
        case "Ammonium Sulfate":
          this.currentInputs.ammonium_sulfate += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          break;
        case "Urea Ammonium Nitrate":
          this.currentInputs.urea_ammonium_nitrate_solution +=
            this.convertToPounds(
              product,
              payload.nitrogen,
              payload.rate,
              payload.units,
            );
          break;
        case "Monoammonium Phosphate":
          this.currentInputs.n_monoammonium_phosphate += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          this.currentInputs.p_monoammonium_phosphate += this.convertToPounds(
            product,
            payload.phosphorus,
            payload.rate,
            payload.units,
          );
          break;
        case "Diammonium Phosphate":
          this.currentInputs.n_diammonium_phosphate += this.convertToPounds(
            product,
            payload.nitrogen,
            payload.rate,
            payload.units,
          );
          this.currentInputs.p_diammonium_phosphate += this.convertToPounds(
            product,
            payload.phosphorus,
            payload.rate,
            payload.units,
          );
          break;
        case "Potash Fertilizer":
          this.currentInputs.potash_fertilizer += this.convertToPounds(
            product,
            payload.potassium,
            payload.rate,
            payload.units,
          );
          break;
        case "Lime":
          this.currentInputs.lime += this.convertToPounds(
            product,
            100, // lime is the full product
            payload.rate,
            payload.units,
          );
          break;
      }
      // console.log("currentInputs after assign", this.currentInputs);
    },
    storeRecord(record) {
      record.field = null;
      record.category = "Fertilizer Application";
      record.crop = this.scenario.metadata.crops[0];
      if (record?.payload?.completed_at) {
        record.completed_at = postgresDate(record.payload.completed_at);
        record.payload.completed_at = postgresDate(record.payload.completed_at);
      }
      this.$store.dispatch("createRecord", record);
      this.showModal = false;
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)

      handler(value) {
        // console.log("scenarioInitial changed", value);
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
    records: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        if (!value) {
          return;
        }
        if (this.recordCache.length == value.length) {
          // no change
          return;
        }
        // console.log("records changed", value);
        this.currentInputs.ammonia = 0;
        this.currentInputs.urea = 0;
        this.currentInputs.ammonium_nitrate = 0;
        this.currentInputs.ammonium_sulfate = 0;
        this.currentInputs.urea_ammonium_nitrate_solution = 0;
        this.currentInputs.n_monoammonium_phosphate = 0;
        this.currentInputs.n_diammonium_phosphate = 0;
        this.currentInputs.p_monoammonium_phosphate = 0;
        this.currentInputs.p_diammonium_phosphate = 0;
        this.currentInputs.potash_fertilizer = 0;
        this.currentInputs.lime = 0;
        for (let record of value) {
          this.assignToInput(record.payload.product, record.payload);
          if (record.payload.mix_products) {
            for (let mixProduct of record.payload.mix_products) {
              this.assignToInput(mixProduct.product, mixProduct);
            }
          }
        }
        this.recordCache = value;
        this.emitUpdateScenarioInputs();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
