<template>
  <modal
    :show="showRecordModal"
    dialogPanelClass="max-w-screen-lg"
    :title="null"
  >
    <record-form
      v-if="dataIsLoaded"
      @closeRecord="showRecordModal = false"
      :recordProp="activeRecord"
      :displayOnly="true"
      :recordType="activeRecord.category"
    />
  </modal>

  <page-card :headingTitle="'Order: ' + $route.params.orderId">
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="saveAttachments"
      />
    </template>
    <template #cardDetails>
      <p v-if="!dataIsLoaded">Data is loading...</p>
      <crop-order-display
        v-if="dataIsLoaded"
        :orderId="parseInt($route.params.orderId)"
        @update:buyerNotes="setBuyerNotes"
      />
      <crop-allocation-aggregate
        v-if="dataIsLoaded"
        :allocations="currentAllocations"
        :currentOrderUnits="currentOrder.units"
      />
      <CropHarvestAggregate
        v-if="dataIsLoaded"
        :orderId="parseInt($route.params.orderId)"
        perspective="controller"
      />
      <CropDeliveryAggregate
        v-if="dataIsLoaded"
        :orderId="parseInt($route.params.orderId)"
        perspective="controller"
      />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Allocations
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Records
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }" v-if="currentOffer">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Offer Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Deliveries
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template" v-if="dataIsLoaded">
          <TabPanel as="dl" class="pt-10">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                      filtered: false,
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    { name: 'crop_details.details.variety', label: 'Variety' },
                    {
                      name: 'acres',
                      label: 'Allocated Acres',
                      filtered: false,
                    },
                    {
                      name: '',
                      label: 'Estimated Yield',
                      filtered: false,
                      sorted: false,
                    },
                  ]"
                  :collection="currentAllocations"
                  @update:derived-collection="derivedAllocations = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr
                  v-for="allocation in derivedAllocations"
                  :key="allocation.id"
                >
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="
                          allocation.shared_data.field_details.properties.name
                        "
                        :geojson="allocation.shared_data.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ allocation.shared_data.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ allocation.shared_data.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Variety">
                    {{ allocation.shared_data.crop_details.details.variety }}
                  </table-data>
                  <table-data td-label="Allocated Acres">
                    {{ allocation.acres }}
                  </table-data>
                  <table-data td-label="Estimated Yield">
                    {{
                      allocation.shared_data.crop_details.details.tpa
                        ? allocation.acres *
                            allocation.shared_data.crop_details.details.tpa +
                          " Tons"
                        : "N/A"
                    }}
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <crop-premium-display :premiums="currentPremiums" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                      filtered: false,
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    {
                      name: 'category',
                      label: 'Category',
                      filtered: false,
                    },
                    { name: 'result', label: 'Result' },
                    { name: 'completed_at', label: 'Completed At' },
                    { name: '', label: '', filtered: false, sorted: false },
                  ]"
                  :collection="currentRecords"
                  @update:derived-collection="derivedRecords = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr v-for="(record, idx) in derivedRecords" :key="record">
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="record.field_details.properties.name"
                        :geojson="record.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ record.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ record.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Category">
                    {{ record.category }}
                  </table-data>
                  <table-data td-label="Result">
                    {{ record.result }}
                  </table-data>
                  <table-data td-label="Completed At">
                    {{
                      record.completed_at
                        ? new Date(record.completed_at)
                            .toISOString()
                            .substring(0, 10)
                        : ""
                    }}
                  </table-data>
                  <table-data class="text-center">
                    <button
                      @click="showRecord(idx)"
                      :disabled="false"
                      v-if="record.category != 'Note'"
                    >
                      View
                    </button>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" class="pt-10" v-if="currentOffer">
            <controller-offer-details :offerId="currentOffer.id" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" :unmount="false">
            <!-- <view-attachments-table
              v-if="currentOrder?.status != 'Internal'"
              :attachments="[]"
            /> 
            -->
            <!-- <add-attachments-table
             v-else
              :parentId="currentOrder?.id"
              :attachments="currentOrder?.attachments"
              addStoreAction="uploadBuyerOrderAttachment"
              removeStoreAction="removeBuyerOrderAttachment"
              ref="attachmentsTable"
            /> -->
            <view-attachments-table :attachments="currentOrder?.attachments" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <crop-delivery-table
              :deliveries="currentOrderDeliveries"
            ></crop-delivery-table>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropOrderDisplay from "@/layouts/buyer-grower/crop-orders/CropOrderDisplay.vue";
import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
// import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import CropAllocationAggregate from "@/layouts/buyer-portal/common/orders/CropAllocationAggregate.vue";
import CropDeliveryTable from "@/layouts/buyer-portal/bp-controller/orders/CropDeliveryTable.vue";
import ControllerOfferDetails from "@/layouts/buyer-portal/bp-controller/offers/ControllerOfferDetails.vue";

import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

import PageCard from "@/components/cards/PageCard.vue";

import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import Modal from "@/components/modals/PopupModal.vue";
import CropHarvestAggregate from "@/layouts/common/orders/CropHarvestAggregate.vue";
import CropDeliveryAggregate from "@/layouts/buyer-portal/common/orders/CropDeliveryAggregate.vue";

// import TextInput from "@/components/form/TextInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";

const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    CropOrderDisplay,
    CropPremiumDisplay,
    // AddAttachmentsTable,
    ViewAttachmentsTable,
    TableCard,
    TableHead,
    TableData,
    BaseButton,
    FieldThumbnail,
    CropAllocationAggregate,
    ControllerOfferDetails,
    RecordForm,
    Modal,
    CropDeliveryTable,
    CropHarvestAggregate,
    CropDeliveryAggregate,
    // TextInput,
    // NumberInput,
  },
  data() {
    return {
      derivedAllocations: [],
      derivedRecords: [],
      showRecordModal: false,
      activeRecord: null,
      buyerNotes: {},
    };
  },
  computed: {
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    dataIsLoaded() {
      // check if this.currentOrder, this.currentOffer, this.currentAllocations.length > 0, this.currentRecords.length > 0, this.currentOrderDeliveries.length > 0
      return this.currentOrder && this.currentOffer;
    },
    currentOrder() {
      return this.$store.getters.getControllerOrderById(this.orderId);
    },
    currentAllocations() {
      if (!this.currentOrder) {
        return [];
      }
      return this.$store.getters.getControllerAllocationsByOrder(this.orderId);
    },
    currentRecords() {
      // iterate through currentAllocations and get records from each in an array at record.shared_data.record_details
      if (this.currentAllocations.length === 0) {
        return [];
      }
      let records = [];
      this.currentAllocations.forEach((allocation) => {
        allocation.shared_data.record_details.forEach((record) => {
          records.push(
            Object.assign({}, record, {
              allocation: allocation.id,
              field_details: allocation.shared_data.field_details,
              crop_details: allocation.shared_data.crop_details,
            }),
          );
        });
      });
      return records;
    },
    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      recordTypes = recordTypes.map((recordType) => {
        return {
          label: recordType.category,
          event: recordType.category,
        };
      });
      return recordTypes;
    },
    currentOffer() {
      if (!this.currentOrder) {
        return null;
      }
      return this.$store.getters.getControllerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    currentContract() {
      if (!this.currentOffer) {
        return null;
      }
      return this.$store.getters.getContractById(
        this.currentOffer.buyer_contract,
      );
    },
    currentPremiums() {
      if (!this.currentContract) {
        return [];
      }
      return this.$store.getters.getContractPremiumsByContractId(
        this.currentContract.id,
      );
    },
    currentOrderDeliveries() {
      if (!this.currentOrder) {
        return [];
      }
      let deliveries = this.$store.state.buyerDeliveries.buyerDeliveries.filter(
        (d) => d.crop_order === parseInt(this.orderId),
      );
      return deliveries;
    },
    // currentOfferCropOrdersSummary() {
    //   let cropOrders = this.$store.getters.getControllerCropOrdersByMarketOffer(
    //     this.currentOrder.offer,
    //   );
    //   let summary = {
    //     quantity: this.currentOffer.quantity,
    //     units: this.currentOffer.quantity_units,
    //     // currentAllocation is the sum of each order's allocated_acres attributes
    //     currentAllocation: cropOrders.reduce(
    //       (acc, order) => acc + order.allocated_acres,
    //       0,
    //     ),
    //   };
    //   return summary;
    // },
  },
  methods: {
    showRecord(id) {
      this.activeRecord = this.derivedRecords[id];
      this.displayOnly = true;
      this.showRecordModal = true;
    },
    setBuyerNotes(/*notes*/) {
      // console.log("Set Notes: ", notes);
      // this.buyerNotes = notes;
    },
    saveAttachments() {
      // console.log("saveAttachments", this.$refs, this.$refs.attachmentsTable);
      this.$refs.attachmentsTable.saveAttachments();
    },
    // saveNotes() {
    //   console.log("Save Notes");
    //   let order = this.currentOrder;
    //   order.buyer_notes = this.buyerNotes;
    //   this.$store.dispatch("updateControllerCropOrderNotes", order);
    // },
  },
};
</script>
