<template>
  <table-toolbar>
    <template #toolbarComponents>
      <div v-if="derivedRecords.length == 0">Results</div>
      <div v-else />
      <!-- <table-toolbar-search v-model="searchValue" /> -->
      <table-toolbar-button
        buttonText="Add"
        :usePlusIcon="true"
        @buttonClick="addNewRecord"
      />
    </template>
  </table-toolbar>
  <div v-if="derivedRecords.length > 0">
    <base-table>
      <template #headerCells>
        <table-header-cell :isLeadingCell="true" headerText="Result" />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="By"
        />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="Completed"
        />
        <table-header-cell :isEmptyHeader="true" headerText="Delete Record" />
      </template>
      <template #bodyRows>
        <tr v-for="record in derivedRecords" :key="record.rowid">
          <table-data>
            <text-area
              v-model="record.result"
              :disabled="false"
              @onBlur="updateRecord(record.rowid, $event)"
            />
          </table-data>
          <table-body-cell
            :hiddenOnMediumAndSmallScreens="true"
            :cellData="record?.user?.name"
          />
          <table-body-cell
            :hiddenOnMediumAndSmallScreens="true"
            :cellData="
              new Date(record?.completed_at).toISOString().substring(0, 10)
            "
          />
          <table-data>
            <button @click="deleteRecord(record.rowid)" :disabled="false">
              Delete
            </button>
          </table-data>
          <!-- <table-body-cell :hiddenOnMediumAndSmallScreens="true" :cellData="allocation.acres" /> -->

          <!-- <table-body-cell
          :isLinkCell="true"
          linkDisplayText="View"
          :routerLinkObject="{
            name: 'buyer-vendor-single',
            params: { id: inquiry.listing },
          }"
        /> -->
        </tr>
      </template>
    </base-table>
  </div>
  <div v-else>
    <table-body-cell
      :hiddenOnMediumAndSmallScreens="false"
      cellData="No records"
    />
  </div>
</template>

<script>
import TableToolbar from "@/components/table/TableToolbar.vue";
// import TableToolbarSearch from "@/components/table/TableToolbarSearch.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TextArea from "@/components/form/TextArea.vue";
import TableData from "@/components/table/TableData.vue";
import { postgresDate } from "@/components/composables/dateUtils";

export default {
  components: {
    TableToolbar,
    TextArea,
    // TableToolbarSearch,
    TableToolbarButton,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableData,
  },
  props: {
    currentField: { type: Number, default: null },
    currentEquipment: { type: Number, default: null },
    currentTask: { type: Number, default: null },
    saveEvent: { type: Boolean, default: false },
  },
  data() {
    return {
      searchValue: "",
      filterObject: {},
      pageFixedFilters: {},
      pageSorts: { completed_at: "asc" },
      newRecords: [],
      nextRecordId: 0,
    };
  },
  computed: {
    combinedFilters() {
      return {
        ...this.filterObject,
        ...this.pageFixedFilters,
      };
    },
    derivedRecords() {
      let derivedRecords = this.$store.getters.getDerivedRecords({
        filter: this.combinedFilters, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort: this.pageSorts,
      });
      derivedRecords = derivedRecords.filter(
        (record) => record.task == this.currentTask,
      );
      derivedRecords = derivedRecords.concat(this.newRecords);
      for (let i = 0; i < derivedRecords.length; i++) {
        derivedRecords[i].rowid = i;
      }
      return derivedRecords;
    },
  },
  methods: {
    selectFilter(e) {
      this.filterObject[Object.keys(e)[0]] = String(e[Object.keys(e)[0]]);
    },
    clearSelectedFilter(e) {
      delete this.filterObject[e];
    },
    updateAcres(value) {
      // console.log("updating acres", value);
      let allocation = this.$store.getters.getWorkingAllocationByCropId(
        value.id,
      );
      allocation.acres = value.acres;
      this.$store.dispatch("updateWorkingAllocation", allocation);
      //this.allocations[this.allocations.findIndex(a => a.crop == value.id)].acres = value.acres
    },
    addNewRecord() {
      let newRecord = {
        task: this.currentTask,
        result: "",
        completed_at: new Date(),
        completed_by: this.$store.getters.getCurrentUser.id,
        user: { name: this.$store.getters.getCurrentUser.name },
        newRowId: this.nextRecordId,
      };
      let fieldId = this.currentField;
      let equipmentId = this.currentEquipment;
      if (fieldId == null && equipmentId == null) {
        let task = this.$store.getters.getTaskById(this.currentTask);
        // console.log("task", task);
        if (task.equipment != null) {
          equipmentId = task.equipment;
        } else {
          fieldId = task.field;
        }
      }

      if (fieldId != null) {
        let field = this.$store.getters.getFieldById(fieldId);
        newRecord.crop = field?.properties?.current_crop?.id;
        // console.log(
        //   "new field record",
        //   newRecord,
        //   fieldId,
        //   equipmentId,
        //   this.currentTask
        // );
      } else if (equipmentId != null) {
        newRecord.equipment = equipmentId;
      }
      // console.log(
      //   "added new record",
      //   newRecord,
      //   fieldId,
      //   equipmentId,
      //   this.currentTask
      // );
      this.newRecords.push(newRecord);
      this.nextRecordId++;
    },
    updateRecord(rowid, value) {
      // console.log("updating record result", rowid, value);
      let newRowId = this.derivedRecords.find(
        (row) => row.rowid == rowid,
      ).newRowId;
      if (newRowId != null) {
        this.newRecords.find((row) => row.newRowId == newRowId).result = value;
      }
    },
    deleteRecord(id) {
      let record = this.derivedRecords.find((row) => row.rowid == id);
      if (record.id == null) {
        // pop from newRecords
        let newRowId = record.newRowId;
        // console.log("deleting record", id, this.newRecords, newRowId);
        this.newRecords = this.newRecords.filter(function (row) {
          return row.newRowId !== newRowId;
        });
      } else {
        //remove from the store
        // console.log("deleting record from store", this.derivedRecords[id]);
        this.$store.dispatch("deleteRecord", this.derivedRecords[id]);
      }
    },
  },
  watch: {
    saveEvent() {
      // console.log("saving records", this.saveEvent, this.derivedRecords);
      if (this.saveEvent == true) {
        for (let record of this.derivedRecords) {
          let submitRecord = {
            task: record.task,
            result: record.result,
            completed_at: record.completed_at,
            completed_by: record.completed_by,
          };
          if (record?.payload?.completed_at) {
            submitRecord.completed_at = postgresDate(
              record.payload.completed_at,
            );
            submitRecord.payload.completed_at = postgresDate(
              record.payload.completed_at,
            );
          }
          if (record.crop != null) {
            submitRecord.crop = record.crop;
          } else if (record.equipment != null) {
            submitRecord.equipment = record.equipment;
          } else if (record.field != null) {
            submitRecord.field = record.field;
          }
          if (record.newRowId != null) {
            // console.log("saving new record", submitRecord);
            this.$store.dispatch("createRecord", submitRecord);
          } else {
            // console.log("updating record", submitRecord);
            let id = record.id;
            this.$store.dispatch("updateRecord", {
              recordId: id,
              record: submitRecord,
            });
          }
        }
      }
    },
  },
};
</script>

<style></style>
