<template>
  <form
    @submit.prevent="() => submitVendorProductForm()"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div class="flex justify-center pr-4 sm:col-span-6 md:justify-start">
      <div>
        <select-dropdown
          v-if="!productId"
          :wideItem="true"
          :select-options="categoryExamples"
          inputTitle="Category"
          v-model="currentProduct.category"
          :required="true"
        />
        <select-dropdown
          v-else
          :wideItem="true"
          inputTitle="Category"
          :displayOnlyValue="currentProduct.category"
          :displayOnly="true"
        />
        <img
          v-if="pendingProductImage"
          :src="pendingProductImageDisplayUrl"
          class="mt-4 w-96"
        />
        <img
          v-else-if="!currentProduct.image_url"
          src="@/assets/no_logo_default.jpeg"
          class="mt-4 h-48 w-48"
        />
        <img v-else :src="currentProduct.image_url" class="w-96" />
        <file-select
          buttonText="Edit Product Image"
          @input="addPendingImage"
          accept="image/*"
          class="mt-8"
        />
        <!-- <variable-string-type-input
          :wideItem="true"
          type="url"
          inputTitle="Image URL"
          v-model="currentProduct.image_url"
          :required="true"
          v-else
        /> -->
      </div>
    </div>
    <variable-string-type-input
      :wideItem="true"
      inputTitle="Product Name"
      v-model="currentProduct.title"
      :required="true"
    />
    <text-area
      :wideItem="true"
      inputTitle="Description"
      v-model="currentProduct.description"
      :required="true"
    />

    <select-dropdown
      :wideItem="true"
      :select-options="durationOptions"
      inputTitle="Agreement Valid"
      v-model="currentProduct.default_duration"
      :required="true"
      help-text="This is the amount of time that a grower will be able to place orders against the quote, once approved. This can be changed on a per quote basis."
      v-if="currentProduct.category != 'Input'"
    />
  </form>

  <div
    class="mx-auto mt-8 w-full max-w-2xl lg:col-span-6 lg:mt-12 lg:max-w-none"
  >
    <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
      <div class="border-b border-gray-200">
        <TabList class="-mb-px flex space-x-8">
          <Tab as="template" v-slot="{ selected }">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Details
            </button>
          </Tab>
          <Tab as="template" v-slot="{ selected }">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Resources
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            :hidden="!tabDisplay?.quotes"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Quote Form
            </button>
          </Tab>
          <Tab as="template" v-slot="{ selected }" :hidden="!tabDisplay.orders">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Order Form
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            :hidden="!tabDisplay.filters"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Filters
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            :hidden="!tabDisplay.inputUnits"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              SKU Information
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels as="template">
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <div class="mt-2"></div>
          <rich-text-input :wideItem="true" v-model="currentProduct.details" />
        </TabPanel>
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <div class="mt-2"></div>
          <add-attachments-table
            :parentId="currentProduct.id"
            :addStoreAction="
              currentProduct.category === 'Input'
                ? 'uploadVendorInputAttachment'
                : 'uploadVendorProductAttachment'
            "
            :removeStoreAction="
              currentProduct.category === 'Input'
                ? 'removeVendorInputAttachment'
                : 'removeVendorProductAttachment'
            "
            :attachments="currentProduct.attachments"
            ref="attachmentsTable"
          />
        </TabPanel>
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <div class="mt-2 sm:col-span-3">
            <label class="block text-sm font-medium text-gray-700">
              Assets the grower must share with the quote
              <span class="text-red-600">*</span>
            </label>
            <checkbox-input
              inputTitle="Fields"
              :wideItem="true"
              v-model="currentProduct.agreement_layout.data_sharing.fields"
            />
            <checkbox-input
              inputTitle="Equipment"
              :wideItem="true"
              v-model="currentProduct.agreement_layout.data_sharing.equipment"
            />
          </div>
          <div class="mt-2 sm:col-span-3">
            <component :is="productQuoteCategory" :readOnly="true" />
          </div>

          <product-variants-input
            @update-variant-list="setCurrentProductAgreementVariants"
            :initialVariantList="currentProduct.agreement_layout.variants"
          />
          <product-options-input
            @update-options-list="setCurrentProductAgreementOptions"
            :initialOptionsList="currentProduct.agreement_layout.options"
          />
          <product-form-input
            @updateFormList="setCurrentProductAgreementForm"
            :initialFormList="currentProduct.agreement_layout.form"
          />
        </TabPanel>
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <!-- <div class="mt-2"></div>
          This information will be delivered to you within each order.
          <div class="mt-2"></div> -->
          <div
            class="mt-2"
            v-if="
              currentProduct?.category === 'Hauling' ||
              currentProduct?.category === 'Recommendations'
            "
          >
            <label class="block text-sm font-medium text-gray-700">
              Assets the grower must share with the order
              <span class="text-red-600">*</span>
            </label>
            <checkbox-input
              inputTitle="Fields"
              :wideItem="true"
              :displayOnly="true"
              :modelValue="true"
            />
            <!-- <checkbox-input
              inputTitle="Equipment"
              :wideItem="true"
              :displayOnly="true"
              :modelValue="false"
            /> -->
          </div>
          <div class="mt-2" v-else>
            <label class="block text-sm font-medium text-gray-700">
              Assets the grower must share with the order
              <span class="text-red-600">*</span>
            </label>

            <checkbox-input
              inputTitle="Fields"
              :wideItem="true"
              v-model="currentProduct.order_layout.data_sharing.fields"
            />
            <checkbox-input
              inputTitle="Equipment"
              :wideItem="true"
              v-model="currentProduct.order_layout.data_sharing.equipment"
            />
          </div>

          <div class="mt-2 sm:col-span-3">
            <component :is="productOrderCategory" :readOnly="true" />
          </div>
          <product-variants-input
            :initialVariantList="currentProduct.order_layout.variants"
            @update-variant-list="setCurrentProductOrderVariants"
          />
          <product-options-input
            @update-options-list="setCurrentProductOrderOptions"
            :initialOptionsList="currentProduct.order_layout.options"
          />
          <product-form-input
            @updateFormList="setCurrentProductOrderForm"
            :initialFormList="currentProduct.order_layout.form"
          />
        </TabPanel>
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <crop-input-listing-details
            :currentProduct="currentProduct"
            @update:currentProduct="setCurrentProductAttributes"
            v-if="currentProduct.category === 'Input'"
          />
          <div v-else>
            <list-input
              :taglist="currentProduct.keywords"
              @updateTags="setCurrentProductKeywords"
              inputTitle="Search Keywords"
              help-text="Add additional keywords that don't appear in your product name or description. These will be used to help growers find your product."
            />
          </div>
        </TabPanel>
        <TabPanel class="text-sm text-gray-500" :unmount="false">
          <input-units-form-input
            ref="inputUnitsFormInput"
            v-if="currentProduct.category === 'Input'"
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
  <!-- {{ currentProduct }} -->
  <!-- <p>{{ pendingProductAttachments }} - Attachments</p> -->
  <!-- <p>{{ pendingProductAttachmentRemovals }} - Attachment Removals</p> -->
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import FileSelect from "@/components/form/FileSelect.vue";
import ListInput from "@/components/form/ListInput.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import ProductFormInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductFormInput.vue";

import ProductOptionsInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductOptionsInput.vue";
import ProductVariantsInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductVariantsInput.vue";
import InputUnitsFormInput from "@/layouts/vendor-portal/products/product-form-inputs/InputUnitsFormInput.vue";
import RichTextInput from "@/components/form/RichTextInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";

import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import DefaultQuoteDetails from "@/layouts/common/quotes/quote-details/DefaultQuoteDetails.vue";
import HaulingQuoteDetails from "@/layouts/common/quotes/quote-details/HaulingQuoteDetails.vue";

import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
import InputOrderDetails from "@/layouts/common/orders/order-details/InputOrderDetails.vue";

import CropInputListingDetails from "@/layouts/vendor-portal/products/product-listing-details/CropInputListing.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";

import { useToast } from "vue-toastification";

// import moment from "moment";
export default {
  components: {
    TextArea,
    FileSelect,
    VariableStringTypeInput,
    ProductFormInput,
    ProductOptionsInput,
    ProductVariantsInput,
    RichTextInput,
    ListInput,
    RadioInput,
    CheckboxInput,
    SelectDropdown,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableToolbar,
    // TableToolbarButton,
    DefaultQuoteDetails,
    HaulingQuoteDetails,
    DefaultOrderDetails,
    HaulingOrderDetails,
    InputOrderDetails,
    CropInputListingDetails,
    InputUnitsFormInput,
    AddAttachmentsTable,
  },
  props: {
    productId: {
      type: Number,
      default: null,
    },
    productCategory: {
      type: String,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
  },
  emits: ["updateVendorProduct"],
  // currentProduct:{
  //       title: "",
  //       description: "",
  //       details: ""
  //       variants: [], // [{label: "", choices: []}]
  //       options: [], // [{name: "", description: ""}]
  //       prices: {},
  //       keywords: [],
  //       data_sharing: {
  //         fields: false,
  //         equipment: false,
  //       },
  //       form: [], // [{label: "", question: ""}]
  //       active: false,
  //     }
  data() {
    const toast = useToast();
    const myOrg = this.$store.getters.getCurrentOrg;
    let categoryExamples = [
      { label: "Product", value: "Product" },
      { label: "Service", value: "Service" },
    ];
    if (myOrg.feature_flags.includes("vp-inputs")) {
      categoryExamples.push({ label: "Crop Inputs", value: "Input" });
    }
    if (myOrg.feature_flags.includes("vp-carbon")) {
      categoryExamples.push({
        label: "Carbon Emissions Reporting",
        value: "Carbon",
      });
    }
    if (myOrg.feature_flags.includes("vp-consulting")) {
      categoryExamples.push({
        label: "Agronomic Recommendation Service",
        value: "Recommendation",
      });
    }
    if (myOrg.feature_flags.includes("vp-hauling")) {
      categoryExamples.push({ label: "Hauling", value: "Hauling" });
    }
    return {
      currentProduct: {
        title: "",
        attachments: [],
        description: "",
        agreement_layout: {
          variants: [], // [{label: "", choices: []}]
          options: [], // [{name: "", description: ""}]
          form: [], // [{label: "", question: ""}]
          data_sharing: {
            fields: false,
            equipment: false,
          },
        },
        order_layout: {
          variants: [], // [{label: "", choices: []}]
          options: [], // [{name: "", description: ""}]
          form: [], // [{label: "", question: ""}]
          data_sharing: {
            fields: false,
            equipment: false,
          },
        },
        keywords: [],
        details: "", // String of "<p>Rich Text Content</p>"
        default_duration: 30, // Int of Days
        active: false,
        category: "",
        rates: {},
        data_policy: {
          scenarios: "None",
        },
      },
      pendingProductImage: null,
      pendingProductAttachments: [],
      pendingProductAttachmentRemovals: [],
      variantsArray: [],
      optionsArray: [],
      keywordsArray: [],
      formArray: [],
      toast,
      selectedTab: 0,
      categoryExamples: categoryExamples,

      durationOptions: [
        {
          label: "14 Days",
          value: 14,
        },
        {
          label: "30 Days",
          value: 30,
        },
        {
          label: "60 Days",
          value: 60,
        },
        {
          label: "90 Days",
          value: 90,
        },
        {
          label: "365 Days",
          value: 365,
        },
        {
          label: "Evergreen",
          value: 9999,
        },
      ],
    };
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    existingProduct() {
      if (!this.productId) return null;
      if (this.productCategory == "Input") {
        return this.$store.getters.getVendorInputListingById(this.productId);
      } else {
        return this.$store.getters.getVendorProductById(this.productId);
      }
    },
    pendingProductImageDisplayUrl() {
      if (this.pendingProductImage) {
        return URL.createObjectURL(this.pendingProductImage);
      } else {
        return null;
      }
    },
    productQuoteCategory() {
      switch (this.currentProduct.category) {
        case "Hauling":
          return "HaulingQuoteDetails";
        // case "Recommendation":
        //   return "InputQuoteDetails";
        default:
          return "DefaultQuoteDetails";
      }
    },
    productOrderCategory() {
      switch (this.currentProduct.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Recommendation":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
    tabDisplay() {
      let tabDisplay = {
        quotes: true,
        orders: true,
        filters: true,
        inputUnits: false,
      };
      if (this.currentProduct.category === "Input") {
        tabDisplay.quotes = false;
        tabDisplay.orders = false;
        tabDisplay.filters = true;
        tabDisplay.inputUnits = true;
      }
      return tabDisplay;
    },
  },
  methods: {
    // Filters tab
    setCurrentProductAttributes(product) {
      // console.log("update product attributes", product);
      switch (product.category) {
        case "Input":
          this.currentProduct.mode_of_action = product.mode_of_action;
          this.currentProduct.crops = product.crops;
          this.currentProduct.pests = product.pests;
          this.currentProduct.active_ingredients = product.active_ingredients;
          this.currentProduct.states = product.states;
          this.currentProduct.type = product.type;
          break;
      }
    },
    // Setters
    setCurrentProductKeywords(keywordsArray) {
      this.currentProduct.keywords = keywordsArray;
    },
    // Agreement Form
    setCurrentProductAgreementForm(formArray) {
      this.currentProduct.agreement_layout.form = formArray;
    },
    setCurrentProductAgreementOptions(optionsArray) {
      this.currentProduct.agreement_layout.options = optionsArray;
    },
    setCurrentProductAgreementVariants(variantsArray) {
      this.currentProduct.agreement_layout.variants = variantsArray;
    },
    // Order Form
    setCurrentProductOrderForm(formArray) {
      this.currentProduct.order_layout.form = formArray;
    },
    setCurrentProductOrderOptions(optionsArray) {
      this.currentProduct.order_layout.options = optionsArray;
    },
    setCurrentProductOrderVariants(variantsArray) {
      this.currentProduct.order_layout.variants = variantsArray;
    },
    // Tab Change
    changeTab(index) {
      this.selectedTab = index;
    },
    // Submit Form
    async submitVendorProductForm() {
      if (this.productId) {
        await this.updateVendorProduct();
        if (!this.$store.getters.getApiError) {
          this.toast.success("Product Updated!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      } else {
        // console.log("create product", this.currentProduct);
        await this.createVendorProduct();
        if (!this.$store.getters.getApiError) {
          this.toast.success("Product Created!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      }
    },
    // Form Methods
    readVendorProduct() {
      if (this.productCategory == "Input") {
        this.currentProduct = this.existingProduct;

        if (!this.currentProduct.description) {
          // placeholder for missing description. Can remove when we have it in the schema
          this.currentProduct.description = "";
        }
        // placeholders since this won't exist on an input listing
        this.currentProduct.agreement_layout = {
          data_sharing: {
            fields: false,
            equipment: false,
          },
          form: [],
          options: [],
          variants: [],
        };
        this.currentProduct.order_layout = {
          data_sharing: {
            fields: false,
            equipment: false,
          },
          form: [],
          options: [],
          variants: [],
        };
        for (let attachment of this.currentProduct.attachments) {
          attachment.owner = this.currentOrg.id;
        }
      } else {
        let readVendorProduct = structuredClone(
          this.$store.getters.getVendorProductById(this.productId),
        );
        if (readVendorProduct.attachments) {
          readVendorProduct.attachments = readVendorProduct.attachments.map(
            (attachment) => ({
              ...attachment,
              filename: attachment.attachment_name,
              id: attachment.attachment_id,
            }),
          );
        } else {
          readVendorProduct.attachments = [];
        }
        // for (let attachment in readVendorProduct.attachments) {
        //   console.log("Attachment", readVendorProduct, attachment);
        //   // attachment.filename = attachment.attachment_name;
        // }
        this.currentProduct = readVendorProduct;
      }
      // console.log("Current Product", this.currentProduct);
    },
    async createVendorProduct() {
      if (this.currentProduct.details === "<p></p>") {
        this.currentProduct.details = null;
      }
      if (
        this.currentProduct.category === "Hauling" ||
        this.currentProduct.category === "Recommendation"
      ) {
        this.currentProduct.order_layout.data_sharing.fields = true;
        this.currentProduct.order_layout.data_sharing.equipment = false;
      }
      if (this.currentProduct.category === "Carbon") {
        this.currentProduct.agreement_layout.data_sharing.fields = true;
        this.currentProduct.agreement_layout.data_sharing.equipment = true;
        this.currentProduct.order_layout.data_sharing.fields = true;
        this.currentProduct.order_layout.data_sharing.equipment = true;
        this.currentProduct.data_policy.scenarios = "Read/Write";
      }
      let response = null;
      switch (this.currentProduct.category) {
        case "Input": {
          let listing = {
            title: "",
            description: "",
            details: "",
            image_url: "",
            manufacturer: "",
            epa_number: "",
            category: "",
            mode_of_action: "",
            active_ingredients: "",
            pests: "",
            crops: "",
            states: "",
            keywords: [],
            active_listing: false,
          };
          listing.title = this.currentProduct.title;
          listing.description = this.currentProduct.description;
          listing.image_url = this.currentProduct.image_url;
          listing.manufacturer = this.currentProduct.manufacturer;
          listing.epa_number = this.currentProduct.epa_number;
          listing.category = this.currentProduct.category;
          listing.mode_of_action = this.currentProduct.mode_of_action;
          listing.active_ingredients = this.currentProduct.active_ingredients;
          listing.pests = this.currentProduct.pests;
          listing.crops = this.currentProduct.crops;
          listing.states = this.currentProduct.states;
          listing.keywords = this.currentProduct.keywords;
          if (listing.keywords == "") {
            listing.keywords = [];
          }
          listing.active = false;
          response = await this.$store.dispatch(
            "createVendorInputListing",
            listing,
          );
          this.currentProduct = response;
          // now put back dummy data for unused tabs
          this.currentProduct.agreement_layout = {
            data_sharing: {
              fields: false,
              equipment: false,
            },
            form: [],
            options: [],
            variants: [],
          };
          this.currentProduct.order_layout = {
            data_sharing: {
              fields: false,
              equipment: false,
            },
            form: [],
            options: [],
            variants: [],
          };
          await this.$refs.inputUnitsFormInput.saveSkus(response.id);
          break;
        }
        default:
          // console.log("Create Vendor Product Response", response);
          if (this.pendingProductImage) {
            await this.uploadImageToVendorProduct(response.id);
          }
          response = await this.$store.dispatch(
            "createVendorProduct",
            this.currentProduct,
          );
          this.currentProduct = response;
          if (!this.currentProduct.attachments)
            this.currentProduct.attachments = [];
          break;
      }
      this.$refs.attachmentsTable.saveAttachments();
      if (!this.$store.getters.getApiError) {
        this.$router.push({
          name: "vendor-single-product",
          params: {
            productId: response.id,
            productCategory: response.category,
          },
        });
      }
    },
    async updateVendorProduct(publish = null) {
      if (this.currentProduct.details === "<p></p>") {
        this.currentProduct.details = null;
      }
      if (publish === true) {
        this.currentProduct.active = true;
      } else if (publish === false) {
        this.currentProduct.active = false;
      }
      let vendorProductUpdate = this.currentProduct;
      delete vendorProductUpdate.seller;
      delete vendorProductUpdate.updated;
      // this.pendingProductAttachments = [];
      // this.pendingProductAttachmentRemovals = [];
      if (vendorProductUpdate.keywords == "") {
        vendorProductUpdate.keywords = [];
      }
      switch (vendorProductUpdate.category) {
        case "Input":
          if (this.pendingProductImage) {
            await this.uploadImageToVendorProduct(vendorProductUpdate.id);
          }
          if (this.pendingProductAttachments) {
            await this.uploadVendorInputAttachments(vendorProductUpdate.id);
          }
          if (this.pendingProductAttachmentRemovals) {
            await this.removeVendorInputAttachments();
          }
          this.pendingProductAttachments = [];
          this.pendingProductAttachmentRemovals = [];
          await this.$store.dispatch(
            "updateVendorInputListing",
            vendorProductUpdate,
          );
          await this.$refs.inputUnitsFormInput.saveSkus(vendorProductUpdate.id);
          await this.$store.dispatch("getVendorInputListings");
          await this.$store.dispatch("getAllVendorInputUnits");
          break;
        default:
          delete vendorProductUpdate.image_url;
          if (this.pendingProductImage) {
            await this.uploadImageToVendorProduct(vendorProductUpdate.id);
          }
          // if (this.pendingProductAttachments) {
          //   await this.uploadVendorProductAttachments(vendorProductUpdate.id);
          // }
          // if (this.pendingProductAttachmentRemovals) {
          //   await this.removeVendorProductAttachments();
          // }
          // this.pendingProductAttachments = [];
          // this.pendingProductAttachmentRemovals = [];
          await this.$store.dispatch(
            "updateVendorProduct",
            vendorProductUpdate,
          );
          break;
      }
      await this.$refs.attachmentsTable.saveAttachments();
      this.readVendorProduct();
      // this.$router.push({ name: "vendor-products-all" });
    },
    clearForm() {
      this.currentProduct = {
        title: "",
        attachments: [],
        description: "",
        agreement_layout: {
          variants: [], // [{label: "", choices: []}]
          options: [], // [{name: "", description: ""}]
          form: [], // [{label: "", question: ""}]
          data_sharing: {
            fields: false,
            equipment: false,
          },
        },
        order_layout: {
          variants: [], // [{label: "", choices: []}]
          options: [], // [{name: "", description: ""}]
          form: [], // [{label: "", question: ""}]
          data_sharing: {
            fields: false,
            equipment: false,
          },
        },
        keywords: [],
        details: "", // String of "<p>Rich Text Content</p>"
        default_duration: 30, // Int of Days
        active: false,
        category: "",
        rates: {},
        data_policy: {
          scenarios: "None",
        },
      };
    },
    addPendingImage(file) {
      this.pendingProductImage = file;
    },
    addFileToPendingAttachment(file) {
      this.pendingProductAttachments.push(file);
    },
    addFileToPendingAttachmentRemovals(file_url) {
      this.pendingProductAttachmentRemovals.push(file_url);
    },
    isPendingDelete(attachment) {
      return this.pendingProductAttachmentRemovals.includes(attachment);
    },
    removeFromPendingAttachment(attachment) {
      this.pendingProductAttachments = this.pendingProductAttachments.filter(
        (file) => file !== attachment,
      );
    },
    async uploadImageToVendorProduct(vendorProductId) {
      // console.log(
      //   "uploading image to vendor product",
      //   this.pendingProductImage,
      // );
      if (this.currentProduct.category === "Input") {
        await this.$store.dispatch("uploadVendorInputImage", {
          productId: vendorProductId,
          file: this.pendingProductImage,
        });
      } else {
        await this.$store.dispatch("uploadVendorProductImage", {
          productId: vendorProductId,
          file: this.pendingProductImage,
        });
      }
    },
    async uploadVendorProductAttachments(vendorProductId) {
      for (let file of this.pendingProductAttachments) {
        await this.$store.dispatch("uploadVendorProductAttachment", {
          productId: vendorProductId,
          file,
        });
      }
    },
    async removeVendorProductAttachments() {
      for (let attachment of this.pendingProductAttachmentRemovals) {
        await this.$store.dispatch(
          "removeVendorProductAttachment",
          attachment.id,
        );
      }
    },
    async uploadVendorInputAttachments(vendorProductId) {
      for (let file of this.pendingProductAttachments) {
        // console.log("vendorProductId", vendorProductId, " - file: ", file);
        await this.$store.dispatch("uploadVendorInputAttachment", {
          productId: vendorProductId,
          file,
        });
      }
    },
    async removeVendorInputAttachments() {
      for (let attachment of this.pendingProductAttachmentRemovals) {
        await this.$store.dispatch(
          "removeVendorInputAttachment",
          attachment.id,
        );
      }
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
  },

  watch: {
    existingProduct: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readVendorProduct();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    productId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (!value) {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
