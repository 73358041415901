<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'affiliate_name', label: 'Buyer' },
          { name: 'contract_name', label: 'Contract' },
          { name: 'crop', label: 'Crop' },
          { name: 'quantity', label: 'Quantity Requested', filtered: false },
          { name: 'premiums', label: 'Attached Premiums', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="offers"
        @update:derived-collection="derivedOffers = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="offer in derivedOffers" :key="offer.id">
        <table-data td-label="Buyer">
          {{ offer.affiliate_name }}
        </table-data>
        <table-data td-label="Contract">
          {{ offer.contract_name }}
        </table-data>
        <table-data td-label="Crop">
          {{ offer.crop }}
        </table-data>
        <table-data td-label="Quantity">
          {{
            offer.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
          {{ offer.quantity_units }}
        </table-data>
        <table-data td-label="Premiums">
          {{ offer.premiumSummary }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-portal-market-offer-single',
              params: { offerId: offer.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import { summarizePremium } from "@/components/composables/scriptUtils";

export default {
  components: {
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedOffers: [],
    };
  },
  computed: {
    offers() {
      let offers = this.$store.getters.getGrowerMarketOffers();
      offers = offers.filter((o) => {
        if (o.remaining_quantity === 0) {
          return false;
        }
        if (
          o.commitment_date !== null &&
          new Date(o.commitment_date) < new Date()
        ) {
          return false;
        }
        return true;
      });
      for (let offer of offers) {
        if (offer.premiums.length == 0) {
          offer.premiumSummary = "None";
        } else {
          offer.premiumSummary = offer.premiums
            .map((p) => {
              // let premium = "";
              // // console.log("p", p);
              // switch (p.type) {
              //   case "fixed":
              //     if (p.total_or_unit == "unit") {
              //       premium =
              //         " $" + p.base_premium?.toFixed(2) + "/" + p.harvest_units;
              //     } else {
              //       premium = " $" + p.base_premium?.toFixed(2) + "  ";
              //     }
              //     break;
              //   case "range":
              //     premium =
              //       " $" +
              //       p.premium_per_point?.toFixed(2) +
              //       "/" +
              //       p.score_units;
              //     break;
              //   case "threshold":
              //     premium =
              //       " $" + p.base_premium?.toFixed(2) + "/" + p.score_units;
              //     break;
              //   case "other":
              //     premium = p.terms;
              // }
              // return premium;
              return summarizePremium(p);
            })

            .join(", ");
        }
      }
      // console.log("GETTING OFFERS", offers);

      return offers;
    },
  },
  methods: {},
};
</script>

<style></style>
