<!-- MapViewer is a read only viewer that displays a set of features in layers from the prop:
layers: [
  // this format for geoJSON  
  {
    name:'', // layer name to show in layer selector
    geojson:{
      type: "FeatureCollection",
      features:[
        geometry: {} // any valid geojson geometry
        properties: {
          name: '' //feature label to show when hover / tap
          link: '' //path to redirect to if selected and the goto button pressed. Optional unless showGoToFeature=true
        }
      ]
    }, 
    style:{color:'blue',opacity:1, weight:1, }  // optional use leaflet style format: https://leafletjs.com/SlavaUkraini/reference.html#path-option
  },
  - or this format for image overlays- 
  {
    key:'url to key image'
    images:[{
      url:'',
      bounds:[]
    }]
  }

  ]
height='h-3/4' // optional
width='w-full'  // optional
showLayerSelector=false // optional
showTurnByTurn=false // optional
showGoToFeature=false // optional, link mandatory if used.
-->
<template>
  <div>
    <div class="relative flex" :class="style">
      <div
        id="mapid"
        class="z-0"
        style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
      >
        <!-- style="height: 100vh; width: 100vw"> -->
        <!-- Map Display here -->
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";

// This is workaround code to make leaflet work with webpack.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// end of workaround

export default {
  props: {
    layers: { type: Array },
    height: { type: String, default: "h-3/4" },
    width: { type: String, default: "w-full" },
    showLayerSelector: { type: Boolean, default: false },
    showTurnByTurn: { type: Boolean, default: false },
    showEditForm: { type: Boolean, default: false },
    showGoToFeature: { type: Boolean, default: false },
    hideAttribution: { type: Boolean, default: false },
    showAssignFeature: { type: Boolean, default: false },
    showLabels: { type: Boolean, default: false },
    multiPick: { type: Boolean, default: false },
  },
  emits: ["selectedFeatures"],
  data: function () {
    return {
      message: "initial",
      loading: false,
      access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      area: "",
      distance: "",
      selectedFeature: null,
      selectedFeatures: [],
    };
  },
  created() {
    this.map = null; // create global private map variable. Do not put in data (reactivity issues)
  },
  mounted() {
    this.createMap();
  },
  unmounted() {
    if (this.map) this.map = this.map.remove();
  },
  methods: {
    async createMap() {
      // try {
      // Create the map
      let map = null;
      if (this.map) {
        this.map.off();
        this.map = this.map.remove();
      }
      map = L.map("mapid");
      this.map = map;

      // var container = L.DomUtil.get("mapid");
      // if (container != null) {
      //   container._leaflet_id = null;
      // }
      // map = new L.map("mapid");

      // Initialise the FeatureGroup to store visible layers
      var visibleLayers = new L.FeatureGroup();
      map.addLayer(visibleLayers);
      if (this.showLayerSelector)
        var layerControl = L.control
          .layers(null, null, { collapsed: false })
          .addTo(map);

      // add the satellite base layer
      let attribution =
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';
      if (this.hideAttribution) attribution = "";
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution: attribution,
          maxZoom: 20,
          minZoom: 1,
          id: "airrer1/ckqqvow3854p218l3d6lo43s5",
          tileSize: 512,
          zoomOffset: -1,
          accessToken: this.access_token,
        },
      ).addTo(map);

      const componentHandle = this; // need this to get handle to 'this' component when embedded in click method.
      let mapBounds = [];

      // console.log("layers", this.layers);

      // Add the layers to the map
      for (let layer of this.layers) {
        let features = layer;
        // console.log("features", features);
        let geojson_points = features.geojson;
        // console.log("points", geojson_points);
        let layerStyle = null;
        if (layer.style) layerStyle = layer.style;
        // console.log(JSON.stringify(geojson_points));
        let mapLayer = null; // will add as either an image layer or a geojson layer.
        // Now add the key if present
        var legend = L.control({ position: "bottomleft" });
        legend.onAdd = function () {
          var div = L.DomUtil.create("div", "legend");
          // let label = "<img  width='50' src='" + layer.key + "' />";
          // div.innerHTML = label;
          // console.log("key", layer.key);
          div.innerHTML = layer.key;
          return div;
        };
        legend.addTo(map);
        if (layer.images != null) {
          mapLayer = new L.LayerGroup(); // adds a set of images as a single layer so we can turn it on / off.
          //Image layer
          layer.images.forEach((image) => {
            // console.log("image overlay", image);
            L.imageOverlay(image.url, image.bounds).addTo(mapLayer);
          });

          mapLayer.addTo(map);
        } else {
          //GeoJSON layer
          // console.log("add layer", this.showLabels);
          let showLabels = this.showLabels; // 'this' will change context in the onEachFeature function
          mapLayer = L.geoJSON(geojson_points, {
            onEachFeature: function (feature, layer) {
              layer.bindTooltip(layer.feature.properties["name"], {
                permanent: showLabels,
              }),
                layer.on({
                  click: (e) => {
                    //calls up the feature clicked on

                    var highlightStyle = {
                      opacity: 1,
                      weight: 5,
                      color: "red",
                    };
                    var unHighlightStyle = {
                      opacity: 1,
                      weight: 3,
                      color: "#3388ff",
                    };
                    var selectedFeature = e.target;
                    if (selectedFeature && selectedFeature.feature) {
                      if (componentHandle.multiPick) {
                        if (
                          componentHandle.selectedFeatures.includes(
                            selectedFeature.feature,
                          )
                        ) {
                          componentHandle.selectedFeatures =
                            componentHandle.selectedFeatures.filter(
                              (feature) =>
                                feature.id !== selectedFeature.feature.id,
                            );
                          console.log(
                            "remove",
                            selectedFeature.feature,
                            componentHandle.selectedFeatures,
                          );
                          let style = layerStyle
                            ? layerStyle
                            : unHighlightStyle;
                          selectedFeature.setStyle(style);
                        } else {
                          componentHandle.selectedFeatures.push(
                            selectedFeature.feature,
                          );
                          componentHandle.selectedFeature =
                            selectedFeature.feature;
                          selectedFeature.setStyle(highlightStyle);
                        }
                      } else {
                        mapLayer.resetStyle();
                        componentHandle.selectedFeature =
                          selectedFeature.feature; //save link to selected feature so we can navigate to it if asked
                        selectedFeature.bringToFront();
                        selectedFeature.setStyle(highlightStyle);
                      }
                    }
                  },
                });
              visibleLayers.addLayer(layer);
            },
            style: layerStyle,
          }).addTo(map);

          // console.log(
          //   "LAYER BOUNDS",
          //   mapLayer.getBounds(),
          //   mapLayer.getBounds().isValid(),
          // );
          if (mapLayer.getBounds().isValid()) {
            mapBounds.push(mapLayer.getBounds());
          }
        }
        if (this.showLayerSelector)
          layerControl.addOverlay(mapLayer, layer.name);
        // Display field name on hover (tap on mobile)

        // Old method of adding tooltips. Doesn't work with permanent, so moved up to onEachFeature
        // mapLayer.bindTooltip(
        //   function (featureCollection) {
        //     // console.log("tooltip", featureCollection);
        //     return featureCollection.feature.properties?.name;
        //   },
        // );
      }

      // console.log("MAP BOUNDS", mapBounds);

      if (mapBounds.length > 0) {
        map.fitBounds(mapBounds);
      } else {
        let centroid = [38.7946, -106.5348];
        map.setView(centroid, 4);
      }

      // Initialise the draw control as empty / view only and pass it the FeatureGroup of viewable layers
      map.pm.addControls({
        position: "topleft",
        drawMarker: false,
        drawPolygon: false,
        drawPolyline: false,
        drawRectangle: false,
        drawCircleMarker: false,
        drawCircle: false,
        drawText: false,
        editMode: false,
        cutPolygon: false,
        removalMode: false,
        rotateMode: false,
        dragMode: false,
      });

      map.pm.setGlobalOptions({ snappable: false });
      if (this.showGoToFeature) {
        map.pm.Toolbar.createCustomControl({
          name: "attributes",
          block: "edit",
          title: "Edit Selected Field",
          className: "leaflet-pm-icon-goto",
          onClick: (e) => {
            if (e) {
              if (this.selectedFeature == null) {
                alert("Please select a field to edit its attributes");
              } else {
                //console.log("go to",this.selectedFeature)
                this.$router.push(this.selectedFeature?.properties?.link);
              }
            }
          },
        });
      }
      if (this.showAssignFeature) {
        map.pm.Toolbar.createCustomControl({
          name: "assignments",
          block: "edit",
          title: "Assign Selected Field",
          className: "leaflet-pm-icon-assign",
          onClick: (e) => {
            if (e) {
              if (this.selectedFeature == null) {
                alert("Please select a field to edit its attributes");
              } else {
                //console.log("go to",this.selectedFeature)
                this.$router.push(
                  this.selectedFeature?.properties?.assign_link,
                );
              }
            }
          },
        });
      }
      if (this.showEditForm) {
        map.pm.Toolbar.createCustomControl({
          name: "edit-attributes",
          block: "edit",
          title: "Edit attributes",
          className: "leaflet-pm-icon-edit-attributes",
          onClick: (e) => {
            if (e) {
              if (
                this.selectedFeature == null &&
                this.selectedFeatures.length == 0
              ) {
                alert("Please select something on the map to edit");
              } else {
                this.$emit("selectedFeatures", this.selectedFeatures);
              }
            }
          },
        });
      }
      if (this.showTurnByTurn) {
        map.pm.Toolbar.createCustomControl({
          name: "directions",
          block: "edit",
          title: "Turn by Turn directions",
          className: "leaflet-pm-icon-directions",
          onClick: (e) => {
            if (e) {
              if (this.selectedFeature == null) {
                alert("Please select something on the map to get directions");
              } else {
                let url = "https://www.google.com/maps/dir/?api=1&destination=";
                let centroid = L.geoJson(this.selectedFeature.geometry)
                  .getBounds()
                  .getCenter();
                url += centroid.lat + "," + centroid.lng;
                // console.log(url, this.selectedFeature, centroid);
                window.open(url, "_blank");
              }
            }
          },
        });
      }
      // } catch (err) {
      //   console.log("map error", err);
      // }
    },
  },
  computed: {
    style() {
      let style = this.height + " " + this.width;
      return style;
    },
  },
  watch: {
    layers: function () {
      // console.log("layers changed", this.layers);
      // this.map.remove();
      if (this.layers.length) this.createMap();
    },
  },
};
</script>
