<template>
  <delete-modal
    :open="deleteModalIsActive"
    modalTitle="Delete Fields"
    modalDescription="Are you sure you want to permanently delete the selected fields?"
    @clickCancel="
      selectedFields = [];
      deleteModalIsActive = false;
    "
    @clickConfirmDelete="deleteSelectedFields"
  />
  <slot-modal
    :open="showEditForm"
    modalTitle="Update Crop"
    @closeModal="
      this.selectedFields = [];
      showEditForm = false;
    "
    dialogPanelClass="sm:max-w-7xl"
  >
    <template #content>
      <crops-form :fields="selectedFields" @closeModal="showEditForm = false" />
    </template>
  </slot-modal>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'properties.farm', label: 'Farm' },
          { name: 'properties.name', label: 'Field', filtered: false },
          { name: 'properties.crop', label: 'Crop' },
          { name: 'properties.details.variety', label: 'Variety' },
          { name: 'properties.acres', label: 'Acres', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="fields"
        @update:derived-collection="derivedFields = $event"
        @update:filter="selectFilter"
        :defaultFilter="defaultFilter"
        :defaultSort="defaultSort"
        selectionMode="Multiple"
        @update:selectedCollection="selectedFields = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <div class="hidden sm:flex">
              <table-toolbar-button
                buttonText="Import"
                heroIcon="UploadIcon"
                @buttonClick="goToImportFieldsPage"
              />
            </div>
            <div class="hidden sm:flex">
              <dropdown-button
                buttonText="Export"
                heroIcon="DownloadIcon"
                :actions="[
                  { label: 'CSV', event: 'csv' },
                  { label: 'GeoJSON', event: 'geojson' },
                  { label: 'Shapefile', event: 'shapefile' },
                  { label: 'FSA 578 Acre Report', event: '578' },
                ]"
                @action="exportTable"
              />
            </div>
            <div class="hidden sm:flex">
              <dropdown-button
                buttonText="Edit Selected"
                heroIcon="DownloadIcon"
                :actions="[
                  { label: 'Delete', event: 'delete' },
                  { label: 'Edit Crops', event: 'edit_crops' },
                ]"
                @action="bulkEdit"
              />
            </div>
            <table-toolbar-button
              class="ml-2"
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewFieldPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="field in derivedFields" :key="field">
        <table-data td-label="Selected">
          <input
            type="checkbox"
            v-model="field.selected"
            class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
            @change="
              field.selected
                ? selectedFields.push(field)
                : (selectedFields = selectedFields.filter(
                    (f) => f.id !== field.id,
                  ))
            "
          />
        </table-data>
        <table-data td-label="Farm">
          <div class="flex">
            <field-thumbnail
              :title="field.properties.name"
              :geojson="field"
              class="mr-6"
            ></field-thumbnail>
            {{ field.properties.farm }}
          </div>
        </table-data>
        <table-data td-label="Field">
          {{ field.properties.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ field.properties.crop }}
        </table-data>
        <table-data td-label="Variety">
          {{
            field.properties?.details?.variety
              ? field.properties?.details?.variety
              : ""
          }}
        </table-data>
        <table-data td-label="Acres">
          {{ field.properties.acres }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'single-field',
              params: { fieldId: field.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6 print:hidden"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import DropdownButton from "@/components/buttons/DropdownButton.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import CropsForm from "@/layouts/grower-portal/assets/fields/CropsForm.vue";
import {
  exportToCSV,
  exportToGeoJSON,
  exportToShapefile,
} from "@/components/composables/exportFile";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    FieldThumbnail,
    TableToolbarButton,
    DropdownButton,
    SlotModal,
    CropsForm,
    DeleteModal,
  },
  data() {
    // let filterObject = this.$store.getters.getFieldsFilter;
    return {
      // defaultFilter: filterObject,
      defaultSort: { "properties.farm": "asc", "properties.name": "asc" },
      searchValue: "",
      derivedFields: [],
      selectedFields: [],
      deleteModalIsActive: false,
      showEditForm: false,
    };
  },
  computed: {
    fields() {
      return this.$store.state.fields.fields;
    },
    defaultFilter() {
      // console.log("computing filter");
      return this.$store.getters.getFieldsFilter;
    },
  },
  methods: {
    bulkEdit(e) {
      // console.log("bulk edit", e);
      if (e === "delete") {
        this.deleteModalIsActive = true;
      } else if (e === "edit_crops") {
        console.log("editing crops", this.selectedFields);
        this.showEditForm = true;
      }
    },
    async deleteSelectedFields() {
      console.log("deleting", this.selectedFields);
      this.selectedFields.forEach((field) => {
        this.$store.dispatch("deleteField", field);
      });
      this.selectedFields = [];
      this.deleteModalIsActive = false;
      await this.$store.dispatch("getFields");
    },
    selectFilter(e) {
      // console.log("saving Filter", e);
      this.$store.dispatch("setFieldsFilter", e);
    },
    goToNewFieldPage() {
      this.$router.push({ name: "new-field" });
    },
    goToImportFieldsPage() {
      this.$router.push({ name: "import-fields" });
    },
    exportTable(e) {
      let exportData = this.derivedFields.map((f) => {
        let exportField = {
          farm: f.properties?.farm,
          field: f.properties?.name,
          acres: f.properties?.acres,
          crop: f.properties?.crop,
        };
        exportField = { ...exportField, ...f.properties?.details };
        return exportField;
      });

      if (e === "csv") {
        exportToCSV(exportData, "fields.csv");
      } else if (e === "geojson") {
        exportToGeoJSON(this.derivedFields, "fields.geojson");
      } else if (e === "578") {
        this.$router.push({ name: "578-report" });
      } else if (e === "shapefile") {
        // console.log("shapefile");
        exportToShapefile(this.derivedFields, "fields.zip");
      }

      // exportToCSV(exportData, "fields.csv");
    },
  },
};
</script>
