<template>
  <div class="sm:col-span-3">
    <div class="mt-2 sm:mt-2">
      <div class="-m-1 flex flex-wrap items-center">
        <span
          v-for="(item, key) in pillArray"
          :key="key"
          class="m-1 inline-flex items-center rounded-full py-1.5 pl-1 text-sm font-medium text-gray-900"
        >
          <pill-button :buttonText="item" :pillColorIndex="key + index" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PillButton from "@/components/buttons/PillButton.vue";
function cloneArray(arr) {
  return arr.reduce(function (agg, val) {
    return agg.concat(val);
  }, []);
}

export default {
  props: {
    pills: {
      type: Array,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PillButton,
  },
  data() {
    return {
      pillArray: this.pills ? cloneArray(this.pills) : [],
      // pill: "",
    };
  },

  methods: {
    removepill(id) {
      this.pillArray.splice(id, 1);
      this.$emit("updatepillArray", cloneArray(this.pillArray));
    },
  },
  watch: {
    pills(pills) {
      this.pillArray = pills.reduce(function (agg, val) {
        return agg.concat(val);
      }, []);
    },
  },
};
</script>
