<template>
  <div>
    <div class="flex">
      <!-- Map Display here -->
      <div id="mapid" style="height: 60vh; width: 75vw" class="z-0"></div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@/components/map/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import * as turf from "@turf/turf";
import { exceptionHandler } from "@/api/parcel/api";

export default {
  props: {
    territoryId: { type: Number, default: null },
  },
  data: function () {
    return {
      message: "initial",
      loading: false,
      access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      // map: {},
      draw: {},
      area: "",
      distance: "",
      selectedTerritory: null,
      territory: this.$store.getters.getVendorTerritoryById(
        this.territoryId,
      ) || {
        harvest_year: null, // Num
        crop_type: null, // string
        crop_details: {
          variety: null, // string
        },
        total_available: null, // Num
        qty_units: null, // String
        asking_price: null, // Num
        min_purchase: null, // Num
        description: null, // String
        title: "",
      },
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      try {
        let componentHandle = this;
        // Create the map

        const map = new L.map("mapid");
        //this.map = map  disabling due to a bug in vue3 / leaflet

        // Initialise the FeatureGroup to store editable layers
        var editableLayers = new L.FeatureGroup();

        map.addLayer(editableLayers);

        // add the satellite base layer
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            minZoom: 1,
            id: "airrer1/ckqqvow3854p218l3d6lo43s5",
            tileSize: 512,
            zoomOffset: -1,
            accessToken: this.access_token,
          },
        ).addTo(map);

        // add the territory to the map
        if (this.territory?.boundary) {
          var territoryStyle = {
            opacity: 1,
            weight: 1,
            color: "blue",
          };
          const { id, boundary: geometry, ...properties } = this.territory;
          let territoryGeoJSON = {
            type: "Feature",
            id,
            properties,
            geometry,
          };

          let centroid = L.geoJson(territoryGeoJSON).getBounds().getCenter();
          // console.log(centroid);
          map.setView(centroid, 20);
          map.fitBounds(L.geoJson(territoryGeoJSON).getBounds());

          let territoryLayer = L.geoJSON(territoryGeoJSON, {
            onEachFeature: function (_, layer) {
              editableLayers.addLayer(layer);
            },
            style: territoryStyle,
          }).addTo(map);

          // Display territory name on hover (tap on mobile)
          territoryLayer.bindTooltip(function (layer) {
            return layer.feature.properties.name;
          });

          // Add editing features to the map

          // Initialise the draw control and pass it the FeatureGroup of editable layers
          map.pm.addControls({
            position: "topleft",
            drawPolygon: true,
            drawMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawCircleMarker: false,
            drawCircle: false,
            drawText: false,
            rotateMode: false,
            dragMode: false,
          });

          // Track events that occur when editing.
          territoryLayer.on("pm:edit", (e) => {
            // console.log("edited a territory layer", e);
            var layers = L.PM.Utils.findLayers(map);
            var group = L.featureGroup();
            layers.forEach((layer) => {
              group.addLayer(layer);
            });
            let shape = group.toGeoJSON();
            e.layer.feature.geometry = shape.features[0].geometry; // override original feature with new geometry
            this.$emit("edited", e.layer.feature);
          });
        } else {
          // console.log("adding a new territory");
          // new territory
          map.pm.addControls({
            position: "topleft",
            drawMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawCircleMarker: false,
            drawCircle: false,
            drawText: false,
            editMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
            dragMode: false,
          });
          map.setView([36.897433, -121.736681], 10);
        }
        // Set Toolbar tooltips - can also set tooltips for drawing if needed
        const customTranslation = {
          tooltips: {},
          buttonTitles: {
            drawPolyButton: "Draw the Territory",
            editButton: "Edit Territory",
            cutButton: "Add Excluded Area in Territory",
            deleteButton: "Remove a Territory",
          },
        };

        map.pm.setLang("custom_en", customTranslation, "en");
        map.pm.setGlobalOptions({ snappable: false });
        // Track adding new territory.
        map.on("pm:create", function (e) {
          // console.log("drew a territory");
          //var type = e.shape;
          var layer = e.layer;
          // console.log(type);
          // console.log(layer);
          editableLayers.addLayer(layer);
          //TODO: Save new shape
          //var layers = L.PM.Utils.findLayers(map);
          //var group = L.featureGroup();
          //.forEach((layer) => {
          //  group.addLayer(layer);
          //});
          //let shape = group.toGeoJSON();
          let shape = editableLayers.toGeoJSON();
          // console.log(shape);
          if (shape.features.length > 1) {
            // console.log("more than one feature, converting to multipolygon");
            let multiPolygon = turf.multiPolygon(
              shape.features.map((feature) => feature.geometry.coordinates),
            );
            multiPolygon.geometry.type = "MultiPolygon";
            componentHandle.$emit("edited", multiPolygon);
          } else {
            componentHandle.$emit("edited", shape.features[0]);
          }
        });
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        console.log("map error", err);
      }
    },
  },
  computed: {},
};
</script>
