<template>
  <li
    class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
  >
    <router-link
      :to="{
        name: 'grower-portal-market-offer-usda-eqip',
      }"
      class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
    >
      <div class="flex flex-1 flex-col p-8">
        <h3 class="text-xl font-bold text-gray-900">NRCS EQIP</h3>
        <img
          class="mx-auto mt-2 h-16 w-16 flex-shrink-0 rounded-sm"
          src="/img/icons/usda.png"
          alt=""
        />
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          Get your USDA EQIP application started today!
        </h3>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          $60-80 per acre or more in cost-share assistance
        </h3>
        <div class="min-h-6"></div>
        <!-- <h3
          class="mt-2 text-sm font-bold text-green-500"
        >
          You could earn:
          {{
            offer.potentialValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }}
        </h3> -->

        <!-- pill format-->
        <dl class="mt-1 flex flex-grow flex-col justify-between">
          <dd class="mt-3">
            <span
              class="inline-flex items-center px-2 text-xs font-medium text-black"
            >
              <ClockIcon class="h-4 w-4 min-w-6 pr-2 text-parcelOrange-600" />
              Enrollment time varies
            </span>
          </dd>
        </dl>
      </div>
    </router-link>
  </li>
</template>
<script>
import { ClockIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ClockIcon,
  },
  props: {},
  data() {
    return {
      matchScore: 1,
    };
  },
};
</script>
