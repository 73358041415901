<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Territory"
    modalDescription="Are you sure you want to permanently delete this territory?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="del"
  />
  <page-card
    :headingTitle="this.routeParam ? currentTerritory?.name : 'New Territory'"
  >
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="submit"
      />
      <base-button
        v-if="this.routeParam"
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Territory Name"
        v-model="currentTerritory.name"
      />
    </template>
    <template #componentSection>
      <territory-leaflet-map
        :territoryId="currentTerritory.id"
        @edited="updateShape"
      ></territory-leaflet-map>
    </template>
  </page-card>
</template>

<script>
// import { reactive } from "vue";
// import { useRoute } from "vue-router";
// import { useStore } from "vuex";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TerritoryLeafletMap from "./TerritoryLeafletMap.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    TerritoryLeafletMap,
    DeleteModal,
  },
  data() {
    return {
      modalIsActive: false,
      currentTerritory: {
        harvest_year: null, // Num
        crop_type: null, // string
        crop_details: {
          variety: null, // string
        },
        total_available: null, // Num
        qty_units: null, // String
        asking_price: null, // Num
        min_purchase: null, // Num
        description: null, // String
        title: "",
      },
    };
  },
  computed: {
    existingTerritory() {
      if (!this.$route.params.id) return null;
      return this.$store.getters.getVendorTerritoryById(this.$route.params.id);
    },
  },
  methods: {
    submit() {
      this.$store.dispatch(
        this.routeParam ? "updateVendorTerritory" : "createVendorTerritory",
        this.currentTerritory,
      );
      this.$router.push({ name: "vendor-revenue-territories" });
    },
    del() {
      this.modalIsActive = false;

      this.$store.dispatch("deleteVendorTerritory", this.currentTerritory);
      this.$router.push({ name: "vendor-revenue-territories" });
    },
    updateShape(feature) {
      // console.log(feature);
      this.currentTerritory.boundary = feature.geometry;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
  },
  async mounted() {
    // console.log(JSON.stringify(this.currentTerritory));
  },
  watch: {
    existingTerritory: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.currentTerritory = newVal;
        }
      },
    },
  },
};
</script>
