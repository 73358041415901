<template>
  <slot-modal
    :open="modalIsActive"
    :modalTitle="'View Record'"
    @closeModal="closeRecordModal"
  >
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <div
            class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
          >
            Record Details
          </div>
          <record-form-layout
            :recordProp="viewRecord"
            :recordType="viewRecord.category"
            :displayOnly="true"
          />
        </div>
      </div>
    </template>
  </slot-modal>
  <page-card
    :headingTitle="'Order' + $route.params.orderId"
    :headingDescription="'Order # ' + $route.params.orderId"
  >
    <template #buttonArea>
      <base-button buttonText="Back to Order" @buttonClick="navigateToOrder" />
    </template>
    <template #cardDetails>
      <select-dropdown
        inputTitle="Premium"
        inputHtmlType="text"
        :wideItem="true"
        v-model="currentPremiumId"
        :selectOptions="premiumOptions"
      />
      <select-dropdown
        inputTitle="Validation Record"
        inputHtmlType="text"
        :wideItem="true"
        v-model="currentValidationRecordId"
        :selectOptions="currentPremiumValidationRecordOptions"
      />

      <div class="sm:col-span-6">
        <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
          <div class="border-b border-gray-200">
            <TabList class="-mb-px flex space-x-8">
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Validate Fields
                </button>
              </Tab>
              <Tab
                as="template"
                v-slot="{ selected }"
                v-if="
                  dataIsLoaded &&
                  crops &&
                  currentPremiumValidationRecordOptions.length > 0
                "
              >
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Add Records
                </button>
              </Tab>
            </TabList>
          </div>
          <TabPanels as="template">
            <TabPanel as="dl" class="pt-10">
              <!-- <div
                class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
              >
                Field Validation
              </div> -->
              <div class="my-2 sm:col-span-6" v-if="currentAllocation">
                <div
                  class="text-md my-2 text-left font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
                >
                  Premium Requirements
                </div>
                <p
                  v-for="req in interpretPremium(currentPremium, true)"
                  :key="req"
                  class="text-sm leading-6 text-gray-900"
                >
                  {{ req }}
                </p>
                <!-- <div
                  class="text-md my-2 text-left font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
                >
                  Field:
                  <span class="text-bold">
                    {{ " " + currentAllocation.field_data.properties.name }}
                  </span>
                </div> -->
              </div>
              <div class="sm:col-span-6">
                <checkbox-input
                  inputTitle="Display Fields"
                  v-model="showFieldSelect"
                />
              </div>
              <div class="sm:col-span-6">
                <span class="pt-4 text-sm text-gray-500">
                  If all necessary records have been entered for a given field,
                  select the Validate slider to approve that field.
                  <p>
                    You can view the number of records by category for each
                    field and year in the table below. Click on any count to add
                    records for that category.
                  </p>
                </span>
                <table-card
                  v-if="dataIsLoaded && allocations && showFieldSelect"
                >
                  <template #header>
                    <table-head
                      :columns="[
                        {
                          name: 'field_data.properties.name',
                          label: 'Field',
                          filtered: false,
                          sorted: true,
                        },
                        {
                          name: 'currentOrderValidations',
                          label: 'Premiums Validated',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'validatedAgainstSelectedPremium',
                          label: 'Validate Against Selected Premium',
                          filtered: false,
                          sorted: false,
                        },
                        // {
                        //   name: 'actions',
                        //   label: 'View Field',
                        //   filtered: false,
                        //   sorted: false,
                        // },
                      ]"
                      :collection="allocations"
                      @update:derived-collection="derivedAllocations = $event"
                      :default-sort="{
                        'field_data.properties.name': 'asc',
                      }"
                    >
                    </table-head>
                  </template>

                  <tbody>
                    <tr v-for="allo in derivedAllocations" :key="allo.id">
                      <table-data td-label="Type">
                        {{ allo.field_data?.properties?.name }}
                      </table-data>
                      <table-data td-label="Validations">
                        {{
                          allo.currentOrderValidations?.length +
                          " of " +
                          premiumOptions?.length +
                          " confirmed"
                        }}
                      </table-data>
                      <table-data
                        class="text-center"
                        td-label="Field Validated"
                      >
                        <toggle-button
                          :enabled="allo.validatedAgainstSelectedPremium"
                          @toggle="
                            toggleAllocationValidationAgainstPremium(allo.id)
                          "
                        />
                      </table-data>
                      <!-- <table-data
                        class="text-center"
                        td-label="View Allocation"
                      >
                        <button @click="setCurrentAllocationId(allo.id)">
                          Select
                        </button>
                      </table-data> -->
                    </tr>
                  </tbody>
                </table-card>
              </div>

              <!-- <div class="sm:col-span-6">
                <table-card v-if="dataIsLoaded && currentAllocation">
                  <template #header>
                    <table-head
                      :columns="[
                        {
                          name: 'crop_harvest_year',
                          label: 'Crop Year',
                        },
                        {
                          name: 'category',
                          label: 'Record Type',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'result',
                          label: 'Result',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'completed_at',
                          label: 'Date',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'delete',
                          label: '',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'view',
                          label: '',
                          filtered: false,
                          sorted: false,
                        },
                      ]"
                      :collection="currentAllocationMatchingTypeRecords"
                    >
                    </table-head>
                  </template>

                  <tbody>
                    <tr
                      v-for="record in currentAllocationMatchingTypeRecords"
                      :key="record.rowid"
                    >
                      <table-data td-label="Crop Year">
                        {{ record.crop_harvest_year }}
                      </table-data>
                      <table-data td-label="Type">
                        {{ record.category }}
                      </table-data>
                      <table-data td-label="Result">
                        {{ record.result }}
                      </table-data>
                      <table-data td-label="Datetime">
                        {{ formatDisplayDate(record.completed_at) }}
                      </table-data>
                      <table-data class="text-center">
                        <button @click="deleteRecord(record)" :disabled="false">
                          Delete
                        </button>
                      </table-data>
                      <table-data class="text-center">
                        <button
                          @click="setViewRecord(record)"
                          :disabled="false"
                        >
                          View
                        </button>
                      </table-data>
                    </tr>
                  </tbody>
                </table-card>
              </div> -->
              <field-validation-table
                @select-records="addRecords"
                v-if="currentPremiumValidationRecordOptions.length > 0"
              />
            </TabPanel>
            <!-- <TabPanel class="pt-10">
            <p>Finalize</p>
          </TabPanel> -->
            <TabPanel
              as="dl"
              class="pt-10"
              v-if="
                dataIsLoaded &&
                crops &&
                currentPremiumValidationRecordOptions.length > 0
              "
            >
              <!-- <TabPanel as="dl" class="pt-10" :unmount="false"> -->
              <div
                class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
              >
                Record Details
              </div>
              <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                <record-form-layout
                  :recordProp="workingRecord"
                  :recordType="
                    currentValidationRecord
                      ? currentValidationRecord.category
                      : 'Note'
                  "
                  @update:record="workingRecord = $event"
                />
              </div>
              <div
                class="text-md my-4 text-left font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
              >
                Selected Premium Requirements
              </div>
              <p
                v-for="req in prettyPremium(currentPremium, true)"
                :key="req"
                class="text-sm leading-6 text-gray-900"
              >
                {{ req }}
              </p>
              <div class="mt-2 sm:col-span-6">
                <div
                  class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
                >
                  Crop Years
                </div>
                <!-- <stats-row :stats="allocationData.stats" /> -->
                <table-card>
                  <template #header>
                    <table-head
                      :columns="[
                        { name: 'field_data.properties.farm', label: 'Farm' },
                        {
                          name: 'field_data.properties.name',
                          label: 'Field',
                        },
                        {
                          name: 'harvest_year',
                          label: 'Crop Year',
                        },
                        {
                          name: 'crop_type',
                          label: 'Crop',
                        },
                        {
                          name: 'validated',
                          label: 'Field Validated',
                        },
                        {
                          name: 'actions',
                          label: 'Add Record',
                          filtered: false,
                          sorted: false,
                        },
                        {
                          name: 'actions',
                          label: 'Undo',
                          filtered: false,
                          sorted: false,
                        },
                      ]"
                      :collection="crops"
                      @update:derived-collection="derivedCrops = $event"
                      selectionMode="Multiple"
                      @update:selectedCollection="selectedCrops = $event"
                      :toolbar="1"
                    >
                      <template #toolbarButtons>
                        <div class="flex">
                          <table-toolbar-button
                            buttonText="Add Record to Selected Crops"
                            :usePlusIcon="true"
                            :disabled="selectedCrops.length == 0"
                            @buttonClick="bulkAddRecords"
                          />
                        </div>
                      </template>
                    </table-head>
                  </template>
                  <tbody>
                    <tr v-for="crop in derivedCrops" :key="crop.id">
                      <table-data td-label="Selected">
                        <input
                          type="checkbox"
                          v-model="crop.selected"
                          class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                          @change="
                            selectedCrops = derivedCrops.filter(
                              (crop) => crop.selected,
                            )
                          "
                        />
                      </table-data>
                      <table-data td-label="Farm">
                        {{ crop?.field_data?.properties?.farm }}
                      </table-data>
                      <table-data td-label="Field">
                        {{ crop?.field_data?.properties?.name }}
                      </table-data>
                      <table-data td-label="Crop Year">
                        {{ crop.harvest_year }}
                      </table-data>
                      <table-data td-label="Crop">
                        {{ crop.crop_type }}
                      </table-data>
                      <table-data td-label="Validated">
                        <toggle-button
                          :enabled="crop.validated"
                          @toggle="
                            toggleAllocationValidationAgainstPremium(
                              crop.relatedAllocationId,
                            )
                          "
                        />
                      </table-data>
                      <table-data td-label="Add Record">
                        <button
                          @click="addRecord(crop)"
                          class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-green-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                        >
                          <OutlineHeroIcon
                            name="PlusIcon"
                            classProp="w-5 h-5 text-gray-700"
                            aria-hidden="true"
                          />
                        </button>
                      </table-data>
                      <!-- Undo button -->
                      <table-data td-label="Undo">
                        <button
                          v-if="recentlyAddedRecords[crop.id]"
                          @click="undoRecord(crop)"
                          class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-red-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                        >
                          <OutlineHeroIcon
                            name="RewindIcon"
                            classProp="w-5 h-5 text-gray-700"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          v-else
                          class="ml-1 rounded-lg border border-gray-300 bg-gray-300 p-1 text-left shadow-sm sm:text-sm"
                          disabled
                        >
                          <OutlineHeroIcon
                            name="RewindIcon"
                            classProp="w-5 h-5 text-gray-700"
                            aria-hidden="true"
                          />
                        </button>
                      </table-data>
                    </tr>
                  </tbody>
                </table-card>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </template>
    <!-- <template #componentSection>
      <p>component section</p>
    </template> -->
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import StatsRow from "@/components/cards/StatsRow.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import ToggleButton from "@/components/buttons/ToggleButton.vue";
import FieldValidationTable from "@/pages/grower-portal/sell/produce/orders/FieldValidationTable.vue";
import { useToast } from "vue-toastification";
import {
  getDisplayYear,
  getShortDisplayDate,
  getLocalTimeFromUTC,
  // getUTCFromLocalTime,
} from "@/components/composables/dateUtils.js";
import { postgresDate } from "@/components/composables/dateUtils.js";
import { interpretPremium } from "@/components/composables/growerPremiumUtils.js";

export default {
  components: {
    PageCard,
    BaseButton,
    CheckboxInput,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // StatsRow,
    SlotModal,
    ToggleButton,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    OutlineHeroIcon,
    SelectDropdown,
    RecordFormLayout,
    FieldValidationTable,
  },
  data() {
    return {
      toast: useToast(),
      selectedTab: parseInt(this.$route.query.tab) || 0,
      currentPremiumId: parseInt(this.$route.query.premiumId) || 0,
      currentAllocationId: parseInt(this.$route.query.allocationId) || null,
      currentValidationRecordId: null,
      derivedCrops: [],
      derivedAllocations: [],
      selectedCrops: [],
      workingRecord: {
        completed_at: null,
        result: null,
        payload: {},
      },
      // state for tracking recently added records
      recentlyAddedRecords: {},
      // View Record
      modalIsActive: false,
      viewRecord: null,
      // Validate fields
      showFieldSelect: true,
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // CORE DATA
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      if (!this.currentOffer?.premiums) {
        return allocations;
      }
      return allocations.map((allo) => {
        return {
          ...allo,
          field_data: this.$store.getters.getFieldByCropId(allo.crop),
          // currentOrderValidations equals allo.validations_met filtered down to validation ids that are in the currentOffer.premiums
          currentOrderValidations: allo.validations_met.filter((val) =>
            this.currentOffer.premiums.map((prem) => prem.id).includes(val),
          ),
          validatedAgainstSelectedPremium: allo.validations_met.includes(
            this.currentPremiumId,
          ),
        };
      });
    },
    crops() {
      if (!this.dataIsLoaded || !this.allocations || !this.currentOffer) {
        return null;
      }
      let fieldIds = [];
      // loop through allocations, use the getFieldIdByCropId getter to get the field id, and push it to fieldIds
      for (let allo of this.allocations) {
        fieldIds.push(this.$store.getters.getFieldIdByCropId(allo.crop));
      }
      // loop through fieldIds, use the getCropsByFieldId getter to get the crops
      // the arguments for the getter are the field id, the current year - this.currentOffer.additional_prior_year_records, and the current year
      let crops = [];
      for (let fieldId of fieldIds) {
        // get the crops for the fieldId, give each crop field_data via the getFieldByCropId getter, and push them to the crops array as a single array
        for (let crop of this.$store.getters.getCropsByFieldId(fieldId)) {
          crops.push({
            ...crop,
            harvest_year: new Date(crop.harvest_range_end).getFullYear(),
            field_data: this.$store.getters.getFieldById(fieldId),
            records: this.$store.getters.getRecordsByCrop(crop.id),
            relatedAllocationId: this.allocations.find(
              (allo) => allo.field_data.id == crop.field,
            )?.id,
            // set validated to true if the related allocation.validations_met includes the currentPremiumId
            validated: this.allocations
              .find((allo) => allo.field_data.id == crop.field)
              ?.validations_met.includes(this.currentPremiumId),
          });
        }
      }
      return crops;
    },
    allocationCrops() {
      if (!this.dataIsLoaded || !this.allocations || !this.crops) {
        return null;
      }
      // only return the crops that have a relatedAllocationId equal to the currentAllocationId
      return this.crops.filter(
        (crop) => crop.relatedAllocationId == this.currentAllocationId,
      );
    },
    //
    // Premiums
    //
    premiumOptions() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return [];
      }
      return this.currentOffer.premiums.map((premium) => ({
        // Put in better premium names
        label: premium.type,
        value: premium.id,
      }));
    },
    currentPremium() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return null;
      }
      // return the premium from this.currentOffer.premiums that matches the currentPremiumId
      let premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentPremiumId,
      );
      return premium;
    },
    //
    // Validation Records
    //
    currentPremiumValidationRecordOptions() {
      if (!this.dataIsLoaded || !this.currentPremium) {
        return [];
      }
      // map through and return label and value
      return this.currentPremium.validation_records.map((record) => ({
        label: record.category,
        value: record.id,
      }));
    },
    currentValidationRecord() {
      if (!this.dataIsLoaded || !this.currentPremium) {
        return null;
      }
      // return the validation record from this.currentPremium.validation_records that matches the currentValidationRecordId
      return this.currentPremium.validation_records.find(
        (record) => record.id == this.currentValidationRecordId,
      );
    },
    //
    // Allocations
    //
    allocationOptions() {
      if (!this.dataIsLoaded || !this.allocations) {
        return [];
      }
      // for allo in allos, create the label and value where label is the allo.field_data.properties.name name and value is the allocation.id
      return this.allocations.map((allo) => ({
        label: allo?.field_data?.properties?.name,
        value: allo?.id,
      }));
    },
    currentAllocation() {
      if (!this.dataIsLoaded || !this.allocations) {
        return null;
      }
      // return the allocation from this.allocations that matches the currentAllocationId
      return this.allocations.find(
        (allo) => allo.id == this.currentAllocationId,
      );
    },
    currentAllocationMatchingTypeRecords() {
      if (
        !this.dataIsLoaded ||
        !this.currentAllocation ||
        !this.currentValidationRecord ||
        !this.crops
      ) {
        return [];
      }
      let records = [];
      // for all the crops in allocationCrops, push the records with the same category as the currentValidationRecord.category
      for (let crop of this.allocationCrops) {
        // attach the crop harvest_year into the record body as crop_harvest_year and push it to the records array if the record category matches the currentValidationRecord.category
        records.push(
          ...crop.records
            .filter(
              (record) =>
                record.category == this.currentValidationRecord.category,
            )
            .map((record) => ({
              ...record,
              crop_harvest_year: crop.harvest_year,
            })),
        );
      }
      return records;
    },
  },
  methods: {
    addRecords(category) {
      this.currentValidationRecordId =
        this.currentPremium.validation_records.find(
          (record) => record.category == category,
        ).id;
      this.selectedTab = 1;
    },
    navigateToOrder() {
      this.$router.push({
        name: "grower-portal-offer-order",
        params: { orderId: this.orderId },
      });
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    formatDisplayYear(date) {
      return getDisplayYear(getLocalTimeFromUTC(date));
    },
    formatDisplayDate(date) {
      if (!date) {
        return "N/A";
      }
      return getShortDisplayDate(getLocalTimeFromUTC(date));
    },
    setCurrentAllocationId(allocationId) {
      this.currentAllocationId = allocationId;
    },
    async addRecord(crop) {
      // check if this.workingRecord has a completed_at date
      console.log("working record", this.workingRecord);
      if (this.workingRecord?.payload?.completed_at) {
        this.workingRecord.completed_at = postgresDate(
          this.workingRecord.payload.completed_at,
        );
        this.workingRecord.payload.completed_at = postgresDate(
          this.workingRecord.payload.completed_at,
        );
      }
      // set completed_by if it is not in payload
      if (!this.workingRecord?.payload?.completed_by) {
        this.workingRecord.completed_by = this.$store.getters.getCurrentUser.id;
      }
      // if (!this.workingRecord.completed_at) {
      //   this.toast.error("Please enter a completion date for your record");
      //   return;
      // }
      const response = await this.$store.dispatch(
        "createRecord",
        {
          ...this.workingRecord,
          category: this.currentValidationRecord.category,
          crop: crop.id,
        },
        true,
      );
      if (response && response.id) {
        // Track the recently added record ID for this crop
        this.recentlyAddedRecords[crop.id] = response.id; // Track the recently added record ID for this crop
        this.toast.success(
          `Record created for ${crop?.field_data?.properties?.name}`,
        );
      }
    },
    async undoRecord(crop) {
      const recordId = this.recentlyAddedRecords[crop.id];
      if (!recordId) return;

      await this.$store.dispatch("deleteRecord", { id: recordId });
      this.toast.success(
        `Record deleted for ${crop?.field_data?.properties?.name}`,
      );
      delete this.recentlyAddedRecords[crop.id]; // Remove the record from tracking
    },
    bulkAddRecords() {
      for (let crop of this.selectedCrops) {
        this.addRecord(crop);
      }
      this.toast.success(`${this.selectedCrops.length} records created`);
    },
    setViewRecord(record) {
      this.viewRecord = record;
      this.modalIsActive = true;
    },
    closeRecordModal() {
      this.modalIsActive = false;
    },
    async deleteRecord(record) {
      await this.$store.dispatch("deleteRecord", record, true);
      this.toast.success("Record deleted");
    },
    setDefaultPremium() {
      if (this.premiumOptions.length > 0 && !this.currentPremiumId) {
        this.currentPremiumId = this.premiumOptions[0].value;
      }
    },
    setDefaultAllocation() {
      if (this.allocationOptions.length > 0) {
        this.currentAllocationId = this.allocationOptions[0].value;
      }
    },
    setDefaultValidationRecord() {
      if (this.currentPremiumValidationRecordOptions.length > 0) {
        this.currentValidationRecordId =
          this.currentPremiumValidationRecordOptions[0].value;
      }
    },
    toggleAllocationValidationAgainstPremium(alloId) {
      if (!this.currentAllocation || !this.currentPremium) {
        return;
      }

      // Fetch the full allocation object from the store
      let allo = this.$store.getters.getGrowerAllocationById(alloId);
      // Clone the validations_met array to avoid mutating the original
      let allo_validations = [...allo.validations_met];

      // Check if the currentPremium.id is already in the validations_met array
      const premiumIndex = allo_validations.indexOf(this.currentPremium.id);

      if (premiumIndex !== -1) {
        // If the currentPremium.id exists in the array, remove it
        allo_validations.splice(premiumIndex, 1);
      } else {
        // If the currentPremium.id doesn't exist in the array, add it
        allo_validations.push(this.currentPremium.id);
      }

      // Dispatch the updated allocation with the modified validations_met array
      this.$store.dispatch("updateGrowerAllocation", {
        ...allo, // Spread the entire allocation object
        validations_met: allo_validations, // Replace the validations_met array
      });
    },
    getAllocationValidatedAgainstCurrentPremium(alloId) {
      if (!this.currentAllocation || !this.currentPremium) {
        return false;
      }
      return this.currentAllocation.validations_met.includes(alloId);
    },
    prettyPremium(premium, includeRequirements = false) {
      let premiumSorted = premium.validation_records.sort((a, b) =>
        a.category > b.category ? 1 : -1,
      );
      let prettyPremium = this.interpretPremium(
        premiumSorted,
        includeRequirements,
      );
      console.log("prettyPremium", premium, prettyPremium);
      return prettyPremium;
    },
    interpretPremium,
  },
  watch: {
    currentPremiumId(newVal) {
      this.$router.push({
        query: {
          premiumId: newVal,
        },
      });
    },
    selectedTab(newVal) {
      this.$router.push({
        // if there is a premiumId or allocationId, keep it
        query: {
          tab: newVal,
          premiumId: this.currentPremiumId,
        },
      });
      if (!this.currentAllocationId) {
        this.setDefaultAllocation();
      }
    },
    premiumOptions: {
      handler(newVal, oldVal) {
        if (newVal.length != oldVal.length) {
          this.setDefaultPremium();
        }
      },
    },
    allocationOptions: {
      handler(newVal, oldVal) {
        if (newVal.length != oldVal.length || !this.currentAllocationId) {
          this.setDefaultAllocation();
        }
      },
    },
    // run immediately
    currentPremiumValidationRecordOptions: {
      handler() {
        this.setDefaultValidationRecord();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    orderId: {
      handler() {
        this.setDefaultPremium();
        this.setDefaultAllocation();
        this.setDefaultValidationRecord();
      },
      immediate: true,
    },
  },
};
</script>
