<template>
  <page-card headingTitle="Organization Settings" v-if="userIsAdmin">
    <template #buttonArea>
      <div class="flex">
        <file-select
          class="h-full"
          buttonText="Edit Logo"
          accept="image/*"
          @input="uploadOrgLogo"
        />
        <base-button
          buttonText="Save"
          :marginLeft="true"
          @buttonClick="submitOrg"
        />
      </div>
    </template>
    <template #cardDetails>
      <div class="sm:col-span-1">
        <img
          v-if="!currentOrg.logo_url"
          src="@/assets/no_logo_default.jpeg"
          class="h-36 w-36"
        />

        <img v-else :src="currentOrg.logo_url" class="h-36 w-36" />
      </div>
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Org Name"
        v-model="currentOrg.name"
      />
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Address"
        v-model="currentOrg.addressObj.street"
      />
      <div class="col-span-3"></div>
      <variable-string-type-input
        :wideItem="false"
        inputTitle="City"
        v-model="currentOrg.addressObj.city"
      />
      <SelectSearch
        :wideItem="false"
        inputTitle="State"
        v-model="currentOrg.addressObj.state"
        :select-options="stateList"
      />
      <variable-string-type-input
        :wideItem="false"
        inputTitle="Zip Code"
        v-model="currentOrg.addressObj.zip"
      />
      <SelectSearch
        :wideItem="false"
        inputTitle="County"
        v-model="currentOrg.addressObj.county"
        :select-options="countyList"
      />
      <email-input
        :wideItem="true"
        inputTitle="Contact Email"
        v-model="currentOrg.contact_email"
        inputHtmlType="email"
      />
      <phone-input-2
        :wideItem="true"
        inputTitle="Contact Phone"
        v-model="currentOrg.contact_phone"
        inputHtmlType="tel"
      />
      <!-- <variable-string-type-input v-if="currentUser.admin == true" :wideItem="true" inputTitle="Organization" v-model="orgName" />
      <variable-string-type-input v-else :wideItem="true" inputTitle="Organization" :displayOnly="true" :displayOnlyValue="currentUser.company" /> -->
    </template>
  </page-card>
  <div class="mt-4"></div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import PhoneInput2 from "@/components/form/PhoneInput2.vue";
import FileSelect from "@/components/form/FileSelect.vue";

// import TableCard from "@/components/table/TableCard.vue";
// import TableHead from "@/components/table/TableHead.vue";
// import TableData from "@/components/table/TableData.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

// import Avatar from "@/components/identity/UserAvatar.vue";
import { useToast } from "vue-toastification";

import SelectSearch from "@/components/form/SelectSearch.vue";

const stateList = require("@/assets/statelist.js");
const stateCountyList = require("@/assets/state_county.js");
const stateAbbreviations = require("@/assets/state_abbreviations.js");

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    EmailInput,
    PhoneInput2,
    // TableCard,
    // TableHead,
    // TableData,
    // TableToolbarButton,
    // Avatar,
    FileSelect,
    SelectSearch,
  },
  props: {
    routeUserNew: {
      type: String,
      required: true,
    },
    routeExternalOrgNew: {
      type: String,
      required: true,
    },
    routeUserEdit: {
      type: String,
      required: true,
    },
  },
  data() {
    // console.log("stateList", stateList);
    return {
      derivedUsers: [],
      derivedExternalOrgs: [],
      derivedTokens: [],
      toast: useToast(),
      currentOrg: null,
      stateList: stateList,
      stateCountyList: stateCountyList,
      stateAbbreviations: stateAbbreviations,
    };
  },
  computed: {
    currentOrgId() {
      return this.$store.getters.getCurrentOrg?.id;
    },
    users() {
      return this.$store.state.users.users;
    },
    externalOrgs() {
      return this.$store.state.externalOrgs.externalOrgs;
    },
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
    countyList() {
      if (!this.currentOrg?.addressObj?.state) {
        return [];
      }
      // console.log("stateCountyList", this.stateCountyList);
      let stateAbbrev =
        this.stateAbbreviations[this.currentOrg.addressObj.state];

      return this.stateCountyList[stateAbbrev];
    },
  },
  methods: {
    async submitOrg() {
      let submitOrg = {
        name: this.currentOrg.name,
        contact_phone: this.currentOrg.contact_phone,
        contact_email: this.currentOrg.contact_email,
        // image: this.currentOrg.image,
        address: JSON.stringify(this.currentOrg.addressObj),
        feature_flags: this.currentOrg.feature_flags,
      };
      // console.log("SAVE ORG", submitOrg);
      await this.$store.dispatch("updateOrg", submitOrg);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Settings Saved!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },
    goToNewUserPage() {
      this.$router.push({ name: this.routeUserNew });
      // Form Methods
    },
    goToNewExternalOrgPage() {
      this.$router.push({ name: this.routeExternalOrgNew });
      // Form Methods
    },
    goToNewTokenPage() {
      this.$router.push({ name: this.routeTokenNew });
      // Form Methods
    },
    readOrgById(orgId) {
      this.currentOrg = this.$store.getters.getOrgById(orgId);
    },
    readCurrentOrg() {
      this.currentOrg = this.$store.getters.getCurrentOrg;
      if (this.currentOrg?.address) {
        this.currentOrg.addressObj = JSON.parse(this.currentOrg.address);
      } else {
        if (!this.currentOrg) this.currentOrg = {};
        this.currentOrg.addressObj = {
          house_number: "",
          road: "",
          city: "",
          county: "",
          state: "",
          zip: "",
        };
      }
    },
    async uploadOrgLogo(orgLogo) {
      let response = await this.$store.dispatch("uploadOrgLogo", orgLogo);
      if (!this.$store.getters.getApiError) {
        // console.log("logo uploaded");
        // console.log(response);
      }
      this.currentOrg.logo_url = response.image;
    },
  },

  watch: {
    currentOrgId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (!this.orgId) {
          this.readCurrentOrg();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    orgId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readOrgById(value);
        } else {
          this.readCurrentOrg();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<style></style>
