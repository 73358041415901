<template>
  <div class="mx-auto max-w-3xl p-8 sm:col-span-6">
    <!-- Title -->
    <h1 class="mb-8 text-center text-3xl font-bold">
      GROWER ENROLLMENT AGREEMENT
    </h1>

    <!-- Effective Date -->
    <p class="mb-4">
      This Grower Enrollment Agreement (the “Agreement”) is effective as of this
      <span class="underline">{{ effectiveDateParts.day }}</span> day of
      <span class="underline">{{ effectiveDateParts.month }}</span
      >, <span class="underline">{{ effectiveDateParts.year }}</span> (the
      “Effective Date”) between Parcel, Thrive Inc (“Company”) and a grower (the
      “Grower” or “You”) with the following name and address:
    </p>

    <!-- Grower Information -->
    <div class="mb-4">
      <p>
        <strong>Grower Name:&nbsp;</strong>
        <span class="underline">{{ currentOrg?.name }}</span>
      </p>
      <div class="flex">
        <strong>Grower Address:&nbsp;</strong>
        <span class="underline">{{ formatAddress(currentOrg?.address) }}</span>
        <router-link
          :to="{
            name: 'grower-portal-settings-tab',
            params: { tab: 'organization' },
          }"
          class="block px-4 py-2 text-sm text-gray-700 active:bg-gray-100 active:text-gray-900"
          ><pencil-icon class="-mt-2 h-4 w-4"
        /></router-link>
      </div>
      <p>
        <strong>Phone Number:</strong>
        <span class="underline">{{ currentOrg?.contact_phone }}</span>
      </p>
      <p>
        <strong>E-Mail Address:</strong>
        <span class="underline">{{ currentOrg?.contact_email }}</span>
      </p>
    </div>

    <!-- Parties -->
    <p class="mb-4">
      Company and Grower may be referred to herein individually as a “Party” and
      jointly as the “Parties.”
    </p>

    <!-- Agreement Body -->
    <p class="mb-4">
      In consideration of the mutual promises hereinafter set forth, the parties
      agree to the following terms and conditions:
    </p>

    <!-- Sections -->
    <!-- Purpose -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Purpose.</h2>
      <p>
        The purpose of this Agreement is to establish the terms and conditions
        for Grower to participate in the Premium Program sponsored by the
        Company and described in each applicable Grower Program Addendum to this
        Agreement (the “Program”). The terms of each applicable Grower Program
        Addendum are incorporated into and made part of this Agreement.
      </p>
    </div>
    <!-- Payment -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Payment.</h2>
      <p>
        Company shall pay to Grower the amounts set forth in each applicable
        Grower Program Addendum upon satisfaction of the terms and conditions
        set forth on such exhibit.
      </p>
    </div>

    <!-- Term and Termination -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Term and Termination.</h2>
      <p>
        This Agreement will be effective as of the Effective Date and will
        expire as set forth in each applicable Grower Program Addendum (the
        “Term”), unless extended under a subsequent written agreement signed
        between the Parties.
      </p>
    </div>

    <!-- Representations and Warranties -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Representations and Warranties.</h2>

      <!-- Mutual Representations and Warranties -->
      <h3 class="mb-2 text-lg font-semibold">
        Mutual Representations and Warranties.
      </h3>
      <p>Each Party represents and warrants:</p>
      <ul class="mb-4 list-inside list-disc">
        <li>
          the execution and performance of this Agreement will not conflict with
          or violate any provision of law applicable to such Party;
        </li>
        <li>
          it has the full power and authority to enter into and perform this
          Agreement; and
        </li>
        <li>
          it is capable of assessing and understanding the terms, conditions and
          risks of this Agreement.
        </li>
      </ul>

      <!-- Grower Representations and Warranties -->
      <h3 class="mb-2 text-lg font-semibold">
        Grower Representations and Warranties.
      </h3>
      <p>Grower represents and warrants:</p>
      <ul class="mb-4 list-inside list-disc">
        <li>
          Grower agrees to not seek to receive multiple premiums or benefits for
          the same Qualified Acres under this Agreement or any other programs,
          whether offered by Parcel or external platforms, unless expressly
          approved in writing by Parcel; and
        </li>
        <li>
          If the Qualified Acres were previously enrolled in, or the Grower
          wishes to enroll the Qualified Acres in, another premium program in
          the future, the Grower shall first obtain Parcel’s written acceptance
          or approval prior to such enrollment. “Qualified Acres” means those
          specific and designated acres and fields that qualify for enrollment
          in the Program, as set forth in each applicable Grower Program
          Addendum.
        </li>
      </ul>
      <p>
        Grower retains the full rights to sell or otherwise dispose of any
        products not covered by this Agreement, including but not limited to
        carbon credits or crop produce, without limitation.
      </p>
    </div>

    <!-- Failure to Comply -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Failure to Comply.</h2>
      <p>
        Grower acknowledges that failure to comply with Section 4(b) of this
        Agreement will be considered a material breach of the Agreement, and
        Your participation in the Program may be terminated in the event of such
        breach. In that event, You may not be entitled to any then-unpaid
        incentive payments, and Company may require repayment for past incentive
        payments.
      </p>
    </div>

    <!-- Grower Commitments -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Grower Commitments.</h2>
      <p>For the Term of the Agreement, Grower commits to:</p>
      <ul class="mb-4 list-inside list-disc">
        <li>
          sharing relevant data with Company and its subcontractors and
          partners, as set forth in Section 6 of this Agreement;
        </li>
        <li>
          allowing Company, its subcontractors, partners and their
          representatives to verify and monitor Your data (either in person or
          remotely, through means including but not limited to remote
          environmental sensing, simulation, and computer vision);
        </li>
        <li>enrolling the Qualified Acres in the Program; and</li>
        <li>
          Program agricultural practices on the enrolled acres for the duration
          of this agreement. Failure to fully implement and maintain these
          practices, as reasonably determined by the Company, shall constitute a
          breach of this Agreement.
        </li>
      </ul>
    </div>

    <!-- Data -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Data.</h2>

      <!-- Program Data -->
      <h3 class="mb-2 text-lg font-semibold">Program Data.</h3>
      <p>
        You are required to maintain and provide Company with detailed and
        accurate records of all agricultural practices on each of the Qualified
        Acres and other documentation and information required by Company to
        verify your eligibility for, compliance with, and payment under the
        Program (“Program Data”). Program Data will be provided to the Company
        promptly upon request in the manner requested. You grant Company, its
        subcontractors, its partners, third-party beneficiaries, and their
        representatives the right to enter and access Your Qualified Acres from
        time to time to collect Program Data and monitor Your compliance with
        the terms of this Agreement and the Program. The Company will make best
        efforts to notify the Grower in advance any time access to the Qualified
        Acres may be necessary.
      </p>

      <!-- Grower Data - Definition -->
      <h3 class="mb-2 text-lg font-semibold">Grower Data - Definition.</h3>
      <p>
        “Grower Data” means any information, including Program Data, (i) entered
        in or otherwise provided or made available by or on Your behalf for
        processing by Company or its subcontractors or partners; or (ii)
        collected, downloaded, or otherwise received by Company or its
        subcontractors or partners pursuant to this Agreement or any Program
        Addendum or at Your written request or instruction. Notwithstanding the
        preceding, Grower Data does not include any aggregated or deidentified
        data collected or received by Company or its subcontractors or partners.
      </p>

      <!-- Grower Data - Ownership -->
      <h3 class="mb-2 text-lg font-semibold">Grower Data - Ownership.</h3>
      <p>
        Grower owns all rights, title, and interest to the Grower Data. Subject
        to the foregoing, Grower hereby grants to Company, its subcontractors,
        and partners a non-exclusive, non-transferable right and license to use
        Grower Data to perform their obligations under this Agreement and any
        applicable Program, and a non-exclusive, assignable, irrevocable,
        perpetual right to use Grower Data to improve and enhance their
        technological platforms and products.
      </p>
    </div>

    <!-- Termination -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Termination.</h2>
      <p>
        Company may terminate this Agreement at any time, on written notice to
        You, in the event that (i) You breach this Agreement or (ii) Company
        determines the Qualified Acres are not eligible for participation in the
        Program, even if through no fault of Grower. In the event Company
        terminates the Agreement through no fault of Grower (e.g., natural
        disaster), You will not be required to repay Company for any incentive
        payments You have received for your participation in the Program,
        subject to Your continuing compliance with all still-applicable Program
        rules, if any.
      </p>
    </div>

    <!-- Support Requests -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Support Requests.</h2>
      <p>
        Grower may submit any support requests or concerns to
        <a href="mailto:support@parcel.ag" class="text-blue-600 underline"
          >support@parcel.ag</a
        >.
      </p>
    </div>

    <!-- Independent Contractor -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Independent Contractor.</h2>
      <p>
        Grower will perform all obligations under this Agreement as an
        “independent contractor” and not as an employee or agent of the Company.
        Grower is not authorized to assume or create any obligation or
        responsibility, express or implied, on behalf of, or in the name of,
        Company or to bind Company in any manner.
      </p>
    </div>

    <!-- Indemnification -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Indemnification.</h2>
      <p>
        Grower will be solely liable for, and will indemnify, defend, and hold
        harmless Company, its subcontractors, and partners and their successors,
        assigns, and third-party beneficiaries from and against any claims,
        suits, judgments, or causes of action initiated by any third party
        against Company, its subcontractors, or its partners where such actions
        result from or arise out of any gross negligence, willful misconduct,
        intentional breach, or fraud of Grower under this Agreement. Grower will
        further be solely liable for, and will indemnify, defend, and hold
        harmless Company and its successors and assigns from and against any
        claim or liability of any kind (including penalties, fees, or charges)
        resulting from Grower's failure to pay taxes or penalties arising in
        connection with this Agreement.
      </p>
    </div>

    <!-- Governing Law; Jurisdiction and Venue -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">
        Governing Law; Jurisdiction and Venue.
      </h2>
      <p>
        This Agreement shall be interpreted according to the laws of the State
        of Montana, without regard to any conflict of laws provisions which
        would compel the application of the law of any other forum. Any legal
        suit, action, or proceeding arising out of this Agreement shall be
        instituted in a United States federal court or state court located in
        Montana, and each Party irrevocably submits to the exclusive
        jurisdiction of such courts in any such suit, action, or proceeding.
      </p>
    </div>

    <!-- Subcontractors -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Subcontractors.</h2>
      <p>
        The Company may subcontract or partner with any person or entity for the
        performance of any of its duties or obligations under this Agreement.
      </p>
    </div>

    <!-- Third Party Beneficiaries -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Third Party Beneficiaries.</h2>
      <p>
        The Parties hereby designate the parties listed in Addendum -
        Beneficiaries as third-party beneficiaries of this Agreement. No other
        person who is not a Party to this Agreement has any rights under this
        Agreement or may enforce any provision in this Agreement.
      </p>
    </div>

    <!-- Assignment -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Assignment.</h2>

      <!-- Grower -->
      <h3 class="mb-2 text-lg font-semibold">Grower.</h3>
      <p>
        This Agreement is personal to Grower, and Grower will not have the right
        to assign any of Grower’s rights or delegate any of Grower’s duties
        without the express written consent of the Company. Any non-consented-to
        assignment or delegation, whether express or implied or by operation of
        law, will be void and will constitute a breach and a default by Grower.
        The foregoing notwithstanding, in the event Grower transfers ownership
        of the Qualified Acres to a third party during the Term of this
        Agreement, Your rights and obligations under this Agreement will
        transfer to the third party with the Qualified Acres.
      </p>

      <!-- Company -->
      <h3 class="mb-2 text-lg font-semibold">Company.</h3>
      <p>
        This Agreement will be binding upon, and inure to the benefit of,
        Company and its successors and assigns, including any corporation with
        which, or into which, Company may be merged or which may succeed to its
        assets or business.
      </p>
    </div>

    <!-- Counterparts -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Counterparts.</h2>
      <p>
        This Agreement may be executed in counterparts, each of which shall be
        deemed an original, but all of which together shall be deemed to be one
        and the same agreement. A signed copy of this Agreement delivered by
        email or other means of electronic transmission shall be deemed to have
        the same legal effect as delivery of an original signed copy of this
        Agreement.
      </p>
    </div>

    <!-- Entire Agreement -->
    <div class="mb-4">
      <h2 class="mb-2 text-xl font-bold">Entire Agreement.</h2>
      <p>
        This Agreement, together with and including any other documents or
        exhibits referenced herein or attached hereto, constitutes the full
        understanding of the Parties and supersedes all prior understandings of
        the Parties, both written and oral, and no terms, conditions,
        understandings, or agreements purporting to modify or vary the terms of
        this document shall be binding unless hereafter made in writing and
        signed by the Parties.
      </p>
    </div>

    <!-- Signature Section -->
    <div class="mt-8">
      <p class="mb-4">
        IN WITNESS WHEREOF, the Parties have executed and delivered this
        Agreement as of the Effective Date.
      </p>
      <div class="flex justify-between">
        <!-- Company Signature -->
        <div>
          <p><strong>Parcel, Thrive Inc.</strong></p>
          <p>Signature: <span class="underline">Adam Irrer</span></p>
          <p>Print Name: <span class="underline">Adam Irrer</span></p>
          <p>Title: <span class="underline">CEO</span></p>
        </div>
        <!-- Grower Signature -->
        <div>
          <p><strong>Grower</strong></p>
          <p>
            Signature:
            <span class="underline">{{ currentUser?.name }}</span>
          </p>
          <p>
            Print Name:
            <span class="underline">{{ currentUser?.name }}</span>
          </p>
          <p>
            Title (if entity):
            <span class="underline">{{ currentOrg?.name }}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- Addendums -->
    <!-- Program Addendum: [Program Requirements / Terms]   -->
    <!-- Beneficiaries Addendum: [list of beneficiaries who will receive data from this program] -->
    <!-- <div class="mt-8">
      <h2 class="mb-4 text-2xl font-bold">GROWER PROGRAM ADDENDUM</h2>
      <p class="mb-4">{{ programAddendum }}</p>

      <h2 class="mb-4 text-2xl font-bold">BENEFICIARIES ADDENDUM</h2>
      <p class="mb-4">{{ beneficiariesAddendum }}</p>
    </div> -->
  </div>
</template>

<script>
import { PencilIcon } from "@heroicons/vue/outline";
export default {
  props: {
    offerId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    PencilIcon,
  },
  computed: {
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    effectiveDateParts() {
      const date = new Date();
      return {
        day: date.getDate(),
        month: date.toLocaleString("default", { month: "long" }),
        year: date.getFullYear(),
      };
    },
  },
  methods: {
    formatAddress(addressString) {
      if (!addressString) return "";
      let addressObj = JSON.parse(addressString);
      let address = " ";
      if (addressObj.street) address += addressObj.street + " ";
      if (addressObj.city) address += addressObj.city + ", ";
      if (addressObj.state) address += addressObj.state + " ";
      if (addressObj.zip) address += addressObj.zip;
      return address;
    },
  },
};
</script>
