<template>
  <display-only
    displayTitle="Allocated Crops"
    :displayValue="cropTypesOfOrderAllocations"
  />
  <display-only
    displayTitle="Allocated Varieties"
    :displayValue="varietiesOfOrderAllocations"
  />
  <number-formatted-input
    inputTitle="Total Allocated Acres"
    :displayOnlyValue="sumAllocationAcresByOrderId"
    :wideItem="true"
    :displayOnly="true"
    v-model="sumAllocationAcresByOrderId"
  />
  <display-only
    :displayTitle="cropAllocationUnitsLabel"
    :displayValue="estimatedAllocatedQty"
  />
</template>

<script>
import DisplayOnly from "@/components/form/DisplayOnly.vue";
import NumberFormattedInput from "@/components/form/NumberFormattedInput.vue";
import { tonsToBushels } from "@/components/composables/convertUnits";
export default {
  props: {
    allocations: {
      type: Array,
      required: true,
    },
    currentOrderUnits: {
      type: String,
      required: true,
    },
  },
  components: {
    DisplayOnly,
    NumberFormattedInput,
  },
  computed: {
    cropAllocationUnitsLabel() {
      let label = "Estimated Allocated Tons";
      let units = this.currentOrderUnits;
      // console.log("units: ", units, typeof units);
      if (
        units &&
        (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu")
      ) {
        label = "Estimated Allocated Bushels";
      }
      return label;
    },
    cropTypesOfOrderAllocations() {
      //console.log("crop types");
      let cropTypes = [];
      for (let allocation of this.allocations) {
        if (
          !cropTypes.includes(allocation?.shared_data?.crop_details?.crop_type)
        ) {
          cropTypes.push(allocation?.shared_data?.crop_details?.crop_type);
        }
      }
      return cropTypes.sort().join(", ");
    },
    varietiesOfOrderAllocations() {
      //console.log("varieties");
      let varieties = [];
      for (let allocation of this.allocations) {
        if (
          allocation?.shared_data?.crop_details?.details?.variety &&
          !varieties.includes(
            allocation?.shared_data.crop_details?.details?.variety,
          )
        ) {
          varieties.push(
            allocation?.shared_data.crop_details?.details?.variety,
          );
        }
      }
      return varieties.sort().join(", ");
    },
    sumAllocationAcresByOrderId() {
      let totalAcres = 0;
      for (let allocation of this.allocations) {
        totalAcres += allocation.acres;
      }
      return totalAcres.toFixed(1);
    },
    estimatedAllocatedQty() {
      //console.log("tpa");
      let tpaEstimates = [];
      let cropType = "";
      let units = this.currentOrderUnits;
      for (let allocation of this.allocations) {
        let crop = allocation?.shared_data?.crop_details;
        if (crop?.details?.tpa) {
          tpaEstimates.push(parseFloat(crop?.details?.tpa));
        }
        if (!cropType.includes(crop?.crop_type)) {
          cropType += crop?.crop_type;
        }
      }
      // console.log(cropType, units);
      if (tpaEstimates.length > 0) {
        let tons = (
          this.median(tpaEstimates) *
          parseFloat(this.sumAllocationAcresByOrderId)
        ).toFixed(1);
        let qty = tons; // default is estimate in tons
        if (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu") {
          qty = tonsToBushels(cropType, tons);
        }

        return qty;
      } else {
        return "Estimate at least one allocated field's TPA to receive an estimate";
      }
    },
  },
  methods: {
    median(values) {
      if (values.length === 0) throw new Error("No inputs");
      values.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) return values[half];
      return (values[half - 1] + values[half]) / 2.0;
    },
  },
};
</script>
