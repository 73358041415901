<template>
  <div class="sm:col-span-6">
    <stats-row :stats="premiumDataStats" />

    <page-card
      v-for="(premium, premiumIndex) in validatedPremiums"
      :key="premiumIndex"
      :heading-title="
        premium.type === 'fixed'
          ? '$' + premium.value
          : premium.type + ' Premium'
      "
      :inserted="true"
    >
      <template #cardDetails>
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <ul role="list" class="divide-y divide-gray-100 sm:col-span-6">
            <li
              class="relative flex justify-between py-5"
              @click="navigateToOrderPremiums(premium.id)"
            >
              <div class="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
                <!-- <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.imageUrl" alt="" /> -->
                <div class="min-w-0 flex-auto">
                  <p class="flex text-sm font-semibold leading-6 text-gray-900">
                    <span class="absolute inset-x-0 -top-px bottom-0" />

                    Fields Validated
                  </p>
                  <p
                    class="mt-1 flex text-xs leading-5 text-gray-500"
                    v-if="premium.premium_met"
                  >
                    <span class="mr-2">
                      <CheckIcon class="h-5 w-5 text-green-500" />
                    </span>
                    {{
                      premium.validated_allocations +
                      " of " +
                      premium.counted_allocations +
                      " completed"
                    }}
                  </p>
                  <p class="mt-1 flex text-xs leading-5 text-gray-500" v-else>
                    <span class="mr-2">
                      <XIcon class="h-5 w-5 text-red-500" />
                    </span>
                    {{
                      premium.validated_allocations +
                      " of " +
                      premium.counted_allocations +
                      " completed"
                    }}
                  </p>
                </div>
              </div>
              <div
                class="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none"
              >
                <div class="hidden sm:block">
                  <p
                    v-for="req in interpretPremium(premium, true)"
                    :key="req"
                    class="text-sm leading-6 text-gray-900"
                  >
                    {{ req }}
                  </p>
                </div>
                <p class="flex text-gray-400">
                  Edit
                  <OutlineHeroIcon
                    :name="'PencilIcon'"
                    classProp="h-5 w-5 ml-2 text-gray-400"
                    aria-hidden="true"
                  />
                </p>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
import { harvestUnits } from "@/components/composables/convertUnits";
import { CheckIcon, XIcon } from "@heroicons/vue/solid"; // Import the necessary Heroicons
import StatsRow from "@/components/cards/StatsRow.vue";

import {
  calculateFixedPremium,
  interpretPremium,
} from "@/components/composables/growerPremiumUtils.js";

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["updatePremiumData"],
  components: {
    PageCard,
    OutlineHeroIcon,
    CheckIcon,
    XIcon,
    StatsRow,
  },
  data() {
    return {
      // Statuses
      statuses: {
        Complete: "text-green-700 bg-green-50 ring-green-600/20",
        "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
        Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
      },
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      return this.$store.getters.getGrowerAllocationsByOrderId(this.orderId);
    },
    validatedPremiums() {
      if (
        !this.dataIsLoaded ||
        !this.currentOffer?.premiums ||
        !this.currentOrder ||
        !this.allocations
      ) {
        return [];
      }
      return this.currentOffer.premiums.map((premium) => {
        return {
          ...premium,
          value: calculateFixedPremium(premium, this.currentOrder?.quantity),
          // validated_allocations should be the count of allocations that contain the premium id in the allocation's validations_met array
          validated_allocations: this.getValidatedAllocations(premium.id),
          counted_allocations: this.allocations?.length ?? 0,
          premium_met:
            this.getValidatedAllocations(premium.id) ===
              this.allocations?.length ?? 0,
        };
      });
    },
    premiumData() {
      if (!this.dataIsLoaded) {
        return {};
      }
      const validatedPremiums = this.validatedPremiums || []; // Make sure validatedPremiums is always an array
      return {
        // when calculating the total value, only include the value of premiums that are fixed
        totalValue: validatedPremiums
          .reduce(
            (acc, premium) =>
              acc + (premium.type === "fixed" ? premium.value : 0),
            0,
          )
          .toFixed(2),
        // sum of value where premium.premium_met === true
        completedValue: validatedPremiums
          .filter((premium) => premium.premium_met)
          .reduce(
            (acc, premium) =>
              acc + (premium.type === "fixed" ? premium.value : 0),
            0,
          )
          .toFixed(2),
        // count of premiums in validatedPremiums
        totalPremiums: validatedPremiums.length,
        // count of premiums where premium_met === true
        completedPremiums: validatedPremiums.filter(
          (premium) => premium.premium_met,
        ).length,
        // non-fixed premiums - the number of premiums without a fixed value
        nonFixedPremiums: validatedPremiums.filter(
          (premium) => premium.type !== "fixed",
        ).length,
      };
    },
    premiumDataStats() {
      return [
        { name: "Total Value", stat: "$" + this.premiumData.totalValue },
        {
          name: "Completed Premiums",
          stat:
            this.premiumData.completedPremiums +
            " of " +
            this.premiumData.totalPremiums,
        },
        {
          name: "Completed Value",
          stat: "$" + this.premiumData.completedValue,
        },
      ];
    },
    unitOptions() {
      let unitOptions = [{ label: "Acres", value: "acres" }];
      let cropUnit = harvestUnits(this.cropType);
      if (cropUnit) {
        unitOptions.push(cropUnit);
      }
      return unitOptions;
    },
  },
  methods: {
    navigateToOrderPremiums(pId) {
      this.$router.push({
        name: "grower-portal-offer-order-premiums",
        params: { orderId: this.orderId },
        query: { premiumId: pId },
      });
    },
    defaultHarvestUnits() {
      if (this.total_or_unit === "total") {
        this.harvest_units = "";
      }
      if (this.total_or_unit === "unit" && this.harvest_units === "") {
        this.harvest_units = this.contract?.quantity_units;
      }
    },
    interpretPremium,
    getValidatedAllocations(premiumId) {
      return this.allocations.filter((allocation) =>
        allocation.validations_met.includes(premiumId),
      ).length;
    },
  },
  watch: {
    premiumData: {
      handler: function (newVal) {
        this.$emit("updatePremiumData", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
