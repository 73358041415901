<template>
  <slot-modal
    :open="modalIsActive"
    :modalTitle="readOnly ? 'View Validation Record' : 'Save Validation Record'"
    @closeModal="closeRecordModal"
  >
    <template #icon>
      <OutlineHeroIcon
        :name="readOnly ? 'DocumentIcon' : 'PlusIcon'"
        classProp="h-5 w-5"
        aria-hidden="true"
      />
    </template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <select-dropdown
            :wideItem="true"
            inputTitle="Record Type"
            inputHtmlType="text"
            v-model="workingRecord.category"
            :selectOptions="recordTypes"
            :display-only="readOnly"
            :display-only-value="workingRecord.category"
          />
          <text-area
            :wideItem="true"
            inputTitle="Record Requirements"
            inputHtmlType="text"
            v-model="workingRecord.requirements"
            class="sm:col-span-6"
            :displayOnly="readOnly"
            :displayOnlyValue="workingRecord.requirements"
          />
          <div v-if="workingRecordReadOnly" class="col-span-6">
            <p>Original Attribute Selections</p>
            <pill-list :pills="initialWorkingRecordAttributes" />
          </div>
          <checkbox-input
            v-if="!workingRecordReadOnly"
            :wideItem="true"
            inputTitle="Include All Attributes"
            :inputHtmlType="'checkbox'"
            v-model="workingRecord.attributes.all"
            :displayOnly="readOnly"
            :displayOnlyValue="workingRecord.attributes.all"
          />
          <list-select2
            v-if="!workingRecord.attributes.all && !workingRecordReadOnly"
            :wideItem="true"
            inputTitle="New Attribute Requirements"
            :selectOptions="recordAttributes"
            select-options-label="label"
            @update-selections="workingRecord.attributes.payload = $event"
          />
        </div>
      </div>
    </template>
    <template #buttons>
      <base-button
        v-if="!readOnly && !workingRecordReadOnly"
        buttonText="Save Record"
        :marginLeft="false"
        @buttonClick="
          saveRecord(
            temporaryPremiumIndex,
            temporaryRecordIndex == null ? null : temporaryRecordIndex,
          )
        "
      />
    </template>
  </slot-modal>
  <div class="sm:col-span-6">
    <page-card
      v-for="(premium, index) in localPremiums"
      :key="index"
      :heading-title="'Premium ' + (index + 1)"
      :inserted="true"
    >
      <template #buttonArea>
        <base-button
          v-if="!readOnly"
          buttonText="Delete"
          :redButton="true"
          :marginLeft="true"
          @buttonClick="deletePremium(index)"
          :disabled="readOnly"
        />
      </template>
      <template #cardDetails>
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <select-search
            :wideItem="true"
            inputTitle="Premium Type"
            inputHtmlType="text"
            v-model="premium.type"
            :selectOptions="premiumTypes"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.type"
            help-text="Only fixed type premiums will be computed by Parcel. Threshold and Range premiums require manual review."
          />
          <text-area
            :wideItem="true"
            inputTitle="Terms"
            inputHtmlType="text"
            v-model="premium.terms"
            help-text="A description in simple terms what the grower must do to be paid this premium."
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.terms"
          />
          <!-- Inputs for Non-Other Type Premiums -->
          <select-search
            v-if="premium.type != 'other'"
            :wideItem="true"
            inputTitle="Lump Total Sum or Per Unit Premium?"
            inputHtmlType="text"
            v-model="premium.total_or_unit"
            :selectOptions="totalOrUnitTypes"
            help-text="Total means the premium is paid once, Unit means the premium is paid per unit"
            @change="defaultHarvestUnits"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.total_or_unit"
          />
          <select-search
            v-if="premium.type != 'other' && premium.total_or_unit != 'total'"
            :wideItem="true"
            inputTitle="Units"
            inputHtmlType="text"
            v-model="premium.harvest_units"
            :select-options="unitOptions"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.harvest_units"
          />
          <!-- Inputs for Fixed & Threshold Premium -->
          <currency-input
            v-if="premium.type === 'fixed' || premium.type === 'threshold'"
            :wideItem="true"
            inputTitle="Premium"
            inputHtmlType="text"
            v-model="premium.base_premium"
            help-text="That value that will be paid per unit or as a lump sum if the threshold is met"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.base_premium"
          />
          <!-- Inputs for Threshold Premium -->
          <number-input
            v-if="premium.type === 'threshold'"
            :wideItem="true"
            inputTitle="Threshold Score"
            inputHtmlType="text"
            v-model="premium.score_target"
            help-text="The score that must be met to receive the premium"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.score_target"
          />
          <!-- Inputs for Threshold & Range Premium -->
          <select-search
            v-if="premium.type === 'threshold' || premium.type === 'range'"
            :wideItem="true"
            inputTitle="Score Preferred"
            v-model="premium.score_preference"
            :selectOptions="scorePreferenceTypes"
            help-text="lower means you prefer a lower score, higher means you prefer a higher score"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.score_preference"
          />
          <text-input
            v-if="premium.type === 'threshold' || premium.type === 'range'"
            :wideItem="true"
            inputTitle="Score Units (CI Score, Grams Carbon, etc)"
            inputHtmlType="text"
            v-model="premium.score_units"
            help-text="The units of the score, e.g. CI Score, Grams Carbon, Brix, etc"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.score_units"
          />
          <!-- Inputs for Range Premium -->
          <number-input
            v-if="premium.type === 'range'"
            :wideItem="true"
            inputTitle="Lower Bound"
            inputHtmlType="text"
            v-model="premium.score_lower_bound"
            help-text="The lower bound of the range"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.score_lower_bound"
          />
          <number-input
            v-if="premium.type === 'range'"
            :wideItem="true"
            inputTitle="Upper Bound"
            inputHtmlType="text"
            v-model="premium.score_upper_bound"
            help-text="The upper bound of the range"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.score_upper_bound"
          />
          <currency-input
            v-if="premium.type === 'range'"
            :wideItem="true"
            inputTitle="Premium per Point"
            inputHtmlType="text"
            v-model="premium.premium_per_point"
            help-text="The premium paid per point past the lower bound"
            class="sm:col-span-3"
            :displayOnly="readOnly"
            :displayOnlyValue="premium.premium_per_point"
          />
        </div>
        <!-- Records for Each Premium -->
        <div
          class="mt-4 flex justify-center sm:col-span-6"
          v-if="premium.validation_records.length === 0"
        >
          <base-button
            v-if="!readOnly"
            :buttonText="'Add Record'"
            :marginLeft="false"
            @buttonClick="openRecordModal(index)"
            :disabled="readOnly"
          />
        </div>
        <div
          class="sm:col-span-6"
          v-else-if="premium.validation_records.length > 0"
        >
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  {
                    name: 'category',
                    label: 'Type',
                    filtered: false,
                    sorted: false,
                  },
                  {
                    name: 'requirements',
                    label: 'Requirements',
                    filtered: false,
                    sorted: false,
                  },
                  {
                    name: 'actions',
                    label: '',
                    filtered: false,
                    sorted: false,
                  },
                  {
                    name: 'actions',
                    label: '',
                    filtered: false,
                    sorted: false,
                  },
                ]"
                :collection="premium.validation_records"
              >
                <template #toolbarButtons>
                  <div class="flex">
                    <table-toolbar-button
                      v-if="!readOnly"
                      buttonText="Add Record"
                      :usePlusIcon="true"
                      @buttonClick="openRecordModal(index)"
                      :disabled="readOnly"
                    />
                  </div>
                </template>
              </table-head>
            </template>

            <tbody>
              <tr
                v-for="(record, recordIndex) in premium.validation_records"
                :key="record.rowid"
              >
                <table-data td-label="Type">
                  {{ record.category }}
                </table-data>
                <table-data td-label="Requirements">
                  {{ record.requirements }}
                </table-data>
                <table-data class="text-center">
                  <button
                    @click="viewRecord(record, index, recordIndex)"
                    :disabled="false"
                  >
                    View
                  </button>
                </table-data>
                <table-data class="text-center">
                  <button
                    v-if="!readOnly"
                    @click="deleteRecord(index, recordIndex)"
                    :disabled="readOnly"
                  >
                    Delete
                  </button>
                </table-data>
              </tr>
            </tbody>
          </table-card>
        </div>
      </template>
    </page-card>
    <div class="mt-4 flex justify-center">
      <base-button
        v-if="!readOnly"
        :buttonText="'Add Premium'"
        :marginLeft="false"
        @buttonClick="createPremium()"
        :disabled="readOnly"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PageCard from "@/components/cards/PageCard.vue";
// import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import ListSelect2 from "@/components/form/ListSelect2.vue";
import { harvestUnits } from "@/components/composables/convertUnits";
import PillList from "@/components/form/PillList.vue";

const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  props: {
    contractId: {
      type: [String, Number],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  components: {
    TextInput,
    CurrencyInput,
    TextArea,
    NumberInput,
    BaseButton,
    PageCard,
    SelectSearch,
    SelectDropdown,
    // RecordFormLayout,
    SlotModal,
    OutlineHeroIcon,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    CheckboxInput,
    ListSelect2,
    PillList,
  },
  data() {
    return {
      premiumTypes: [
        { value: "fixed", label: "Fixed" },
        { value: "threshold", label: "Threshold" },
        { value: "range", label: "Range" },
        { value: "other", label: "Other" },
      ],
      totalOrUnitTypes: [
        { value: "total", label: "Total" },
        { value: "unit", label: "Unit" },
      ],
      scorePreferenceTypes: [
        { value: "higher", label: "Higher" },
        { value: "lower", label: "Lower" },
      ],
      localPremiums: [],
      premiumIdsForDeletion: [],
      validationRecordIdsForDeletion: [],
      recordTypes: RecordTypeSchemas.map((schema) => ({
        label: schema.category,
        value: schema.category,
      })),
      modalIsActive: false,
      // Record Creation
      temporaryPremiumIndex: null,
      temporaryRecordIndex: null,
      initialWorkingRecordAttributes: [],
      workingRecordReadOnly: false,
      workingRecord: {
        requirements: "",
        category: "Note",
        attributes: {
          all: true,
          payload: [],
        },
      },
    };
  },
  computed: {
    currentContract() {
      return this.$store.getters.getContractById(this.contractId);
    },
    initalContractPremiums() {
      return this.$store.getters.getContractPremiumsByContractId(
        this.contractId,
      );
    },
    unitOptions() {
      let unitOptions = [{ label: "Acres", value: "acres" }];
      let cropUnit = harvestUnits(this.currentContract.crop);
      if (cropUnit) {
        unitOptions.push(cropUnit);
      }
      return unitOptions;
    },
    recordAttributes() {
      const selectedCategory = this.workingRecord.category;
      const recordType = RecordTypeSchemas.find(
        (schema) => schema.category === selectedCategory,
      );
      return (
        recordType?.data.payload.map((attribute) => ({
          label: attribute.label,
          value: attribute.value,
        })) || []
      );
    },
  },
  methods: {
    createPremium() {
      this.localPremiums.push({
        type: "fixed",
        terms: "",
        total_or_unit: "total",
        harvest_units: "",
        base_premium: null,
        validation_records: [],
      });
    },
    deletePremium(index) {
      // if the premium has an id, add it to the list of ids to delete
      if (this.localPremiums[index].id) {
        this.premiumIdsForDeletion.push(this.localPremiums[index].id);
      }
      this.localPremiums.splice(index, 1);
    },
    saveRecord(premiumIndex, recordIndex) {
      const newRecord = { ...this.workingRecord };

      if (this.workingRecord.attributes.payload.length === 0) {
        newRecord.attributes = { all: true };
      } else {
        newRecord.attributes = {
          all: false,
          payload: this.workingRecord.attributes.payload.map(
            (item) => item.value,
          ),
        };
      }

      if (recordIndex === null) {
        this.localPremiums[premiumIndex].validation_records.push(newRecord);
      } else {
        this.localPremiums[premiumIndex].validation_records.splice(
          recordIndex,
          1,
          newRecord,
        );
      }

      this.closeRecordModal();
    },
    deleteRecord(premiumIndex, recordIndex) {
      // if the record has an id, add it to the list of ids to delete
      if (this.localPremiums[premiumIndex].validation_records[recordIndex].id) {
        this.validationRecordIdsForDeletion.push({
          premiumId: this.localPremiums[premiumIndex].id,
          validationRecordId:
            this.localPremiums[premiumIndex].validation_records[recordIndex].id,
        });
      }
      this.localPremiums[premiumIndex].validation_records.splice(
        recordIndex,
        1,
      );
    },
    closeRecordModal() {
      this.temporaryPremiumIndex = null;
      this.temporaryRecordIndex = null;
      this.modalIsActive = false;
      this.initialWorkingRecordAttributes = [];
      this.workingRecordReadOnly = false;
      this.resetWorkingRecord();
    },
    openRecordModal(premiumIndex) {
      this.temporaryPremiumIndex = premiumIndex;
      this.temporaryRecordIndex = null;
      this.modalIsActive = true;
      this.resetWorkingRecord();
    },
    viewRecord(record, premiumIndex, recordIndex) {
      this.temporaryPremiumIndex = premiumIndex;
      this.temporaryRecordIndex = recordIndex;
      this.initialWorkingRecordAttributes = record.attributes.payload;
      this.workingRecordReadOnly = true;
      this.workingRecord = { ...record };
      this.modalIsActive = true;
    },
    defaultHarvestUnits() {
      if (this.total_or_unit === "total") {
        this.harvest_units = "";
      }
      if (this.total_or_unit === "unit" && this.harvest_units === "") {
        this.harvest_units = this.contract?.quantity_units;
      }
    },
    resetWorkingRecord() {
      this.workingRecord = {
        requirements: "",
        category: "Note",
        attributes: {
          all: true,
          payload: [],
        },
      };
    },
    async submitPremiumsChanges() {
      // delete validation records
      for (let recordIdObject of this.validationRecordIdsForDeletion) {
        console.log("recordIdObject", recordIdObject);
        this.$store.dispatch("deletePremiumValidationRecord", recordIdObject);
      }
      // delete premiums
      for (let premiumId of this.premiumIdsForDeletion) {
        this.$store.dispatch("deleteContractPremium", premiumId);
      }
      // update premiums by checking if the id exists, if it does, update, if not, create
      for (let premium of this.localPremiums) {
        // if the premium.id exists, check to see if it is identical to the initalContractPremiums after ignoring the validation_records for both
        if (premium.id) {
          let initalPremium = this.initalContractPremiums.find(
            (p) => p.id === premium.id,
          );
          if (initalPremium) {
            let initalPremiumWithoutRecords = {
              ...initalPremium,
              validation_records: [],
            };
            let premiumWithoutRecords = {
              ...premium,
              validation_records: [],
            };
            if (
              JSON.stringify(initalPremiumWithoutRecords) !==
              JSON.stringify(premiumWithoutRecords)
            ) {
              console.log(
                "initalPremiumWithoutRecords: ",
                JSON.stringify(initalPremiumWithoutRecords),
              );
              console.log(
                "premiumWithoutRecords: ",
                JSON.stringify(premiumWithoutRecords),
              );
              this.$store.dispatch("updateContractPremium", {
                contractId: this.contractId,
                contractPremium: premium,
              });
            }
          }
          // create validation records
          for (let record of premium.validation_records) {
            if (record.id) {
              continue;
            } else {
              console.log("premium.id", premium.id);
              console.log("record", record);
              this.$store.dispatch("createPremiumValidationRecord", {
                premiumId: premium.id,
                validationRecord: record,
              });
            }
          }
        } else {
          let newPremium = await this.$store.dispatch("createContractPremium", {
            contractId: this.contractId,
            contractPremium: premium,
          });
          // create validation records against the new premium
          for (let record of premium.validation_records) {
            this.$store.dispatch("createPremiumValidationRecord", {
              premiumId: newPremium.id,
              validationRecord: record,
            });
          }
        }
      }
    },
  },
  watch: {
    initalContractPremiums: {
      immediate: true,
      handler(newValue) {
        this.localPremiums = structuredClone(
          newValue.map((premium) => ({
            ...premium,
          })),
        );
      },
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
