<template>
  <styled-input
    type="text"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
    v-model="inputValue"
    @blur="setValue"
  />
</template>

<script>
import StyledInput from "./StyledInput.vue";

export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String, default: null },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Number] },
    placeholder: { type: String },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    maxDecimalPlaces: { type: Number, default: 2 },
    minDecimalPlaces: { type: Number, default: 0 },
    modelValue: {},
    nullIfEmpty: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    let amount;
    if (this.displayOnly) {
      amount = this.displayOnlyValue;
    } else {
      amount = this.modelValue;
    }
    // console.log("AMOUNT", amount);
    if (amount == null) amount = 0;
    if (typeof amount == "string") {
      amount = Number(amount.replace(/[^0-9.]+/g, ""));
    }
    amount = this.formatLocalNumber(amount);
    return {
      inputValue: amount,
    };
  },
  methods: {
    setValue(value) {
      let amount = value;
      if (typeof amount == "string")
        amount = Number(amount.replace(/[^0-9.]+/g, ""));
      if (value == "" && this.nullIfEmpty) {
        amount = null;
      }
      this.$emit("update:modelValue", amount);
      if (amount) amount = this.formatLocalNumber(amount);
      this.inputValue = amount;
    },
    formatLocalNumber(value) {
      var formatter = new Intl.NumberFormat("en-US", {
        // style: "currency",
        // currency: "USD",
        minimumFractionDigits: this.minDecimalPlaces,
        maximumFractionDigits: this.maxDecimalPlaces,
      });
      let amount = formatter.format(value);
      return amount;
    },
  },
  watch: {
    displayOnly() {
      if (this.displayOnly) {
        let amount = this.displayOnlyValue;
        if (typeof amount == "string")
          amount = Number(amount.replace(/[^0-9.]+/g, ""));
        amount = this.formatLocalNumber(amount);
        this.inputValue = amount;
      }
    },
    displayOnlyValue() {
      if (this.displayOnly) {
        let amount = this.displayOnlyValue;
        if (typeof amount == "string")
          amount = Number(amount.replace(/[^0-9.]+/g, ""));
        amount = this.formatLocalNumber(amount);
        this.inputValue = amount;
      }
    },
  },
};
</script>
