<template>
  <!-- <page-card
    :headingTitle="'Free Trial'"
    v-if="subscription_status == 'trialing' && days_until > 0"
  >
    <template #cardDetails>
      <div class="sm:col-span-6">
        There are {{ days_until }} days remaining in your free trial. Click
        <router-link :to="{ name: 'vendor-subscription' }" class="underline"
          >here</router-link
        >
        to manage your subscription.
      </div>
    </template>
  </page-card>
  <br /> -->

  <Modal
    :show="showPreviewModal"
    title="Listing Preview"
    dialogPanelClass="max-w-full"
  >
    <div class="flex w-full justify-end">
      <button @click="showPreviewModal = false">
        <XIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <!-- <complete-company-listing :currentCompany="companyListing" /> -->
    <div class="inline-flex w-full justify-center pt-4">
      <base-button
        buttonText="close"
        :marginLeft="true"
        @buttonClick="showPreviewModal = false"
      />
    </div>
  </Modal>
  <page-card :headingTitle="'Company Listing'">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="true"
        formId="companyListingForm"
      />
      <!-- <base-button
        buttonText="Preview"
        :redButton="false"
        @buttonClick="showPreviewModal = true"
      /> -->
      <base-button
        v-if="companyListing?.available_on"
        buttonText="Delist"
        :redButton="true"
        @buttonClick="delistCompanyListing"
      />
      <base-button
        v-else
        buttonText="Publish"
        :marginLeft="true"
        @buttonClick="publishCompanyListing"
      />
    </template>
    <template #cardDetails>
      <div class="flex sm:col-span-6">
        <div class="sm:col-span-1">
          <div class="flex justify-center pr-4 sm:col-span-3 md:justify-start">
            <div>
              <img
                v-if="!companyListing?.logo_url"
                src="@/assets/no_logo_default.jpeg"
                class="h-48 w-48"
              />
              <img v-else :src="companyListing?.logo_url" class="w-96" />
              <file-select
                buttonText="Edit Company Logo"
                @input="uploadCompanyListingLogo"
                accept="image/*"
                class="mt-8"
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <form
            @submit.prevent="() => saveAndPreviewCompanyListing()"
            id="companyListingForm"
            class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
            v-if="companyListing"
          >
            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                Company Name
                <span class="text-red-600">*</span>
                <router-link
                  :to="{ name: 'vendor-settings' }"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(Manage)</router-link
                >
              </label>
              <display-only :wideItem="true" :displayValue="org?.name" />
            </div>
            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                Company Territory
                <span class="text-red-600">*</span>
                <router-link
                  :to="{ name: 'vendor-revenue-territories' }"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(Manage)</router-link
                >
                <ToolTip
                  helpText="Please select a territory where you provide service. Click the manage link to add a territory if needed. "
                  width="w-48"
                />
              </label>

              <select-search
                :noTitle="true"
                :wideItem="true"
                :required="true"
                placeholder="Pick a Territory"
                :selectOptions="territories"
                v-model="companyListing.territory"
              />
            </div>
            <!-- <display-only
          :wideItem="true"
          displayTitle="Header Title"
          :displayValue="companyListing.name"
        /> -->
            <!-- <variable-string-type-input
              :wideItem="true"
              inputTitle="Tagline"
              v-model="companyListing.tagline"
              :required="true"
            /> -->
            <text-area
              :fullWidth="true"
              inputTitle="Description"
              v-model="companyListing.desc_rtf"
              :required="true"
            />
            <variable-string-type-input
              v-if="$store.state.orgs.org_profile.subscription_tier"
              :wideItem="true"
              inputTitle="Button Text"
              v-model="companyListing.btn_1_text"
              :required="false"
            />
            <variable-string-type-input
              v-if="$store.state.orgs.org_profile.subscription_tier"
              :wideItem="true"
              inputTitle="Button Link"
              v-model="companyListing.btn_1_link"
              :required="false"
            />
            <!-- <variable-string-type-input
        :wideItem="true"
        inputTitle="Button Two Text"
        v-model="companyListing.btn_2_text"
      />
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Button Two Link"
        v-model="companyListing.btn_2_link"
      /> -->

            <!-- <text-area
          :wideItem="true"
          inputTitle="About Us"
          v-model="companyListing.about_rtf"
        /> -->
            <!-- <div class="sm:col-span-3" /> -->
            <!-- <file-select
              buttonText="Edit Company Logo"
              @input="uploadCompanyListingLogo"
              accept="image/*"
            /> -->
            <!-- <file-select
          v-if="$store.state.orgs.org_profile.subscription_tier"
          buttonText="Upload Listing Banner"
          @input="uploadCompanyListingBanner"
          accept="image/*"
        /> -->
          </form>
        </div>
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Features
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Benefits
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Resources
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                FAQ
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel
            as="dl"
            class="text-sm text-gray-500"
            :unmount="false"
            v-if="companyListing"
          >
            <listing-details-form
              :saveButtonText="'Save and Preview'"
              :orgProfile="companyListing"
              @saveButtonClick="saveFeatures"
              @updateProfile="updateProfile"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="text-sm text-gray-500"
            :unmount="false"
            v-if="companyListing"
          >
            <company-listing-simple-pf-form
              :fourEntries="true"
              :saveButtonText="'Save and Preview'"
              formDataType="features_list"
              :orgProfile="companyListing"
              :row_count="4"
              @saveButtonClick="saveFeatures"
              @updateProfile="updateProfile"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="text-sm text-gray-500"
            :unmount="false"
            v-if="companyListing"
          >
            <company-listing-simple-pf-form
              :saveButtonText="'Save and Preview'"
              formDataType="benefits_list"
              :orgProfile="companyListing"
              @saveButtonClick="saveBenefits"
              @updateProfile="updateProfile"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="text-sm text-gray-500"
            :unmount="false"
            v-if="companyListing"
          >
            <company-listing-resources-form
              :saveButtonText="'Save and Preview'"
              :orgProfile="companyListing"
              @saveResources="saveResources"
              @updateProfile="updateProfile"
              @updateImages="updateImageFiles"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="text-sm text-gray-500"
            :unmount="false"
            v-if="companyListing"
          >
            <company-listing-simple-pf-form
              :saveButtonText="'Save and Preview'"
              formDataType="faq_list"
              :orgProfile="companyListing"
              @saveButtonClick="saveFaq"
              @updateProfile="updateProfile"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import FileSelect from "@/components/form/FileSelect.vue";
import DisplayOnly from "@/components/form/DisplayOnly.vue";
import Modal from "@/components/modals/PopupModal.vue";
import ToolTip from "@/components/modals/ToolTip.vue";
// import CompleteCompanyListing from "@/layouts/common/listing/CompleteCompanyListing.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import TextInput from "@/components/form/TextInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
// import modal from "@/components/modals/PopupModal.vue";

// import AlertModal from "@/components/modals/AlertModal.vue";
// import DeleteModal from "@/components/modals/DeleteModal.vue";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
// import CompanyListingFeaturesTable from "@/layouts/vendor-portal/CompanyListingFeaturesTable.vue";
// import CompanyListingFaqTable from "@/layouts/vendor-portal/CompanyListingFaqTable.vue";
// import CompanyListingBenefitsTable from "@/layouts/vendor-portal/CompanyListingBenefitsTable.vue";
// import CompanyListingResourcesTable from "@/layouts/vendor-portal/CompanyListingResourcesTable.vue";
import CompanyListingSimplePfForm from "@/layouts/vendor-portal/CompanyListingSimplePfForm.vue";
import ListingDetailsForm from "@/layouts/vendor-portal/ListingDetailsForm.vue";
import CompanyListingResourcesForm from "@/layouts/vendor-portal/CompanyListingResourcesForm.vue";
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
// import moment from "moment";
export default {
  components: {
    PageCard,
    // DeleteModal,
    TextArea,
    SelectSearch,
    FileSelect,
    VariableStringTypeInput,
    BaseButton,
    SubmitButton,
    DisplayOnly,
    Modal,
    ToolTip,
    // CompleteCompanyListing,
    XIcon,
    // NumberInput,
    // SelectDropdown,
    // TextInput,
    // AlertModal,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    CompanyListingResourcesForm,
    ListingDetailsForm,
    // CompanyListingBenefitsTable,
    // CompanyListingFaqTable,
    // CompanyListingFeaturesTable,
    CompanyListingSimplePfForm,
  },
  data() {
    // let subscription_status =
    //   this.$store.state.orgs.org_profile.subscription_status;
    // let trial_start = this.$store.state.orgs.org_profile.created;
    // let trial_end = moment(trial_start).add(30, "days");
    // let days_until = moment
    //   .duration(trial_end.diff(moment().startOf("day")))
    //   .days();
    // let settableCompanyListing = this.$store.state.orgs.org_profile;
    // settableCompanyListing.name = this.$store.getters.getCurrentOrg.name;

    // // add blank rows to the array if the saved rows are less than the row count
    // if (settableCompanyListing) {
    //   if (settableCompanyListing?.resources_list?.length <= 3) {
    //     for (
    //       let i = settableCompanyListing.resources_list.length;
    //       i <= 3;
    //       i++
    //     ) {
    //       settableCompanyListing.resources_list.push({
    //         title: "",
    //         description: "",
    //         link_text: "",
    //         link_url: "",
    //         image_url: "",
    //       });
    //     }
    //   }

    //   for (let [formDataType, row_count] of Object.entries({
    //     features_list: 4,
    //     benefits_list: 3,
    //     faq_list: 3,
    //   })) {
    //     if (settableCompanyListing[formDataType]?.length < row_count) {
    //       for (
    //         let i = settableCompanyListing[formDataType].length;
    //         i < row_count;
    //         i++
    //       ) {
    //         settableCompanyListing[formDataType].push({
    //           title: "",
    //           description: "",
    //         });
    //       }
    //     }
    //   }
    // }

    const toast = useToast();
    return {
      // subscription_status,
      // days_until,
      // chosenProductLink: this.$store.state.subscriptionPreference.chosenProduct,
      companyListing: null,
      modalIsActive: false,
      toast,
      imageFiles: [],
      showPreviewModal: false,
    };
  },
  computed: {
    orgProfile() {
      let profile = this.$store.state.orgs.org_profile;
      return profile;
    },
    org() {
      return this.$store.getters.getCurrentOrg;
    },
    territories() {
      let territories =
        this.$store.state.vendorTerritories.vendorTerritories.map(
          (territory) => {
            return {
              value: territory?.id,
              label: territory?.name,
            };
          },
        );
      if (!territories || territories.length === 0) {
        territories = [];
      }
      return territories;
    },
    subscription() {
      if (this.companyListing?.subscription_tier != null) return true;
      else return false;
    },
    syncTimestamp() {
      return this.$store.state.orgs.org_profile?.pf_sync;
    },
    pfSlug() {
      return this.$store.state.orgs.org_profile?.slug;
    },
  },
  methods: {
    updateProfile(updatedProfile) {
      // console.log("updatedProfile", updatedProfile);
      this.companyListing = updatedProfile;
    },
    updateImageFiles(updatedImages) {
      // console.log("updatedImages", updatedImages);
      this.imageFiles = updatedImages;
    },
    async saveAndPreviewCompanyListing() {
      this.companyListing.name = this.$store.getters.getCurrentOrg.name; // Need a catch for no org name?
      this.companyListing.header = this.$store.getters.getCurrentOrg.name;
      delete this.companyListing.org;
      // delete this.companyListing.logo_url;
      delete this.companyListing.banner_url;
      delete this.companyListing.updated;
      delete this.companyListing.created;

      await this.submitCompanyListing("", false);
      // Set the company listing to the updated listing from the backend
      this.companyListing = this.$store.state.orgs.org_profile;
      // now save any resource images to the profile
      for (let i = 0; i < this.companyListing.resources_list.length; i++) {
        // console.log("saving image", i);
        if (this.imageFiles[i]) await this.uploadResourceImage(i);
        // console.log("done saving image", i);
      }
      if (!this.$store.getters.getApiError) {
        this.toast.success("Listing Saved!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },
    // Form Methods
    async submitCompanyListing(notice, pushRoute = false) {
      await this.$store.dispatch("createOrgProfile", this.companyListing);
      this.companyListing = this.$store.state.orgs.org_profile;
      if (!this.$store.getters.getApiError) {
        if (notice.length > 0) {
          this.toast.info(notice, {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      }

      if (!this.$store.getters.getApiError && pushRoute) {
        this.$router.push({ name: "vendor-company-listing" });
      }
    },
    async uploadCompanyListingLogo(file) {
      let response = await this.$store.dispatch("uploadOrgProfileLogo", file);
      if (!this.$store.getters.getApiError) {
        // console.log("logo uploaded");
        // console.log(response);
      }
      this.companyListing.logo_url = response.logo_url;
    },
    async uploadCompanyListingBanner(file) {
      let response = await this.$store.dispatch("uploadOrgProfileBanner", file);
      if (!this.$store.getters.getApiError) {
        // console.log("banner uploaded");
        // console.log(response);
      }
      this.companyListing.banner_url = response.image;
    },
    async uploadResourceImage(index) {
      let response = await this.$store.dispatch(
        "uploadOrgProfileResourceImage",
        {
          image: this.imageFiles[index],
          index: index,
        },
      );
      if (!this.$store.getters.getApiError) {
        // console.log("Resource Image Uploaded");
        // console.log(response);
      }
      this.companyListing.resources_list[index].image_url = response.image_url;
    },
    async saveFeatures() {
      this.saveAndPreviewCompanyListing();
    },
    async saveFaq() {
      this.saveAndPreviewCompanyListing();
    },
    async saveBenefits() {
      this.saveAndPreviewCompanyListing();
    },
    // async saveResources({ imageUploadCallback }) {
    //   // this.companyListing.resources_list =
    //   //   this.CompanyListingStore.data.resources;
    //   await this.saveAndPreviewCompanyListing(false);
    //   await imageUploadCallback();
    //   this.$store.dispatch("syncOrgProfile");
    // },
    async saveResources() {
      await this.saveAndPreviewCompanyListing();
    },
    async publishCompanyListing() {
      let publishNowDate = new Date().toISOString();
      this.companyListing.available_on = publishNowDate;
      this.submitCompanyListing("Listing Published!");
    },
    async delistCompanyListing() {
      this.companyListing.available_on = null;
      this.submitCompanyListing("Listing De-Listed!");
    },
  },
  watch: {
    // orgProfile() {
    //   // console.log("org profile changed");
    //   if (this.orgProfile) this.companyListing = this.orgProfile;
    // },
    orgProfile: {
      handler(newVal) {
        if (this.orgProfile != null) {
          if (newVal?.created) {
            this.companyListing = newVal;
            console.log("org profile changed", newVal, this.companyListing);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
