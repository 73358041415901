<template>
  <div class="bg-white" id="pageDiv">
    <div class="col-span-6 flex justify-end print:hidden">
      <base-button
        buttonText="Print"
        :marginLeft="false"
        @buttonClick="printReport"
      />
      <!-- <base-button
        buttonText="Download"
        :marginLeft="true"
        @buttonClick="saveCSV"
      /> -->
      <base-button
        buttonText="Download"
        :marginLeft="true"
        @buttonClick="exportXLSX"
      />
      <!-- <base-button
        buttonText="Test"
        :marginLeft="true"
        @buttonClick="exportRecordSheet"
      /> -->
    </div>
    <span class="text-2xl font-bold uppercase">CONTRACT</span>

    :&nbsp;
    <span class="text-2xl font-bold">{{ contract?.contract_name }}</span>
    <div class="flex">
      <div class="w-1/2 pl-4 pt-4">
        <div v-if="contract?.client_name?.length > 0">
          Buyer: {{ contract?.client_name }}
        </div>
        Goal: {{ contract?.quantity?.toLocaleString() }}
        {{ contract?.quantity_units }} of
        {{ contract?.crop }}
        <br />
        Contracted Quantity: {{ orderQuantity?.toLocaleString() }}
        {{ contract?.quantity_units }} of
        {{ contract?.crop }}
        <br />
        Premium(s):
        <div v-for="(premium, idx) in premiums" :key="idx">
          {{ premium.summary }} if {{ premium.terms }}
        </div>
      </div>
      <div class="w-96 pl-4">
        <apexchart
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div class="pt-8"></div>
    <hr />
    <span class="text-xl font-bold uppercase">Order Summary</span>
    <div class="pl-4">
      <div>
        <div v-for="(order, idx) in orders" :key="idx">
          <div v-if="order?.buyer_signature && order?.grower_signature">
            {{ order?.quantity }} {{ contract?.quantity_units }} of
            {{ contract?.crop }} from {{ order?.seller_details?.name }} on order
            #
            {{ order?.id }}
            <div class="pl-4">
              <span class="text-l font-bold">Deliveries:</span>

              <div
                v-for="(delivery, idx) in deliveries?.filter(
                  (delivery) => delivery.crop_order == order.id,
                )"
                :key="idx"
                class="pl-4"
              >
                {{ delivery.quantity_delivered }}
                {{ delivery.quantity_units }}
                <!--  delivered on
                {{ delivery.updated.toLocaleString("en", dateFormat) }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="!print:break-before-page pt-8"></div>
    <hr />
    <span class="text-xl font-bold uppercase">Grower Summary</span>
    <div class="pl-4">
      <div v-for="(order, idx) in orders" :key="idx" class="">
        <span class="text-xl font-bold">{{ order.seller_details?.name }}</span>
        <span class="pl-4 text-xs"
          >{{ order.seller_details?.contact_phone }}&nbsp;&nbsp;
          {{ order.seller_details?.contact_email }}</span
        >
        <div
          v-for="(allocation, idx) in getAllocationsByOrder(order)"
          :key="idx"
        >
          <div class="flex pl-4">
            Field
            {{ allocation.shared_data?.field_details?.properties?.name }}
            ( {{ allocation.shared_data?.field_allocated_acres }} acres )
            <div v-if="allocation.shared_data?.crop_details?.crop_type">
              &nbsp;of
              {{ allocation.shared_data?.crop_details?.crop_type }}
            </div>
          </div>
          <div class="pl-8">
            <span class="text-l font-bold">Records:</span>
            <table>
              <tr
                v-for="(
                  record, idx
                ) in allocation.shared_data?.record_details?.filter(
                  (record) => record.category != 'NDVI',
                )"
                :key="idx"
              >
                <td class="pt-8 align-top">
                  {{ record.category }}
                </td>
                <td>
                  <record-form
                    :recordProp="record"
                    :displayOnly="true"
                    :recordType="record.category"
                    :print="true"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { summarizePremium } from "@/components/composables/scriptUtils";
import { tonsToBushels } from "@/components/composables/convertUnits";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VueApexCharts from "vue3-apexcharts";
// import { exportToCSV } from "@/components/composables/exportFile";
import { flattenObj } from "@/components/composables/scriptUtils";
import { writeFileXLSX, utils } from "xlsx";
import { exportToShapefile } from "@/components/composables/exportFile";
import _ from "lodash";

const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    RecordForm,
    apexchart: VueApexCharts,
    BaseButton,
  },
  data() {
    return {
      // Metadata
      recordType: null,
      selectedFields: [],
      // Submit
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
    };
  },
  computed: {
    contract() {
      return this.$store.getters.getContractById(this.$route.params.contractId);
    },
    premiums() {
      let premiums = [];
      if (!this.contract) return premiums;
      let contractPremiums = this.contract.premiums;
      if (!contractPremiums) return premiums;
      // console.log(
      //   "contractPremiums",
      //   contractPremiums,
      //   typeof contractPremiums,
      // );
      if (typeof contractPremiums == "string") {
        contractPremiums = JSON.parse(contractPremiums);
      }
      for (let premium of contractPremiums) {
        premiums.push({
          ...premium,
          summary: summarizePremium(premium),
        });
      }
      return premiums;
    },
    offers() {
      let filter = {
        buyer_contract: parseInt(this.$route.params.contractId),
      };
      // console.log("filter", filter);
      return this.$store.getters.getDerivedControllerMarketOffers({
        filter: filter,
      });
    },
    orders() {
      let allOrders = this.$store.getters.getEnrichedControllerOrders({});
      let orders = [];
      if (!this.contract) return orders;
      for (let order of allOrders) {
        let offer = this.offers.find((offer) => offer.id == order.offer);
        if (
          offer &&
          order.buyer_signature &&
          order.grower_signature &&
          offer.buyer_contract === this.contract.id
        )
          orders.push(order);
      }
      return orders;
    },
    orderQuantity() {
      let quantity = 0;
      for (let order of this.orders) {
        quantity += order.quantity;
      }
      return quantity;
    },
    deliveries() {
      let deliveries = [];
      for (let order of this.orders) {
        for (let delivery of this.$store.getters.getControllerDeliveriesByOrder(
          order.id,
        )) {
          deliveries.push(delivery);
        }
      }
      return deliveries;
    },
    deliveredQuantity() {
      let quantity = 0;
      for (let delivery of this.deliveries) {
        quantity += delivery.quantity_delivered;
      }
      return quantity;
    },
    deliveredUnits() {
      let units = "";
      for (let delivery of this.deliveries) {
        let deliveryUnit = delivery.quantity_units;
        if (deliveryUnit.toLowerCase().indexOf("bushel") > -1)
          deliveryUnit = "bu";
        // console.log(
        //   "deliveryUnit",
        //   deliveryUnit,
        //   deliveryUnit.toLowerCase().indexOf("bushel"),
        // );
        if (units.indexOf(deliveryUnit) == -1) {
          if (units.length > 0) units += ", ";
          units += deliveryUnit;
        }
      }
      return units;
    },
    deliveredPct() {
      if (!this.contract?.quantity) return 0;
      if (this.contract.quantity_units === "acres") {
        return -1;
      }
      return (this.deliveredQuantity / this.contract.quantity) * 100;
    },
    allocations() {
      let allocations = this.$store.getters.getAllControllerAllocations;
      let filteredAllocations = [];
      for (let order of this.orders) {
        for (let allocation of allocations) {
          if (allocation.crop_order === order.id) {
            filteredAllocations.push(allocation);
          }
        }
      }
      return filteredAllocations;
    },
    allocatedQuantity() {
      let quantity = 0;
      if (!this.contract?.quantity) return 0;
      for (let order of this.orders) {
        for (let allocation of this.getAllocationsByOrder(order)) {
          if (this.contract.quantity_units === "acres") {
            quantity += allocation.acres;
          } else {
            if (
              allocation.shared_data?.crop_details?.crop_type &&
              allocation.shared_data?.crop_details?.details?.tpa
            ) {
              let qpa = tonsToBushels(
                allocation.shared_data?.crop_details?.crop_type,
                allocation.shared_data?.crop_details?.details?.tpa,
              );
              quantity += allocation.shared_data?.field_allocated_acres * qpa;
            }
          }
        }
      }
      return quantity;
    },
    allocatedPct() {
      if (!this.contract?.quantity) return 0;
      return (this.allocatedQuantity / this.contract.quantity) * 100;
    },
    orderedPct() {
      if (!this.contract?.quantity) return 0;
      return (this.orderQuantity / this.contract.quantity) * 100;
    },

    labels() {
      return ["100% Ordered", "55% Allocated", "67% Delivered"];
    },
    series() {
      let series = [this.orderedPct, this.allocatedPct];
      if (this.deliveredPct > 0) series.push(this.deliveredPct);
      return series;
    },
    chartOptions() {
      let labels = [
        this.orderedPct.toFixed(0) + "% Ordered",
        this.allocatedPct.toFixed(0) + "% Allocated",
      ];
      if (this.deliveredPct > 0)
        labels.push(this.deliveredPct.toFixed(0) + "% Delivered");

      return {
        colors: ["#009aa6", "#c67bc4", "#65f057"],
        chart: {
          height: 350,
          type: "radialBar",
        },
        legend: {
          show: true,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 10,
              size: "30%",
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "16px",
              },
              // value: {
              //   show: true,
              //   fontSize: "12px",
              // },
            },
          },
        },
        labels: labels,
        // labels: ["white", "white", "white"],
      };
    },
    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      // console.log("recordTypes", recordTypes);
      recordTypes = recordTypes.map((recordType) => {
        return recordType.category;
      });
      // remove the recordType of category NDVI
      recordTypes = recordTypes.filter((recordType) => recordType !== "NDVI");
      return recordTypes;
    },

    allPremiums() {
      return this.$store.getters.getControllerCropOrderPremiums;
    },
    growers() {
      return this.$store.getters.getControllerCropOrderGrowers;
    },

    allocationPremiums() {
      if (!this.allPremiums || !this.allocations || !this.orders) return [];
      let allocationPremiums = [];
      for (let allocation of this.allocations) {
        // if allocation.shared_data doesn't exist skip the allocation
        if (!allocation.shared_data) {
          continue;
        }
        let allocationPremium = {};
        allocationPremium.allocation_id = allocation.id;
        allocationPremium.allocation_acres = allocation.acres;
        allocationPremium.field_id = allocation.shared_data.field_details.id;
        allocationPremium.field_name =
          allocation?.shared_data.field_details.properties.name;
        allocationPremium.farm_name =
          allocation?.shared_data.field_details.properties.farm;
        allocationPremium.field_acres =
          allocation?.shared_data.field_details.properties.acres;
        allocationPremium.crop_type =
          allocation?.shared_data.crop_details.crop_type;
        // allocationPremium.crop_details =
        //   allocation?.shared_data.crop_details.details;
        let order = this.orders.find(
          (order) => order.id == allocation.crop_order,
        );
        allocationPremium.order_id = order?.id;
        allocationPremium.order_quantity = order?.quantity;
        allocationPremium.order_units = order?.units;
        allocationPremium.grower_id = order?.seller_details?.id;
        allocationPremium.grower_name = order?.seller_details?.name;
        // if the order has an offer, get the offer details
        // if the order does not have an offer, set the offer_id, contract_id, and contract_name to null

        if (order.offer) {
          let offer = this.offers.find((offer) => offer.id == order.offer);
          let contract = this.$store.getters.getContractById(
            offer.buyer_contract,
          );
          allocationPremium.offer_id = offer.id;
          allocationPremium.assignment_quantity = offer.quantity;
          // allocationPremium.assignment_units = offer.units;
          allocationPremium.contract_crop = contract.crop;
          allocationPremium.contract_id = offer.buyer_contract;
          allocationPremium.contract_description =
            contract.contract_description;
          allocationPremium.contract_name = contract.contract_name;
        } else {
          allocationPremium.offer_id = null;
          allocationPremium.assignment_quantity = null;
          // allocationPremium.assignment_units = null;
          allocationPremium.contract_crop = null;
          allocationPremium.contract_id = null;
          allocationPremium.contract_description = null;
          allocationPremium.contract_name = null;
        }
        // for each premium where premium.order_id matches the allocation.crop_order
        // create a new object that combines the allocationPremium object with the premium object
        // add the new object to the allocationPremiums array
        // console.log("allocation premium", allocationPremium);
        allocationPremiums.push(allocationPremium);

        //NOTE: This is commented out because the premiums are not being used in the report and they are causing the report to load incorrectly
        // let premiums = this.allPremiums.filter(
        //   (premium) => premium.order_id == allocation.crop_order,
        // );

        // for (let premium of premiums) {
        //   console.log("premium", premium, allocationPremium);
        //   // reverse the order of the keys within allocationPremium so that the first key is assignment_units
        //   // this is the order that the keys are in the premium object

        //   let allocationPremiumKeys = Object.keys(allocationPremium).reverse();
        //   let allocationPremiumReversed = {};
        //   for (let key of allocationPremiumKeys) {
        //     allocationPremiumReversed[key] = allocationPremium[key];
        //   }
        //   let allocationPremiumWithPremium = {
        //     ...allocationPremiumReversed,
        //     premium_id: premium.id,
        //     premium_type: premium.type,
        //     premium_terms: premium.terms,
        //   };
        //   allocationPremiums.push(allocationPremiumWithPremium);
        // }
      }
      return allocationPremiums;
    },
    recordsForExport() {
      let records = [];
      for (let allocation of this.allocations) {
        if (allocation.shared_data.record_details) {
          for (let record of allocation.shared_data.record_details) {
            // loop through the record payload and add the details to the record object at the top level
            record.allocation_id = allocation.id;
            record.field_id = allocation.shared_data.field_details.id;
            record.field_name =
              allocation.shared_data.field_details.properties.name;
            record.field_farm =
              allocation.shared_data.field_details.properties.farm;
            record.crop_type = allocation.shared_data.crop_type;
            record.order_id = allocation.crop_order;
            let order = this.orders.find(
              (order) => order.id == record.order_id,
            );
            record.grower_id = order.seller_details.id;
            record.grower_name = order.seller_details.name;
            if (order.offer) {
              let offer = this.offers.find((offer) => offer.id == order.offer);
              record.offer_id = offer.id;
              record.contract_id = offer.buyer_contract;
              record.contract_name = offer.contract_name;
            } else {
              record.offer_id = null;
              record.contract_id = null;
              record.contract_name = null;
            }
            records.push(record);
          }
        }
      }
      // for each item in this.recordTypes, create an array for that recordType
      // each record in records, add the record to the array for the recordType == record.category
      // return the array of arrays
      let recordsForExport = [];
      for (let recordType of this.recordTypes) {
        let recordsOfType = records.filter(
          (record) => record.category == recordType,
        );
        recordsForExport.push(recordsOfType);
      }
      // remove any arrays that are length 0
      recordsForExport = recordsForExport.filter(
        (recordType) => recordType.length > 0,
      );
      return recordsForExport;
    },
    deliveriesForExport() {
      let deliveries = [];
      for (let delivery of this.deliveries) {
        let order = this.orders.find(
          (order) => order.id == delivery.crop_order,
        );
        delivery.grower_id = order.seller_details.id;
        delivery.grower_name = order.seller_details.name;
        if (order.offer) {
          let offer = this.offers.find((offer) => offer.id == order.offer);
          delivery.offer_id = offer.id;
          delivery.contract_id = offer.buyer_contract;
          delivery.contract_name = offer.contract_name;
        } else {
          delivery.offer_id = null;
          delivery.contract_id = null;
          delivery.contract_name = null;
        }
        deliveries.push(delivery);
      }
      return deliveries;
    },
  },
  methods: {
    getAllocationsByOrder(order) {
      return this.allocations.filter((allocation) => {
        return allocation.crop_order === order.id;
      });
    },
    printReport() {
      var tempTitle = document.title;
      document.title = this.contract.contract_name + ".pdf";
      window.print();
      document.title = tempTitle;
    },
    // async saveCSV() {
    //   console.log("saveCSV");
    //   this.exportAllocationPremiums();
    //   await new Promise((callback) => setTimeout(callback, 100));
    //   this.exportRecords();
    //   await new Promise((callback) => setTimeout(callback, 100));
    //   this.exportDeliveries();
    // },
    // exportAllocationPremiums() {
    //   exportToCSV(this.allocationPremiums, "premiums.csv");
    // },
    // async exportRecords() {
    //   // for each of the recordType arrays in this.recordsForExport's array of arrays
    //   // let the record equal the record object and ...record.payload
    //   // and exportToCsv(recordTypeArray, recordType + ".csv")
    //   for (let recordTypeArray of this.recordsForExport) {
    //     let recordType = "Parcel_Records_" + recordTypeArray[0].category;
    //     let records = recordTypeArray.map((record) => {
    //       // remove the original payload object and replace it with the payload object's properties
    //       let recordPayload = record.payload;
    //       delete record.payload;

    //       return { ...record, ...recordPayload };
    //     });
    //     exportToCSV(records, recordType + ".csv");
    //     // timeout to allow multiple file downloads in safari
    //     await new Promise((callback) => setTimeout(callback, 100));
    //   }
    // },
    // exportDeliveries() {
    //   exportToCSV(this.deliveriesForExport, "deliveries.csv");
    // },

    exportRecordSheet() {
      // flatten the record and its payload to a single object
      let recordSet = [];
      for (let recordTypeArray of structuredClone(this.recordsForExport)) {
        let recordType = recordTypeArray[0].category;
        let records = [];
        for (let record of recordTypeArray) {
          let payload = flattenObj(record.payload, true);
          // console.log("flattened payload", payload);
          for (let key in payload) {
            if (Array.isArray(payload[key])) {
              for (let i = 0; i < payload[key].length; i++) {
                let repeatedSection = payload[key][i];
                // console.log("repeatedSection", repeatedSection);
                for (let attribute in repeatedSection) {
                  payload[key + "_" + i + "_" + attribute] =
                    repeatedSection[attribute];
                }
              }
              // let repeatedSection = payload[key];
              // for (let attribute of repeatedSection) {
              //   for (let mixKey in attribute) {
              //     payload[key + "_" + mixKey] = attribute[mixKey];
              //   }
              // }
              delete payload[key];
            }
          }
          delete record.payload;
          let recordFlat = flattenObj(record, false);
          if (Object.keys(payload).length > 0) _.merge(recordFlat, payload);
          records.push(recordFlat);
        }

        recordSet.push({ type: recordType, records: records });
      }
      // console.log("recordSet", recordSet);
      return recordSet;
    },
    async exportXLSX() {
      let wb = utils.book_new();
      let ws = utils.json_to_sheet(this.allocationPremiums);
      utils.book_append_sheet(wb, ws, "Orders");
      let recordSet = this.exportRecordSheet();
      for (let recordType of recordSet) {
        // console.log("recordType", recordType);
        ws = utils.json_to_sheet(recordType.records);
        utils.book_append_sheet(wb, ws, recordType.type);
      }
      ws = utils.json_to_sheet(this.deliveriesForExport);
      utils.book_append_sheet(wb, ws, "Deliveries");
      writeFileXLSX(wb, this.contract.contract_name + ".xlsx");
      await new Promise((callback) => setTimeout(callback, 100));
      this.exportShapeFile();
    },
    exportShapeFile() {
      let fields = [];
      for (let allocation of this.allocations) {
        if (allocation.shared_data?.field_details) {
          let field = allocation.shared_data.field_details;
          let order = this.orders.find(
            (order) => order.id == allocation.crop_order,
          );
          field.properties.grower = order.seller_details.name;
          if (allocation.shared_data?.crop_details) {
            field.properties.current_crop = allocation.shared_data.crop_details;
          }
          fields.push(field);
        }
      }
      // console.log("fields", fields);
      let fileName = this.contract.contract_name + "_shapefile.zip";
      exportToShapefile(fields, fileName);
    },
  },
};
</script>
