<template>
  <error-notification />
  <div
    class="flex h-screen overflow-hidden bg-gray-100 pt-5 sm:pt-0 print:overflow-visible"
  >
    <side-menu
      settingsPath="grower-portal-settings"
      homePath="grower-portal-home"
      orgPath="switch-org"
      :navigation="appNavigation"
      :showSideBar="showSideBar"
      @sidebarOpen="(open) => (this.showSideBar = open)"
    />
    <!-- main page area -->
    <div class="flex w-0 flex-1 flex-col">
      <div
        class="relative z-10 flex h-20 flex-shrink-0 bg-white shadow print:hidden"
      >
        <!-- App top bar -->
        <grower-portal-frame-page-header
          homeRouteName="grower-portal-home"
          :solutions="solutions"
          @clickSidebarMenu="this.showSideBar = true"
        />
      </div>
      <!-- main content area -->
      <main
        class="relative flex-1 overflow-y-auto focus:outline-none print:overflow-y-visible"
        id="main-content"
      >
        <div class="py-6 print:py-0">
          <div class="mx-auto max-w-full px-4 sm:px-6 md:px-8">
            <!-- Router View Page Content -->
            <ActionCardProcess
              v-if="activeGrowerActionsProcess"
              :title="activeGrowerActionsProcess.title"
              :description="activeProcessDescription"
              @closeProcess="removeActiveProcess"
            >
              <template #gridItems>
                <ActionCard
                  v-for="(action, index) in activeGrowerActionsProcess.actions"
                  :key="action.routeObject"
                  :title="action.title"
                  :routeObject="action.routeObject"
                  :tags="action.tags"
                  :notificationCount="
                    $store.getters.actionsGrowerNotificationFactory(
                      action.notificationType,
                    )
                  "
                  :stepGreen="action.active"
                  :stepNumber="index + 1"
                  @clickOnAction="activateAction(index)"
                />
              </template>
            </ActionCardProcess>
            <router-view></router-view>

            <!-- <router-view v-slot="{ Component }">
              <keep-alive
                :include="[
                  // 'FieldsDashboard',
                  // 'EquipmentDashboard',
                  // 'Activities',
                  // 'FieldPlans',
                  // 'ProduceReports',
                  // 'GrowerPortalProduceOrders',
                ]"
              > 
              <component :is="Component" />
               </keep-alive> 
            </router-view> -->
            <!-- /End page content -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import GrowerPortalFramePageHeader from "@/layouts/grower-portal/GrowerPortalFramePageHeader.vue";
// import { ShoppingCartIcon } from "@heroicons/vue/outline";
import SideMenu from "@/layouts/common/SideMenu.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";
import ActionCard from "@/components/cards/ActionCard.vue";
import ActionCardProcess from "@/components/cards/ActionCardProcess.vue";

const solutions = [];

export default {
  components: {
    GrowerPortalFramePageHeader,
    SideMenu,
    ErrorNotification,
    ActionCard,
    ActionCardProcess,
  },
  setup() {
    return {
      solutions,
    };
  },
  data() {
    return {
      // For whatever reason :src="parcelLogoPath" didn't work so the img path is hard coded for now
      // parcelLogoPath: "../../assets/parcel-logo.svg",
      showSideBar: false,
      homeLabel: "Home",
      needsActionFilter: function (o) {
        if (o.status === "seller_responded") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  methods: {
    activateAction(index) {
      this.$store.dispatch("setActiveProcessGrowerActionToActive", index);
    },
    removeActiveProcess() {
      this.$store.dispatch("clearGrowerActionsActiveProcess");
    },
  },
  computed: {
    activeGrowerActionsProcess() {
      return this.$store.getters.getGrowerActionsActiveProcess;
    },
    activeProcessDescription() {
      if (this.$store.getters.getGrowerActionsActiveProcessActiveAction) {
        return this.$store.getters.getGrowerActionsActiveProcessActiveAction
          .description;
      } else {
        return this.activeGrowerActionsProcess.description;
      }
    },

    needsActionQuotes() {
      let numQuotes = this.$store.getters.getNeedsActionGrowerQuotes.length;
      let numInputQuotes = this.$store.getters.getGrowerInputOrders?.filter(
        this.needsActionFilter,
      )?.length;
      if (numInputQuotes) {
        numQuotes += numInputQuotes;
      }
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
    needsActionRecommendations() {
      let opps = this.$store.getters
        .getEnrichedGrowerOpportunities({})
        .filter((opp) => {
          return opp.status === "Seller Submitted";
        });
      let recs = this.$store.getters
        .getEnrichedGrowerRecommendations({})
        .filter((rec) => {
          return rec.status === "Seller Submitted";
        });
      let count = opps.length + recs.length;
      return count;
    },
    needsActionInquiries() {
      let numInquiries = this.$store.getters.getPartnerInquiries.length;
      if (numInquiries > 0) {
        return numInquiries;
      } else {
        return null;
      }
    },
    totalInvoices() {
      return this.$store.state.growerInvoices.growerInvoices?.length;
    },
    totalVendors() {
      return this.$store.state.vendors.vendors?.length;
    },
    totalRecommendations() {
      return (
        this.$store.getters.getEnrichedGrowerRecommendations({}).length +
        this.$store.getters.getEnrichedGrowerOpportunities({})?.length
      );
    },

    appNavigation() {
      let myOrg = this.$store.getters.getCurrentOrg; // eslint-disable-line
      let appNavigation = { sections: [] };
      appNavigation.sections.push(
        // appHeading: "Parcel",
        {
          sectionHeading: this.homeLabel,
          featureFlagForAccess: "allow",
          expandable: false,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Dashboard",
              routeName: "grower-portal-home",
              icon: "TemplateIcon",
              featureFlagForAccess: "allow",
            },
          ],
        },
      );
      appNavigation.sections.push({
        sectionHeading: "Buy",
        featureFlagForAccess: "gp-buy",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Shop",
            routeName: "grower-portal-discover",
            icon: "ShoppingCartIcon",
            featureFlagForAccess: "allow",
          },
          // {
          //   id: 2,
          //   name: "Recommendations",
          //   routeName: "grower-recommendations",
          //   icon: "ClipboardListIcon",
          //   featureFlagForAccess: "allow",
          //   alerts: {
          //     count: this.needsActionRecommendations,
          //     color: "text-white bg-gray-500",
          //   },
          // },
          {
            id: 4,
            name: "Quotes",
            routeName: "grower-portal-quotes-all",
            icon: "QuestionMarkCircleIcon",
            featureFlagForAccess: "gp-buy",
            alerts: {
              count: this.needsActionQuotes,
              color: "text-white bg-gray-500",
            },
          },
          {
            id: 3,
            name: "Purchases",
            routeName: "grower-portal-buy-orders-all",
            icon: "QuestionMarkCircleIcon",
            featureFlagForAccess: "gp-buy",
          },
          {
            id: 4,
            name: "Hauling",
            routeName: "grower-portal-hauling-dashboard",
            icon: "TruckIcon",
            featureFlagForAccess: "gp-hauling",
          },
          //   {
          //   id: 5,
          //   name: "Vendors",
          //   routeName: "vendors",
          //   icon: "UsersIcon",
          //   featureFlagForAccess: "gp-vendors",
          // },
          //   {
          //     id: 6,
          //     name: "Invoices",
          //     routeName: "grower-invoices",
          //     icon: "CashIcon",
          //     featureFlagForAccess: "allow",
          //   },
        ],
      });
      if (this.totalRecommendations > 0) {
        appNavigation.sections[
          appNavigation.sections.length - 1
        ].sectionNavigation.push({
          id: 2,
          name: "Recommendations",
          routeName: "grower-recommendations",
          icon: "ClipboardListIcon",
          featureFlagForAccess: "allow",
          alerts: {
            count: this.needsActionRecommendations,
            color: "text-white bg-gray-500",
          },
        });
      }
      if (this.totalVendors > 0) {
        appNavigation.sections[
          appNavigation.sections.length - 1
        ].sectionNavigation.push({
          id: 5,
          name: "Vendors",
          routeName: "vendors",
          icon: "UsersIcon",
          featureFlagForAccess: "gp-vendors",
        });
      }
      if (this.totalInvoices > 0) {
        appNavigation.sections[
          appNavigation.sections.length - 1
        ].sectionNavigation.push({
          id: 6,
          name: "Invoices",
          routeName: "grower-invoices",
          icon: "CashIcon",
          featureFlagForAccess: "allow",
        });
      }
      appNavigation.sections.push({
        sectionHeading: "Assets",
        featureFlagForAccess: "allow",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Fields",
            routeName: "fields",
            icon: "TemplateIcon",
            featureFlagForAccess: "allow",
          },
          {
            id: 2,
            name: "Equipment",
            routeName: "equipment",
            icon: "CubeIcon",
            featureFlagForAccess: "allow",
          },
          {
            id: 3,
            name: "Records",
            routeName: "records",
            icon: "ClipboardListIcon",
            featureFlagForAccess: "allow",
          },
          // {
          //   id: 4,
          //   name: "Map",
          //   routeName: "grower-portal-map",
          //   icon: "MapIcon",
          //   featureFlagForAccess: "allow",
          // },
        ],
      });
      appNavigation.sections.push({
        sectionHeading: "Operations",
        featureFlagForAccess: "gp-fms",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Plans",
            routeName: "field-plans",
            icon: "ClipboardListIcon",
            featureFlagForAccess: "allow",
          },
          {
            id: 1,
            name: "Activities",
            routeName: "activities",
            icon: "ClipboardListIcon",
            featureFlagForAccess: "allow",
          },
          // {
          //   id: 2,
          //   name: "Recommendations",
          //   routeName: "recommendations",
          //   icon: "ClipboardListIcon",
          //   featureFlagForAccess: "gp-recs",
          // },
          {
            id: 3,
            name: "Discussions",
            routeName: "chat",
            icon: "ChatAlt2Icon",
            featureFlagForAccess: "gp-chat",
          },
        ],
      });
      appNavigation.sections.push({
        sectionHeading: "Sell",
        featureFlagForAccess: "gp-sell",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Orders",
            routeName: "grower-portal-produce-orders",
            icon: "ArchiveIcon",
            featureFlagForAccess: "gp-sell",
          },
          {
            id: 2,
            name: "Inquries",
            routeName: "grower-portal-produce-inquiries",
            icon: "QuestionMarkCircleIcon",
            featureFlagForAccess: "gp-sell",
            alerts: {
              count: this.needsActionInquiries,
              color: "text-white bg-gray-500",
            },
          },
          {
            id: 3,
            name: "Offers",
            routeName: "grower-portal-market-offers",
            icon: "ArchiveIcon",
            featureFlagForAccess: "gp-sell",
          },
          {
            id: 4,
            name: "Listings",
            routeName: "grower-portal-produce-listings",
            icon: "CurrencyDollarIcon",
            featureFlagForAccess: "gp-sell",
          },
          {
            id: 5,
            name: "Carbon",
            routeName: "carbon",
            icon: "PresentationChartLineIcon",
            featureFlagForAccess: "gp-carbon",
          },
          {
            id: 6,
            name: "Allocations Report",
            routeName: "grower-portal-produce-allocations",
            icon: "ChartBarIcon",
            featureFlagForAccess: "gp-sell",
          },
          {
            id: 7,
            name: "Customers",
            routeName: "grower-portal-customers",
            icon: "UsersIcon",
            featureFlagForAccess: "gp-sell",
          },
          // {
          //   id: 10,
          //   name: "Produce",
          //   routeName: "grower-portal-produce-dashboard",
          //   icon: "CurrencyDollarIcon",
          //   featureFlagForAccess: "gp-sell",
          // },
        ],
      });
      return appNavigation;
    },

    // navigation() {
    //   return appNavigation;
    // },
  },
};
</script>
