import ky from "ky";
import store from "@/store/index.js";

let responseHooks = {
  afterResponse: [
    async (request, _, response) => {
      if (response.status === 422) {
        throw new Error([await request.text(), await response.text()]);
      }
    },
  ],
};

let apiFetch = ky.create({
  prefixUrl: (process.env.VUE_APP_API_URL ?? "") + "/v1",
  credentials: "include",
  hooks: responseHooks,
  timeout: 30000,
});

let authFetch = ky.create({
  prefixUrl: (process.env.VUE_APP_API_URL ?? "") + "/auth",
  credentials: "include",
  hooks: responseHooks,
});

export let exceptionHandler;

export const setExceptionHandler = (handler) => {
  exceptionHandler = handler;
};

const httpMethod = async (method, url, opts) => {
  try {
    return await method(url, opts).json();
  } catch (error) {
    if (process.env.VUE_APP_ENV === "development") {
      console.log(error);
    }
    if (exceptionHandler) {
      exceptionHandler(error);
    }
    store.dispatch("catchParcelApiError", error);
  }
};

//AXIOS Calls
const httpGET =
  (url, fetcher = apiFetch) =>
  async (id, params) => {
    return await httpMethod(fetcher.get, url.replace("{id}", id), {
      searchParams: params,
    });
  };

const httpPOST =
  (url, fetcher = apiFetch) =>
  async (id, body, params) => {
    return await httpMethod(fetcher.post, url.replace("{id}", id), {
      searchParams: params,
      json: body,
    });
  };

const httpFilePOST =
  (url, formAttribute = "image", fetcher = apiFetch) =>
  async (id, file) => {
    const formData = new FormData();
    formData.append(formAttribute, file);
    return await httpMethod(fetcher.post, url.replace("{id}", id), {
      body: formData,
    });
  };

const httpPUT =
  (url, fetcher = apiFetch) =>
  async (id, body, params) => {
    return await httpMethod(fetcher.put, url.replace("{id}", id), {
      searchParams: params,
      json: body,
    });
  };

const httpDELETE =
  (url, fetcher = apiFetch) =>
  async (id, id2, params) => {
    return await httpMethod(
      fetcher.delete,
      url.replace("{id}", id).replace("{id2}", id2),
      {
        searchParams: params,
      },
    );
  };

const client = {
  auth: {
    check: httpGET("login", authFetch),
    login: httpPOST("login", authFetch),
    switch: httpPUT("login", authFetch),
    logout: httpDELETE("login", authFetch),
  },
  fields: {
    read: httpGET("fields"),
    create: httpPOST("fields"),
    update: httpPUT("fields/{id}"),
    delete: httpDELETE("fields/{id}"),
    import: httpFilePOST("fields/geojson", "geojson"),
    transfer: httpPOST("fields/trimble"),
  },
  crops: {
    read: httpGET("crops?active={id}"),
    create: httpPOST("fields/{id}/crops"),
    update: httpPUT("crops/{id}"),
    delete: httpDELETE("crops/{id}"),
  },
  equipment: {
    read: httpGET("equipment"),
    create: httpPOST("equipment"),
    update: httpPUT("equipment/{id}"),
    delete: httpDELETE("equipment/{id}"),
  },
  records: {
    read: httpGET("records"),
    create: httpPOST("records"),
    update: httpPUT("records/{id}"),
    delete: httpDELETE("records/{id}"),
    attachment: {
      upload: httpFilePOST("records/{id}/attachment", "attachment"),
      delete: httpDELETE("records/{id}/attachment"),
    },
    import: httpFilePOST("records/import", "attachment"),
  },
  tasks: {
    read: httpGET("tasks"),
    create: httpPOST("tasks"),
    update: httpPUT("tasks/{id}"),
    delete: httpDELETE("tasks/{id}"),
  },
  orgs: {
    read: httpGET("orgs"),
    create: httpPOST("orgs"),
    update: httpPUT("orgs"),
    createLogo: httpFilePOST("orgs/logo"),
    readProfile: httpGET("orgs/profile"),
    createProfile: httpPOST("orgs/profile"),
    createProfileLogo: httpFilePOST("orgs/profile/logo"),
    createProfileBanner: httpFilePOST("orgs/profile/banner"),
    createResourceImage: httpFilePOST("orgs/profile/resources/{id}"),
    deleteResourceImage: httpDELETE("orgs/profile/resources/{id}"),
    readSharedOrgs: httpGET("shared_orgs"),
    readExternalOrgs: httpGET("external_orgs"),
    createExternalOrg: httpPOST("external_orgs"),
    updateExternalOrg: httpPUT("external_orgs/{id}"),
    deleteExternalOrg: httpDELETE("external_orgs/{id}"),
    readAffiliateOrgs: httpGET("affiliate_orgs"),
    createAffiliateOrg: httpPOST("affiliate_orgs"),
    deleteAffiliateOrg: httpDELETE("affiliate_orgs/{id}"),
  },
  companies: {
    read: httpGET("companies"),
    readSeeds: httpGET("companies/directory"),
  },
  integrations: {
    read: httpGET("integrations"),
    create: httpPOST("integrations"),
    update: httpPUT("integrations/{id}"),
    delete: httpDELETE("integrations/{id}"),
    trimbleSetup: httpGET("auth/trimble/redirect"),
    johndeereSetup: httpGET("auth/johndeere/redirect"),
    g3Setup: httpGET("auth/g3/redirect"),
  },
  settings: {
    read: httpGET("settings"),
    update: httpPOST("settings"),
    admin_read: httpGET("settings"),
    admin_update: httpPOST("settings"),
  },
  users: {
    read: httpGET("users"),
    create: httpPOST("users"),
    update: httpPUT("users/{id}"),
    delete: httpDELETE("users/{id}"),
    image: {
      save: httpFilePOST("users/{id}/image"),
      //update: httpPUT("profile/image"),
    },
  },
  threads: {
    messages: {
      read: httpGET("messages"),
      create: httpPOST("threads/{id}/messages"),
      //				update: httpPUT("threads/{id}/messages"),
      //				delete: httpDELETE("threads/{id}/messages"),
    },
    read: httpGET("threads"),
    create: httpPOST("threads"),
    //			update: httpPUT("threads"),
    //			delete: httpDELETE("threads"),
  },
  notifications: {
    read: httpGET("notifications"),
    create: httpPOST("notifications"),
    update: httpPUT("notifications/{id}"),
    delete: httpDELETE("notifications/{id}"),
  },
  profile: {
    createProfileLogo: httpFilePOST("profile/image"),
    read: httpGET("profile"),
    update: httpPUT("profile"),
  },
  customers: {
    read: httpGET("customers"),
    create: httpPOST("customers"),
    update: httpPUT("customers/{id}"),
    delete: httpDELETE("customers/{id}"),
  },
  vendors: {
    read: httpGET("vendors"),
    readOne: httpGET("vendors/{id}"),
    create: httpPOST("vendors"),
    update: httpPUT("vendors/{id}"),
    delete: httpDELETE("vendors/{id}"),
  },
  scenarios: {
    read: httpGET("scenarios"),
    calculate: httpPOST("scenarios/calculate"),
    create: httpPOST("scenarios"),
    update: httpPUT("scenarios/{id}"),
    delete: httpDELETE("scenarios/{id}"),
  },
  buyer: {
    cropListings: {
      read: httpGET("buyer/crop_listings"),
    },
    cropAllocations: {
      read: httpGET("buyer/crop_allocations"),
      readRecords: httpGET("buyer/crop_allocations/records"),
    },
    cropDeliveries: {
      read: httpGET("buyer/crop_deliveries"),
    },
    cropOrders: {
      read: httpGET("buyer/crop_orders"),
      create: httpPOST("buyer/crop_orders"),
      update: httpPUT("buyer/crop_orders/{id}"),
      delete: httpDELETE("buyer/crop_orders/{id}"),
      // createPremium: httpPOST("buyer/crop_orders/{id}/premiums"),
      // updatePremium: httpPUT("buyer/crop_orders/premiums/{id}"),
      // deletePremium: httpDELETE("buyer/crop_orders/premiums/{id}"),
      createAttachment: httpFilePOST(
        "buyer/crop_orders/{id}/attachments",
        "attachment",
      ),
      removeAttachment: httpDELETE("buyer/crop_orders/attachments/{id}"),
    },
    cropVendors: {
      read: httpGET("buyer/crop_vendors"),
      create: httpPOST("buyer/crop_vendors"),
      update: httpPUT("buyer/crop_vendors/{id}"),
    },
    marketOffers: {
      read: httpGET("buyer/market_offers"),
      update: httpPUT("buyer/market_offers/{id}"),
    },
    contractOriginators: {
      read: httpGET("buyer/contract_originators"),
      update: httpPUT("buyer/contract_originators/{id}"),
      delete: httpDELETE("buyer/contract_originators/{id}"),
    },
    cropSupply: {
      read: httpGET("buyer/crops"),
    },
  },
  controller: {
    contracts: {
      read: httpGET("controller/contracts"),
      create: httpPOST("controller/contracts"),
      update: httpPUT("controller/contracts/{id}"),
      delete: httpDELETE("controller/contracts/{id}"),
      createAttachment: httpFilePOST(
        "controller/contracts/{id}/attachments",
        "attachment",
      ),
      deleteAttachment: httpDELETE("controller/contracts/attachments/{id}"),
    },
    contractPremiums: {
      read: httpGET("controller/contract_premiums"),
      create: httpPOST("controller/contracts/{id}/premiums"),
      update: httpPUT("controller/contract_premiums/{id}"),
      delete: httpDELETE("controller/contract_premiums/{id}"),
      createValidationRecord: httpPOST(
        "controller/contract_premiums/{id}/validation_records",
      ), // contract premium id
      removeValidationRecord: httpDELETE("controller/validation_records/{id}"), // validation record id
    },
    marketAffiliates: {
      read: httpGET("controller/market_affiliates"),
      create: httpPOST("controller/market_affiliates"),
      delete: httpDELETE("controller/market_affiliates/{id}"),
    },
    marketOffers: {
      read: httpGET("controller/market_offers"),
      create: httpPOST("controller/market_offers"),
      update: httpPUT("controller/market_offers/{id}"),
      delete: httpDELETE("controller/market_offers/{id}"),
    },
    cropOrders: {
      read: httpGET("controller/crop_orders"),
    },
    cropAllocations: {
      read: httpGET("controller/crop_allocations"),
      readRecords: httpGET("controller/crop_allocations/records"),
    },
    cropDeliveries: {
      read: httpGET("controller/crop_deliveries"),
    },
    summary: {
      readContractsSummary: httpGET("controller/summary/contracts"),
      readOffersSummary: httpGET("controller/summary/market_offers"),
    },
  },
  grower: {
    cropListings: {
      read: httpGET("grower/crop_listings"),
      create: httpPOST("grower/crop_listings"),
      update: httpPUT("grower/crop_listings/{id}"),
      delete: httpDELETE("grower/crop_listings/{id}"),
    },
    cropAllocations: {
      read: httpGET("grower/crop_allocations"),
      create: httpPOST("grower/crop_allocations"),
      update: httpPUT("grower/crop_allocations/{id}"),
      delete: httpDELETE("grower/crop_allocations/{id}"),
      readRecords: httpGET("grower/crop_allocations/records"),
    },
    cropDeliveries: {
      read: httpGET("grower/crop_deliveries"),
      create: httpPOST("grower/crop_deliveries"),
      update: httpPUT("grower/crop_deliveries/{id}"),
      delete: httpDELETE("grower/crop_deliveries/{id}"),
      createAttachment: httpFilePOST(
        "grower/crop_deliveries/{id}/attachments",
        "attachment",
      ),
      deleteAttachment: httpDELETE("grower/crop_deliveries/attachments/{id}"),
    },
    cropOrders: {
      read: httpGET("grower/crop_orders"),
      create: httpPOST("grower/crop_orders"),
      update: httpPUT("grower/crop_orders/{id}"),
      delete: httpDELETE("grower/crop_orders/{id}"),
      createAttachment: httpFilePOST(
        "grower/crop_orders/{id}/attachments",
        "attachment",
      ),
      removeAttachment: httpDELETE("grower/crop_orders/attachments/{id}"),
    },
    cropCustomers: {
      read: httpGET("grower/crop_customers"),
      create: httpPOST("grower/crop_customers"),
      update: httpPUT("grower/crop_customers/{id}"),
    },
    input_agreements: {
      read: httpGET("input_sales/grower/input_agreements"),
      create: httpPOST("input_sales/grower/input_agreements"),
      update: httpPUT("input_sales/grower/input_agreements/{id}"),
    },
    input_listings: {
      read: httpGET("input_sales/grower/input_listings"),
      readUnits: httpGET("input_sales/grower/input_listings/{id}/units"),
    },
    input_units: {
      read: httpGET("input_sales/grower/input_units/{id}"),
    },
    input_requests: {
      read: httpGET("input_sales/grower/input_requests/{id}"),
      searchRequests: httpGET(
        "input_sales/grower/input_requests/search?query={id}",
      ),
    },
    input_orders: {
      read: httpGET("input_sales/grower/input_orders"),
      create: httpPOST("input_sales/grower/input_orders"),
      update: httpPUT("input_sales/grower/input_orders/{id}"),
      delete: httpDELETE("input_sales/grower/input_orders/{id}"),
      createLineItem: httpPOST("input_sales/grower/input_orders/{id}/items"),
      updateLineItem: httpPUT("input_sales/grower/line_items/{id}"),
      deleteLineItem: httpDELETE("input_sales/grower/line_items/{id}"),
    },
    services: {
      read: httpGET("grower/services"),
      readOne: httpGET("grower/services/{id}"),
    },
    serviceAgreements: {
      read: httpGET("grower/service_agreements"),
      create: httpPOST("grower/service_agreements"),
      update: httpPUT("grower/service_agreements/{id}"),
      delete: httpDELETE("grower/service_agreements/{id}"),
    },
    serviceOrders: {
      read: httpGET("grower/service_orders"),
      create: httpPOST("grower/service_orders"),
      update: httpPUT("grower/service_orders/{id}"),
      delete: httpDELETE("grower/service_orders/{id}"),
    },
    serviceOpportunities: {
      read: httpGET("grower/service_opportunities"),
      update: httpPUT("grower/service_opportunities/{id}"),
      delete: httpDELETE("grower/service_opportunities/{id}"),
    },
    serviceRecommendations: {
      read: httpGET("grower/service_recommendations"),
      update: httpPUT("grower/service_recommendations/{id}"),
      delete: httpDELETE("grower/service_recommendations/{id}"),
    },
    serviceLocations: {
      read: httpGET("grower/service_locations"),
      create: httpPOST("grower/service_locations"),
      update: httpPUT("grower/service_locations/{id}"),
      delete: httpDELETE("grower/service_locations/{id}"),
    },
    serviceInvoices: {
      read: httpGET("grower/service_invoices"),
    },
    marketOffers: {
      read: httpGET("grower/market_offers"),
    },
    submissions: {
      createCIBO: httpPOST("submit/cibo"),
    },
  },
  vendor: {
    input_agreements: {
      read: httpGET("input_sales/vendor/input_agreements"),
      update: httpPUT("input_sales/vendor/input_agreements/{id}"),
    },
    input_listings: {
      read: httpGET("input_sales/vendor/input_listings"),
      create: httpPOST("input_sales/vendor/input_listings"),
      update: httpPUT("input_sales/vendor/input_listings/{id}"),
      delete: httpDELETE("input_sales/vendor/input_listings/{id}"),
      readUnits: httpGET("input_sales/vendor/input_listings/{id}/units"),
      createUnit: httpPOST("input_sales/vendor/input_listings/{id}/units"),
      readAllUnits: httpGET("input_sales/vendor/input_units"),
      updateUnit: httpPUT("input_sales/vendor/input_units/{id}"),
      deleteUnit: httpDELETE("input_sales/vendor/input_units/{id}"),
      createInputImage: httpFilePOST(
        "input_sales/vendor/input_listings/{id}/image",
      ),
      deleteInputImage: httpFilePOST(
        "input_sales/vendor/input_listings/{id}/image",
      ),
      createAttachment: httpFilePOST(
        "input_sales/vendor/input_listings/{id}/attachments",
        "attachment",
      ),
      removeAttachment: httpDELETE(
        "input_sales/vendor/input_listings/attachments/{id}",
      ),
    },
    input_orders: {
      read: httpGET("input_sales/vendor/input_orders"),
      update: httpPUT("input_sales/vendor/input_orders/{id}"),
      delete: httpDELETE("input_sales/vendor/input_orders/{id}"),
      createLineItem: httpPOST("input_sales/vendor/input_orders/{id}/items"),
      updateLineItem: httpPUT("input_sales/vendor/line_items/{id}"),
      deleteLineItem: httpDELETE("input_sales/vendor/line_items/{id}"),
    },
    services: {
      read: httpGET("vendor/services"),
      create: httpPOST("vendor/services"),
      update: httpPUT("vendor/services/{id}"),
      delete: httpDELETE("vendor/services/{id}"),
      createServiceImage: httpFilePOST("vendor/services/{id}/image"),
      deleteServiceImage: httpFilePOST("vendor/services/{id}/image"),
      createAttachment: httpFilePOST(
        "vendor/services/{id}/attachment",
        "attachment",
      ),
      removeAttachment: httpDELETE("vendor/services/{id}/attachment/{id2}"),
    },
    serviceAgreements: {
      read: httpGET("vendor/service_agreements"),
      update: httpPUT("vendor/service_agreements/{id}"),
      delete: httpDELETE("vendor/service_agreements/{id}"),
      createAttachment: httpFilePOST(
        "vendor/service_agreements/{id}/attachment",
        "attachment",
      ),
      removeAttachment: httpDELETE(
        "vendor/service_agreements/{id}/attachment/{id2}",
      ),
    },
    serviceOrders: {
      read: httpGET("vendor/service_orders"),
      update: httpPUT("vendor/service_orders/{id}"),
    },
    serviceOpportunities: {
      read: httpGET("vendor/service_opportunities"),
      create: httpPOST("vendor/service_opportunities"),
      update: httpPUT("vendor/service_opportunities/{id}"),
      delete: httpDELETE("vendor/service_opportunities/{id}"),
    },
    serviceRecommendations: {
      read: httpGET("vendor/service_recommendations"),
      create: httpPOST("vendor/service_recommendations"),
      update: httpPUT("vendor/service_recommendations/{id}"),
      delete: httpDELETE("vendor/service_recommendations/{id}"),
    },
    serviceTerritories: {
      read: httpGET("vendor/service_territories"),
      create: httpPOST("vendor/service_territories"),
      update: httpPUT("vendor/service_territories/{id}"),
      delete: httpDELETE("vendor/service_territories/{id}"),
    },
    serviceLocations: {
      read: httpGET("vendor/service_locations/{id}"),
    },
    serviceInvoices: {
      read: httpGET("vendor/service_invoices"),
      create: httpPOST("vendor/service_invoices"),
      delete: httpDELETE("vendor/service_invoices/{id}"),
    },
  },
  shared: {
    scenarios: {
      read: httpGET("shared/scenarios?page_size=500"),
      create: httpPOST("shared/scenarios"),
    },
  },
  stripe: {
    login: httpGET("stripe/login"),
    setup: httpGET("stripe/setup"),
  },
};

export default client;
