<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'id', label: 'Id', filtered: false },
          { name: 'buyer_details.name', label: 'Buyer' },
          { name: 'status', label: 'Status' },
          { name: 'contract_name', label: 'Contract' },

          {
            name: 'quantity',
            label: 'Quantity Ordered',
            filtered: false,
          },
          { name: 'units', label: 'Order Units' },
          {
            name: 'allocationAcres',
            label: 'Allocated Acres',
            filtered: false,
          },
          {
            name: 'estimatedAllocatedQty',
            label: 'Est. Allocated Qty',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="growerCropOrders"
        @update:derived-collection="derivedGrowerCropOrders = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <div class="hidden sm:flex">
              <table-toolbar-button
                buttonText="Import"
                heroIcon="UploadIcon"
                @buttonClick="goToImportOrdersPage"
              />
              <table-toolbar-button
                buttonText="New"
                :usePlusIcon="true"
                @buttonClick="goToNewOrderPage"
              />
            </div>
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="order in derivedGrowerCropOrders" :key="order.id">
        <table-data td-label="Id">
          {{ order.id }}
        </table-data>
        <table-data td-label="Buyer">
          {{ order.buyer_details?.name }}
        </table-data>
        <table-body-cell-pill
          td-label="Status"
          :hiddenOnSmallScreens="true"
          :cellData="order.status"
          :pillColorIndex="order.status === 'In Progress' ? 0 : 1"
        />
        <table-data td-label="Contract Name">
          {{ order.contract_name }}
        </table-data>
        <table-data td-label="Quantity Ordered" class="text-right">
          {{
            order.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
        </table-data>
        <table-data td-label="Order Units">
          {{ order.units }}
        </table-data>
        <table-data td-label="Allocated Acres" class="text-right">
          {{
            order.allocationAcres?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
        </table-data>
        <table-data td-label="Est Allocated Qty">
          {{ order.estimatedAllocatedQty }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="orderPageNavigation(order)"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>
<script>
import { tonsToBushels } from "@/components/composables/convertUnits";

import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableBodyCellPill from "@/components/table/TableBodyCellPill.vue";
export default {
  name: "GrowerPortalProduceOrders",
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    TableBodyCellPill,
  },
  data() {
    return {
      derivedGrowerCropOrders: [],
    };
  },
  computed: {
    growerCropOrders() {
      let derived = this.$store.getters.getGrowerCropOrders(
        ["In Progress", "Internal", "Completed"],
        true,
      );
      // map over derived and add estimatedAllocatedQty as an attribute to each order
      derived = derived.map((order) => {
        return {
          ...order,
          estimatedAllocatedQty: this.estimatedAllocatedQty(order.id),
        };
      });
      // map over derived and add offer.contract_name if order.offer is not null and if this.$store.getters.getGrowerMarketOfferById(order.offer) is not undefined
      derived = derived.map((order) => {
        if (
          order.offer &&
          this.$store.getters.getGrowerMarketOfferById(order.offer)
        ) {
          return {
            ...order,
            contract_name: this.$store.getters.getGrowerMarketOfferById(
              order.offer,
            ).contract_name,
          };
        } else {
          return {
            ...order,
            contract_name: null,
          };
        }
      });
      return derived;
    },
  },
  methods: {
    goToNewOrderPage() {
      this.$router.push({ name: "new-grower-portal-produce-order" });
    },
    goToImportOrdersPage() {
      this.$router.push({ name: "import-orders" });
    },
    // Helper methids for estimated allocated acres
    sumAllocationAcresByOrderId(orderId) {
      return this.$store.getters.getSumAllocationAcresByOrderId(orderId);
    },
    median(values) {
      if (values.length === 0) throw new Error("No inputs");
      values.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) return values[half];
      return (values[half - 1] + values[half]) / 2.0;
    },
    estimatedAllocatedQty(orderId) {
      let tpaEstimates = [];
      let cropType = "";
      let units = this.$store.getters.getGrowerCropOrderById(orderId).units;
      for (let allocation of this.$store.getters.getGrowerAllocationsByOrderId(
        orderId,
      )) {
        let crop = this.$store.getters.getAnyCropById(allocation.crop);
        if (crop?.details?.tpa) {
          tpaEstimates.push(parseFloat(crop?.details?.tpa));
        }
        // console.log("crop", cropType, units);

        if (!cropType.includes(crop?.crop_type)) {
          cropType += crop?.crop_type;
        }
      }
      if (tpaEstimates.length > 0) {
        let tons =
          this.median(tpaEstimates) * this.sumAllocationAcresByOrderId(orderId);
        let qty = tons; // default is estimate in tons
        if (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu") {
          qty = tonsToBushels(cropType, tons);
        }
        return qty.toFixed(1);
      } else {
        return "No Estimate Available";
      }
    },
    orderPageNavigation(order) {
      if (order.offer) {
        return {
          name: "grower-portal-offer-order",
          params: { orderId: order.id },
        };
      } else {
        return {
          name: "grower-portal-produce-order",
          params: { orderId: order.id },
        };
      }
    },
  },
};
</script>
