<template>
  <template v-if="$store.getters.getTaskById($route.params.activityId) == null">
    <page-card headingTitle="Activity could not be found."> </page-card>
  </template>
  <template v-else>
    <delete-modal
      :open="modalIsActive"
      modalTitle="Delete Activity"
      modalDescription="Are you sure you want to permanently delete this activity?"
      @clickCancel="cancelDelete"
      @clickConfirmDelete="deleteTask"
    />
    <page-card
      :headingTitle="$store.getters.getTaskById($route.params.activityId).id"
    >
      <template #buttonArea>
        <submit-button
          buttonText="Save"
          :marginLeft="false"
          :formId="editTaskFormId"
          @submitButtonClick="saveTask"
        />
        <base-button
          buttonText="Delete"
          :redButton="true"
          @buttonClick="intializeDeleteModal"
          dataTestid="delete-activity-button"
        />
      </template>
      <template #cardDetails>
        <activity-form
          :taskId="parseInt($route.params.activityId)"
          :formId="editTaskFormId"
          @save="saveTask"
        />
      </template>
      <template #componentSection>
        <!-- Tabs Below Grid -->
        <TabGroup as="div">
          <div class="border-b border-gray-200">
            <TabList class="-mb-px flex space-x-8">
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Records
                </button>
              </Tab>
              <!-- <feature-flagged requires="gp-suggested-purchases">
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Relevant Products
                  </button>
                </Tab>
              </feature-flagged> -->
            </TabList>
          </div>
          <TabPanels as="template">
            <TabPanel as="dl" class="pt-10" :unmount="false">
              <records-table
                :currentCropId="currentCropId"
                :currentFieldId="currentTask?.field"
                ref="recordsTable"
              />
            </TabPanel>
            <feature-flagged requires="gp-suggested-purchases">
              <TabPanel class="pt-10">
                <!-- <allocations-table :orderId="parseInt($route.params.orderId)" /> -->
                <!-- Purchases Table -->
                <suggested-purchases-table
                  :currentTask="parseInt($route.params.activityId)"
                />
              </TabPanel>
            </feature-flagged>
          </TabPanels>
        </TabGroup>
      </template>
    </page-card>
  </template>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import ActivityForm from "@/layouts/grower-portal/operations/ActivityForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";
import SuggestedPurchasesTable from "@/layouts/grower-portal/operations/SuggestedPurchasesTable.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    SubmitButton,
    ActivityForm,
    DeleteModal,
    RecordsTable,
    SuggestedPurchasesTable,
    FeatureFlagged,
  },
  data() {
    return {
      modalIsActive: false,
      editTaskFormId: "editTask",
    };
  },
  computed: {
    currentTask() {
      return this.$store.getters.getTaskById(this.$route.params.activityId);
    },
    currentCropId() {
      if (!this.currentTask?.field) return null;
      return this.$store.getters.getCropByFieldId(this.currentTask.field)?.id;
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteTask() {
      this.modalIsActive = false;
      // console.log("delete task", this.currentTask);
      await this.$store.dispatch("deleteTask", this.currentTask);
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "activities" });
      }
    },
    async saveTask() {
      // console.log("save task", this.currentTask);
      this.$refs.recordsTable.saveRecords();
    },
  },
};
</script>
