<!-- CropsForm is used for bulk editing of crops, typically for replanting a set of fields. -->
<template>
  <span class="text-xs text-gray-500">
    This form is used for bulk editing of crops, typically for planting a set of
    fields. You can add a new crop to a field or update the existing crop.
    <br />
    Saving this form will make changes to the crops for all the listed fields in
    the table below in a single action.
  </span>
  <div class="-mt-12 mb-8 mr-8 flex justify-end">
    <base-button
      :buttonText="'Save'"
      :marginLeft="false"
      @buttonClick="saveCrops"
    />
  </div>
  <div class="grid grid-cols-1 gap-x-4 gap-y-4 sm:col-span-6 sm:grid-cols-6">
    <crop-form
      v-model:crop="currentCrop"
      :clone="currentCrop"
      :bulk-edit="true"
    />
  </div>
  <div>
    <table-card>
      <template #header>
        <table-head
          :columns="[
            { name: 'properties.farm', label: 'Farm' },
            { name: 'properties.name', label: 'Field', filtered: false },
            { name: 'properties.crop', label: 'Crop' },
            { name: 'properties.details.variety', label: 'Variety' },
            { name: 'action', label: '', filtered: false, sorted: false },
            { name: 'action2', label: '', filtered: false, sorted: false },
          ]"
          :collection="derivedFields"
        >
        </table-head>
      </template>
      <tbody>
        <tr v-for="field in derivedFields" :key="field">
          <table-data td-label="Farm">
            {{ field.properties.farm }}
          </table-data>
          <table-data td-label="Field">
            <div class="flex">
              <field-thumbnail
                :title="field.properties.name"
                :geojson="field"
                class="mr-6"
              ></field-thumbnail>
              {{ field.properties.name }}
            </div>
          </table-data>
          <table-data td-label="Crop">
            {{ field.properties.crop }}
          </table-data>
          <table-data td-label="Variety">
            {{
              field.properties?.details?.variety
                ? field.properties?.details?.variety
                : ""
            }}
          </table-data>
          <table-data class="text-left">
            <radio-input
              inputTitle=""
              :wideItem="true"
              v-model="field.add_update"
              :selectOptions="[
                { label: 'Add Crop', value: 'add' },
                { label: 'Update Crop', value: 'update' },
              ]"
            />
          </table-data>
          <table-data class="text-left">
            <CheckboxInput
              v-if="field.add_update === 'add'"
              :wideItem="true"
              inputTitle="Make this the active crop?"
              v-model="field.makeActive"
            />
            <div v-else class="w-36 min-w-36"></div>
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>
<script>
import CropForm from "@/layouts/grower-portal/assets/fields/CropForm.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    CropForm,
    TableCard,
    TableHead,
    TableData,
    FieldThumbnail,
    RadioInput,
    CheckboxInput,
    BaseButton,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["closeModal"],
  data() {
    let derivedFields = this.fields.map((field) => {
      let crop = field?.properties?.current_crop;
      return {
        ...field,
        properties: {
          ...field.properties,
          crop: crop?.crop_type,
          details: crop,
        },
        add_update: "add",
        makeActive: true,
      };
    });
    return {
      toast: useToast(),
      addCount: 0,
      updateCount: 0,
      showEditForm: false,
      currentCrop: {
        crop_type: null,
        harvest_range_start: null,
        harvest_range_end: null,
        details: {},
        active: false,
      },
      derivedFields: derivedFields,
    };
  },
  computed: {},
  methods: {
    saveCrops() {
      for (let field of this.derivedFields) {
        if (field.add_update === "add") {
          if (field.makeActive) {
            this.currentCrop.active = true;
          } else {
            this.currentCrop.active = false;
          }
          this.markCropInactive(field?.properties?.current_crop?.id);
          this.addCrop(field.id);
        } else {
          this.updateCrop(field?.properties?.current_crop?.id);
        }
      }
      this.toast.success("Crops updated successfully");
      this.$emit("closeModal");
    },
    async addCrop(fieldId) {
      console.log("adding new crop to", fieldId, this.currentCrop);
      await this.$store.dispatch("createCrop", {
        fieldId: fieldId,
        crop: this.currentCrop,
      });
      this.addCount++;
    },
    async markCropInactive(cropId) {
      console.log("marking crop inactive", cropId);
      let crop = this.$store.state.crops.crops.find(
        (crop) => crop.id === cropId,
      );
      if (crop) {
        crop.active = false;
        await this.$store.dispatch("updateCrop", crop);
      }
    },
    async updateCrop(cropId) {
      // console.log(updatedCrop);
      if (cropId) {
        let updatedCrop = {
          ...this.currentCrop,
          id: cropId,
        };
        console.log("updating crop", cropId, updatedCrop);
        await this.$store.dispatch("updateCrop", updatedCrop);
      }
      this.updateCount++;
    },
  },
};
</script>
