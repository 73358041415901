<template>
  <form
    @submit.prevent="submitAssignmentForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Assignment Details
    </div>
    <p v-if="$route.query.contractId" class="sm:col-span-6">
      Contracts must be assigned to an affiliate in order for growers to view.
    </p>
    <select-dropdown
      :wideItem="true"
      inputTitle="Contract"
      inputHtmlType="text"
      v-model="currentMarketOfferContract"
      :selectOptions="contractOptions"
    />
    <select-dropdown
      :wideItem="true"
      inputTitle="Affiliate"
      inputHtmlType="text"
      v-model="currentMarketOfferOrg"
      :selectOptions="affiliateOptions"
    />
    <number-formatted-input
      :wideItem="true"
      inputTitle="Quantity"
      inputHtmlType="text"
      v-model="currentMarketOfferQuantity"
    />
  </form>

  <!-- show buyer_contract details here -->
  <div
    class="mt-4 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    v-if="selectedContract"
  >
    Contract Details
  </div>
  <div
    v-if="selectedContract"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <TextInput
      :wideItem="true"
      inputTitle="Contract Name"
      inputHtmlType="text"
      v-model="selectedContract.contract_name"
      :display-only="true"
      :display-only-value="selectedContract?.contract_name"
    />
    <TextInput
      :wideItem="true"
      inputTitle="Crop"
      inputHtmlType="text"
      v-model="selectedContract.crop"
      :display-only="true"
      :display-only-value="selectedContract?.crop"
    />
    <NumberFormattedInput
      :wideItem="true"
      inputTitle="Quantity"
      inputHtmlType="text"
      v-model="selectedContract.quantity"
      :display-only="true"
      :display-only-value="selectedContract?.quantity"
    />
    <NumberFormattedInput
      :wideItem="true"
      inputTitle="Unassigned Quantity"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="remainingQuantity"
    />
  </div>
</template>

<script>
import NumberFormattedInput from "@/components/form/NumberFormattedInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  props: {
    assignmentId: {
      type: [Number, String],
      default: null,
    },
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    NumberFormattedInput,
    TextInput,
    SelectDropdown,
  },
  data() {
    return {
      currentMarketOfferOrg: null,
      currentMarketOfferQuantity: null,
      currentMarketOfferContract: null,
      // temp until build multiple premium support
    };
  },
  computed: {
    // assignmentId() {
    //   return this.$route.params.assignmentId;
    // },
    contracts() {
      return this.$store.getters.getContracts({});
    },
    existingMarketOffer() {
      if (!this.assignmentId) return null;
      return this.$store.getters.getControllerMarketOfferById(
        this.assignmentId,
      );
    },
    selectedContract() {
      return this.contracts.find(
        (contract) => contract.id === parseInt(this.currentMarketOfferContract),
      );
    },
    selectedContractAssignments() {
      let assignments = this.$store.getters.getControllerMarketOffers();
      return assignments.filter(
        (assignment) =>
          assignment.buyer_contract === this.currentMarketOfferContract,
      );
    },
    remainingQuantity() {
      let assignedQuantity = 0;
      for (let assignment of this.selectedContractAssignments) {
        if (assignment.id != this.assignmentId) {
          assignedQuantity += assignment?.quantity;
        }
      }
      assignedQuantity += this.currentMarketOfferQuantity;
      if (!this.selectedContract) {
        return 0;
      }
      return this.selectedContract?.quantity - assignedQuantity;
    },
    marketAffiliates() {
      return this.$store.getters.getControllerMarketAffiliates;
    },
    affiliateOptions() {
      let options = [];
      for (let marketAffiliate of this.marketAffiliates) {
        options.push({
          value: marketAffiliate?.affiliate_details?.id,
          label: marketAffiliate?.affiliate_details?.name,
        });
      }
      return options;
    },
    contractOptions() {
      let options = [];
      for (let contract of this.contracts) {
        options.push({ value: contract.id, label: contract?.contract_name });
      }
      return options;
    },
    readyForReadMarketOffer() {
      if (this.$store.getters.getControllerMarketOfferById(this.assignmentId)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitAssignmentForm() {
      if (this.assignmentId) {
        this.updateMarketOffer();
      } else {
        this.createMarketOffer();
      }
    },
    readMarketOffer() {
      let currentMarketOffer = this.$store.getters.getControllerMarketOfferById(
        this.assignmentId,
      );
      this.currentMarketOfferOrg = currentMarketOffer?.org;
      this.currentMarketOfferQuantity = currentMarketOffer?.quantity;
      this.currentMarketOfferContract = currentMarketOffer?.buyer_contract;
    },
    async createMarketOffer() {
      await this.$store.dispatch("createControllerMarketOffer", {
        org: this.currentMarketOfferOrg, // int
        quantity: this.currentMarketOfferQuantity, // number
        buyer_contract: this.currentMarketOfferContract, // int
      });
      this.$router.push({ name: "buyer-assignments" });
    },
    async updateMarketOffer() {
      await this.$store.dispatch("updateControllerMarketOffer", {
        id: this.assignmentId, // int
        org: this.currentMarketOfferOrg, // int
        quantity: this.currentMarketOfferQuantity, // number
      });
      this.$router.push({ name: "buyer-assignments" });
    },
    clearForm() {
      // if there is a query param of contractId, then set the buyer_contract to that value
      this.currentMarketOfferOrg = null;
      this.currentMarketOfferQuantity = null;
      this.currentMarketOfferContract = null;
      if (this.$route.query.contractId) {
        this.currentMarketOfferContract = this.$route.query.contractId;
      }
    },
  },
  watch: {
    assignmentId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (!value) {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    existingMarketOffer: {
      handler(newVal) {
        if (newVal) {
          this.readMarketOffer();
        } else {
          this.clearForm();
        }
      },
    },
    readyForReadMarketOffer: {
      handler(value) {
        if (value && this.assignmentId) {
          this.readMarketOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
