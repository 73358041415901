<template>
  <slot-modal
    :open="showEditForm"
    modalTitle="Update Crop"
    @closeModal="showEditForm = false"
    dialogPanelClass="sm:max-w-7xl"
  >
    <template #content>
      <crops-form :fields="derivedFields" @closeModal="showEditForm = false" />
    </template>
  </slot-modal>
  <div v-if="!nofields" class="mx-auto px-4 sm:px-6 md:px-8">
    <h1 class="text-2xl font-semibold text-gray-900">My Fields</h1>
  </div>
  <div class="mx-auto px-4 sm:px-6 md:px-8">
    <PageCard
      v-if="nofields"
      headingTitle="No Fields"
      headingDescription="Please add a field before viewing the map."
    />
    <map-viewer
      :layers="layers"
      :showLayerSelector="true"
      :showTurnByTurn="true"
      :showGoToFeature="true"
      :showEditForm="true"
      :multi-pick="true"
      height="h-screen-3/4"
      @selected-features="editFieldAttributes"
      v-else
    />
  </div>
</template>

<script>
import MapViewer from "@/components/map/MapViewer.vue";
import PageCard from "@/components/cards/PageCard.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import CropsForm from "@/layouts/grower-portal/assets/fields/CropsForm.vue";

export default {
  components: {
    PageCard,
    MapViewer,
    SlotModal,
    CropsForm,
  },
  data() {
    return {
      nofields: this.$store.state.fields.fields.length === 0,
      showEditForm: false,
      currentCrop: {
        crop_type: null,
        harvest_range_start: null,
        harvest_range_end: null,
        details: {},
        active: false,
      },
      derivedFields: [],
    };
  },

  computed: {
    layers() {
      let layers = [];
      let fields = this.$store.state.fields.fields;
      fields = fields.filter((field) => field.geometry);
      // console.log("fields", fields);
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        //style:{fillColor:"red", opacity: 1, weight: 1, color: "green",}
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      //NDVI
      const records = this.$store.state.records.records
        .filter((rec) => rec.category === "NDVI")
        .map((rec) => {
          const img_data = rec.payload;
          return {
            field: rec.field,
            url: img_data.ndvi_image,
            bounds: [
              [img_data.location[1], img_data.location[0]],
              [img_data.location[3], img_data.location[2]],
            ],
          };
        });
      let ndviImages = [];
      records.forEach((rec) => {
        ndviImages.push({
          url: rec.url,
          bounds: rec.bounds,
        });
      });
      let NDVILayer = {
        name: "Plant Vigor",
        images: ndviImages,
        key: "<img width='50' src='/img/pictures/ndvi_key.png' />",
        // key: "/img/pictures/ndvi_key.png",
      };
      layers.push(NDVILayer);
      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
  },
  methods: {
    navToFields() {
      this.$router.push({ name: "fields" });
    },
    editFieldAttributes(features) {
      console.log("features", features);
      this.derivedFields = [];
      features.forEach((feature) => {
        let field = this.$store.getters.getFieldById(feature.id);
        this.derivedFields.push(field);
      });
      this.showEditForm = true;
    },
  },
};
</script>
<!--
<style>
 page {
    display:flex;
    flex-flow:row;
}
</style>
-->
