<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'buyer_details.name', label: 'Customer' },
          { name: 'service_title', label: 'Product' },
          { name: 'prettyStatus', label: 'Status' },
          { name: 'prettyDate', label: 'Date' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="quotes"
        @update:derived-collection="derivedQuotes = $event"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="q in derivedQuotes" :key="q.id">
        <table-data td-label="Customer">
          {{ q.buyer_details.name }}
        </table-data>
        <table-data td-label="Product">
          {{ q.service_title }}
        </table-data>
        <table-data td-label="Status">
          {{ q.prettyStatus }}
        </table-data>
        <table-data td-label="Date">
          {{ q.prettyDate }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: q.service_category
                ? 'vendor-single-quote'
                : 'vendor-single-input-order',
              params: q.service_category
                ? { quoteId: q.id }
                : { orderId: q.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import { prettyQuoteStatusVendor } from "@/components/composables/formatStringUtils.js";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    // TableToolbarButton,
  },
  props: {
    tabFilters: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      derivedQuotes: [],
      defaultSort: { prettyDate: "desc" },
    };
  },
  computed: {
    products() {
      return this.$store.state.vendorProducts.vendorProducts;
    },
    quotes() {
      return [
        ...this.$store.getters.getDerivedVendorQuotes({
          filterFunction: this.tabFilters,
        }),
        ...this.orders,
      ];
    },
    inputAgreements() {
      return this.$store.state.vendorInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.vendorInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.vendorInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
    orders() {
      let vendorOrders = this.$store.state.vendorInputOrders.orders.filter(
        this.tabFilters,
      );
      let orders = structuredClone(vendorOrders);
      for (let order of orders) {
        if (!order.buyer_details?.name) {
          order.buyer_details = {
            name: (order.service_category
              ? this.getAgreement(order.agreement)
              : this.getInputAgreement(order.input_agreement)
            )?.buyer_details.name,
          };
        }
        if (!order.service_title)
          order.service_title = order.service_category
            ? this.getAgreement(order.agreement)?.service_title
            : this.getOrderProductNames(order);
        if (!order.prettyStatus)
          order.prettyStatus = prettyQuoteStatusVendor(order.status);
        order.prettyDate = order.updated?.substring(0, 10);
      }
      return orders;
    },
  },
  methods: {
    getAgreement(id) {
      return this.agreements.find((a) => a.id == id);
    },
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    getOrderProductNames(o) {
      let title = "";
      for (let item of o.line_items || []) {
        if (item.input_unit) {
          const unit = this.getInputUnit(item.input_unit);
          if (unit) {
            const listing = this.getInputListing(unit.input_listing);
            if (title.length) title = title.concat(", ");
            title = title.concat(listing.title);
          }
        }
        if (item.input_request) {
          const request = this.getInputRequest(item.input_request);
          if (title.length) title = title.concat(", ");
          title = title.concat(request.product_name);
        }
      }
      return title;
    },
    prettyQuoteStatusVendor,
  },
};
</script>
